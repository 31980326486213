import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {auth, userSelector} from "../../../../../store/slices/user-slice";
import {getUserInfo, userNotification} from "../../../../../api/user/user";
import {setToken} from "../../../../../utils/authLocalStorage";
import Loader from "../../../../../shared/loader/loader";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface props {

}

const SetSlack: React.FC<props> = () => {
    const [visible, setVisible] = useState<boolean>(false)
    const {user} = useSelector(userSelector)
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [text, setText] = useState<string>('')
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const navigate = useNavigate()
    useEffect(() => {
        if (user.entity.slack) {
            setText(user.entity.slack)
        }
    }, [user.entity.slack])
    const showHandler = () => {
        document.body.style.overflow = 'hidden'
        setVisible(true)
    }
    const hideHandler = () => {
        setVisible(false)
        setError('')
        document.body.style.overflow = 'visible'
    }
    const onChange = (e: any) => {
        setText(e.target.value)
    }
    const resetUser = () => {
        getUserInfo()
            .then((response) => {
                if (response && response.data) {
                    dispatch(auth(response.data.user))
                }
            }).catch((e) => {
            if (e.response.status === 403) {
                navigate('/403')
            }
            if (e.response.status === 500) {
                navigate('/500')
            }
        })
    }
    const onChangeEmail = (e: any) => {
        if (text.trim().length > 0 && !text.trim().includes('https://hooks.slack.com')) {
            setError(t('slackError'))
            return
        }
        const data = {
            email: user.entity.email,
            preferred_notifications: user.entity.enabledNotifications,
            slack: text,
            telegram: user.entity.telegram || null
        }
        setConfirmLoading(true)
        userNotification(data)
            .then((response) => {
                if (response && response.data) {
                    if (response.data.member) {
                        setToken(response.data.token)
                        resetUser()
                    }
                    if (response.data.token) {
                        hideHandler()
                    }
                }
            }).catch((e) => {
            if (e.response && e.response.data) {
                setError(e.response.data.message)
            }
        }).finally(() => setConfirmLoading(false))
    }
    return (
        <>
            <svg onClick={showHandler} className={'account__options'} width="25" height="16" viewBox="0 0 19 12"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.118164 9.5127C0.118164 10.2979 0.329102 10.8955 0.750977 11.3057C1.17285 11.7158 1.77637 11.9209 2.56152 11.9209H16.0264C16.8115 11.9209 17.415 11.7158 17.8369 11.3057C18.2588 10.8955 18.4697 10.2979 18.4697 9.5127V2.48145C18.4697 1.69629 18.2588 1.10156 17.8369 0.697266C17.415 0.287109 16.8115 0.0820312 16.0264 0.0820312H2.56152C1.77637 0.0820312 1.17285 0.287109 0.750977 0.697266C0.329102 1.10156 0.118164 1.69629 0.118164 2.48145V9.5127ZM1.62988 9.46875V2.5166C1.62988 1.81348 1.97559 1.46191 2.66699 1.46191H15.9121C16.6094 1.46191 16.958 1.81348 16.958 2.5166V9.46875C16.958 9.80273 16.8672 10.0635 16.6855 10.251C16.5098 10.4326 16.252 10.5234 15.9121 10.5234H2.66699C2.33301 10.5234 2.0752 10.4326 1.89355 10.251C1.71777 10.0635 1.62988 9.80273 1.62988 9.46875ZM5.20703 7.20996C5.54102 7.20996 5.8252 7.09277 6.05957 6.8584C6.2998 6.61816 6.41992 6.33105 6.41992 5.99707C6.41992 5.66309 6.2998 5.37891 6.05957 5.14453C5.8252 4.9043 5.54102 4.78418 5.20703 4.78418C4.87305 4.78418 4.58594 4.9043 4.3457 5.14453C4.10547 5.37891 3.98535 5.66309 3.98535 5.99707C3.98535 6.33105 4.10547 6.61816 4.3457 6.8584C4.58594 7.09277 4.87305 7.20996 5.20703 7.20996ZM9.30273 7.20996C9.63672 7.20996 9.9209 7.09277 10.1553 6.8584C10.3955 6.61816 10.5156 6.33105 10.5156 5.99707C10.5156 5.66309 10.3955 5.37891 10.1553 5.14453C9.9209 4.9043 9.63672 4.78418 9.30273 4.78418C8.96289 4.78418 8.67578 4.9043 8.44141 5.14453C8.20703 5.37891 8.08984 5.66309 8.08984 5.99707C8.08984 6.33105 8.20703 6.61816 8.44141 6.8584C8.67578 7.09277 8.96289 7.20996 9.30273 7.20996ZM13.3984 7.20996C13.7324 7.20996 14.0195 7.09277 14.2598 6.8584C14.5 6.61816 14.6201 6.33105 14.6201 5.99707C14.6201 5.66309 14.5 5.37891 14.2598 5.14453C14.0195 4.9043 13.7324 4.78418 13.3984 4.78418C13.0645 4.78418 12.7773 4.9043 12.5371 5.14453C12.3027 5.37891 12.1855 5.66309 12.1855 5.99707C12.1855 6.33105 12.3027 6.61816 12.5371 6.8584C12.7773 7.09277 13.0645 7.20996 13.3984 7.20996Z"
                    fill="#55AAF9"/>
            </svg>
            {visible && <div className={'logout-modal-wrapper'}>
                <div onClick={hideHandler} className={'create-team__backdrop'}>

                </div>
                <div className={'logout-modal change-password'}>
                    {confirmLoading && <Loader/>}
                    <svg onClick={hideHandler} className={'create-team__icon'} width="11" height="11"
                         viewBox="0 0 11 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.804688 10.2803C0.86849 10.3486 0.945964 10.3919 1.03711 10.4102C1.12826 10.4329 1.21712 10.4329 1.30371 10.4102C1.3903 10.3919 1.47005 10.3486 1.54297 10.2803L5.7334 6.08301L9.93066 10.2803C10.0309 10.3805 10.154 10.4307 10.2998 10.4307C10.4456 10.4352 10.5687 10.3851 10.6689 10.2803C10.7692 10.1755 10.8193 10.0501 10.8193 9.9043C10.8193 9.75846 10.7692 9.6377 10.6689 9.54199L6.47168 5.34473L10.6689 1.14746C10.7692 1.05176 10.8193 0.93099 10.8193 0.785156C10.8239 0.634766 10.7738 0.50944 10.6689 0.40918C10.5687 0.308919 10.4456 0.258789 10.2998 0.258789C10.154 0.258789 10.0309 0.308919 9.93066 0.40918L5.7334 4.60645L1.54297 0.40918C1.47005 0.345378 1.3903 0.302083 1.30371 0.279297C1.21712 0.25651 1.12826 0.25651 1.03711 0.279297C0.945964 0.297526 0.86849 0.34082 0.804688 0.40918C0.736328 0.477539 0.690755 0.557292 0.667969 0.648438C0.645182 0.735026 0.645182 0.823893 0.667969 0.915039C0.690755 1.00618 0.736328 1.08366 0.804688 1.14746L4.99512 5.34473L0.804688 9.54199C0.736328 9.60579 0.688477 9.68327 0.661133 9.77441C0.638346 9.86556 0.638346 9.95671 0.661133 10.0479C0.683919 10.139 0.731771 10.2165 0.804688 10.2803Z"
                            fill="#C6C7C7"/>
                    </svg>
                    <div className={'create-team__icon-wrapper'}>
                        <h2 className={'create-team__title'}>
                            {t('changeSlack')}
                        </h2>

                    </div>
                    <label className={'password__label'}>
                        Slack webhook URL
                    </label>
                    <div className={'password__input'}>
                        <input onChange={onChange} value={text} type={'text'}
                               placeholder={'https://hooks.slack.com/services/000000000000/00000000000/000000000000000'}/>
                    </div>
                    {error.length > 0 && <p className={'password__error'}>
                        {error}
                    </p>}

                    <div className={'logout-modal__btn-wrapper'}>
                        <div onClick={onChangeEmail} className={'logout-modal__btn'}>
                            {t('change')}
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default SetSlack
