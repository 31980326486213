import React, {useEffect} from 'react'
import Header from "../../components/header/header";
import UploadForm from "../upload/components/upload-form/upload-form";
import Projects from "./components/projects/projects";
import {useDispatch, useSelector} from "react-redux";
import {clearProjects, getProjectsThunk, projectsSelector, setAllProjects} from "../../store/slices/projects-slice";
import Loader from "../../shared/loader/loader";
import {useNavigate, useParams} from "react-router-dom";
import {getTeamThunk, teamSelector} from "../../store/slices/team-slice";
import {setDocumentTitle} from "../../utils/setDocumentTitle/setDocumentTitle";
import {filterSelector, resetFilters, resetSort, setCount, setPage} from "../../store/slices/project-filter-slice";
import {getProjectsApi} from "../../api/project/project";

interface props {

}

const Team: React.FC<props> = () => {
    const {teamId} = useParams()
    const {projects} = useSelector(projectsSelector)
    const {team} = useSelector(teamSelector)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {projectOptions} = useSelector(filterSelector)

    useEffect(() => {
        if (teamId) {
            getProjectsApi('?page=1', '', '', '', '', teamId ? `&teamID=${teamId}` : '', '&count=500')
                .then((response) => {
                    dispatch(setAllProjects(response.items))
                }).catch(e => {

            })
            dispatch(getProjectsThunk(1, teamId, '', '&sortBy=updatedAt', '&sortValue=desc', '', '', projectOptions.count.toString()))
            dispatch(getTeamThunk(teamId))
            dispatch(resetFilters())
            dispatch(resetSort())
        }
        return () => {
            dispatch(clearProjects())
            dispatch(setAllProjects([]))
            dispatch(setPage(1))
        }
    }, [teamId])

    if (!projects.loaded) return (<Loader/>)
    if (!team.loaded) return (<Loader/>)

    if (projects.errors.isError && projects.errors.message === 'Permission denied') {
        navigate('/projects')
    }
    setDocumentTitle(`${team.entity.name ? `${team.entity.name}'s` : ''} Projects`)
    return (
        <>
            <UploadForm isFromTeam={true}/>
            <Projects/>
        </>
    )
}

export default Team
