import React from 'react'
import './loader.css'
import { SpinnerCircular } from 'spinners-react';

interface props {

}

const Loader: React.FC<props> = () => {
    return (
        <div className={'sign-form__spinner-wrapper'}>
            <SpinnerCircular enabled={true} />
        </div>
    )
}

export default Loader
