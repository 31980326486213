import React, {useEffect, useState} from 'react'
import './intersection-range.css'
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getTeamThunk, teamSelector} from "../../../../store/slices/team-slice";
import Loader from "../../../../shared/loader/loader";
import {editPercentageRange} from "../../../../api/team/team";
import {userSelector} from "../../../../store/slices/user-slice";
import {useTranslation} from "react-i18next";

interface props {

}

const IntersectionRange: React.FC<props> = () => {
    const barsColors = ['#23D86B', `#FFC531`, '#FF1A1A']
    const {register, handleSubmit,  setValue} = useForm();
    const {team} = useSelector(teamSelector)
    const {t} = useTranslation()
    const {user} = useSelector(userSelector)
    const {teamId} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [error, setError] = useState<string>('')
    useEffect(() => {
        if (teamId) {
            dispatch(getTeamThunk(teamId))
        }
    }, [])
    if (!team.loaded) return <Loader/>
    const percentages = [0, team.entity.mediumPointIntersection, team.entity.highPointIntersection]
    const onChangeRange = (e: any) => {
        if (Number(e.mediumPointIntersection) > 100 || Number(e.highPointIntersection) > 100) {
            setError(t('pointsErrors.isMoreHundred'))
            return
        }
        if (Number(e.mediumPointIntersection) >= Number(e.highPointIntersection)) {
            setError(t('pointsErrors.mediumMoreThenHigh'))
            return
        }
        if (teamId) {
            editPercentageRange(teamId, e)
                .then((response) => {
                    if (response && response.data) {
                        dispatch(getTeamThunk(teamId))
                        setError('')
                    }
                }).catch((e) => {
                if (e.response && e.response.status === 500) {
                    navigate('/500')
                }
                if (e.response && e.response.status === 403) {
                    navigate('/403')
                }
                if (e.response && e.response.status === 404) {
                    navigate('/500')
                }
                if (e.response && e.response.data) {
                    setError(e.response.data.message)
                }
            })
        }
    }

    const role = user.entity.role.find((r) => r.teamID.toString() === teamId)?.role
    const values = [t('low'), t('medium'), t('high')]
    return (
        <form onSubmit={handleSubmit(onChangeRange)} className={'intersection-range'}>
            <h4 className={'intersection-range__title'}>
                {t('intersectionsRange')}
            </h4>
            <div className={'intersection-range__inputs'}>
                <div className={'intersection-range__bar-wrapper'}>
                    <div className={'intersection-range__percentages'}>
                        {percentages.map((percentage) => (
                            <div className={'intersection-range__percentage'} key={percentage}>
                                {percentage}%
                            </div>
                        ))}
                        <div className={'intersection-range__percentage intersection-range__max'}>
                            100%
                        </div>
                    </div>
                    <div className={'intersection-range__bars'}>
                        {barsColors.map((color) => (
                            <div style={{backgroundColor: color}} key={color} className={'intersection-range__bar'}/>
                        ))}
                    </div>
                    <div className={'intersection-range__percentages'}>
                        {values.map((value) => (
                            <div className={'intersection-range__percentage intersection-range__values'} key={value}>
                                {value}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={'intersection-range__input'}>
                    <label className={'intersection-range__input--label'}>
                        {t('mediumPoint')}
                    </label>
                    <div className={'intersection-range__input--medium'}>
                        <input min={1} max={100}
                               readOnly={role === 'admin' ? undefined : true} {...register('mediumPointIntersection', {
                            value: team.entity.mediumPointIntersection,
                            required: true,
                            onChange: (e) => setValue('mediumPointIntersection', e.target.value.slice(0, 3)),
                            onBlur: e => setValue('mediumPointIntersection', e.target.value.trim())
                        })} type={'number'}/>
                    </div>
                    {error.length > 0 && <p className={'upload-form__error'}>
                        {error}
                    </p>}
                </div>
                <div className={'intersection-range__input'}>
                    <label className={'intersection-range__input--label'}>
                        {t('highPoint')}
                    </label>
                    <div className={'intersection-range__input--main'}>
                        <input min={1} max={100}
                               readOnly={role === 'admin' ? undefined : true} {...register('highPointIntersection', {
                            value: team.entity.highPointIntersection,
                            onChange: (e) => setValue('highPointIntersection', e.target.value.slice(0, 3)),
                            onBlur: e => setValue('highPointIntersection', e.target.value.trim()),
                            required: true
                        })} type={'number'}/>
                    </div>
                </div>
                <p className={'intersection-range__text'}>
                    {t('selectPoints')}
                </p>
            </div>
            <div className={'intersection-range__btn'}>
                {role === 'admin' && <button className={'submit-btn'}>
                    {t('saveChanges')}
                </button>}
            </div>
        </form>

    )
}

export default IntersectionRange
