import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setCompareToId, uploadSelector} from "../../../../../../store/slices/upload-project-slice";
import {IDataSelects} from "../../upload-form";
import {projectsSelector} from "../../../../../../store/slices/projects-slice";
import {useParams} from "react-router-dom";

interface props {
    isVisible: boolean
    visibleHandler: (data: IDataSelects) => void
}

const CustomProjectsSelect: React.FC<props> = ({isVisible, visibleHandler}) => {
    const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
    const [value, setValue] = useState<string>('Compare project')
    const {teamId} = useParams()
    const {upload} = useSelector(uploadSelector)
    const {projects} = useSelector(projectsSelector)

    const dispatch = useDispatch()
    const wrapperRef = useRef(null);
    const hideOptions = (e: any) => {
        if (wrapperRef.current) {
            // @ts-ignore
            if (!wrapperRef.current.contains(e.target)) {
                setIsOptionsOpen(false)
            }
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', hideOptions)
        return () => document.removeEventListener('mouseup', hideOptions)
    }, [])
    const toggleOptions = () => {
        setIsOptionsOpen(!isOptionsOpen);
    };
    const setAllDatabase = (id: number) => {
        dispatch(setCompareToId(id))
        setIsOptionsOpen(false)
    }


    return (
        projects.allProjects && projects.allProjects.length > 0 ? <div ref={wrapperRef} className="wrapper">
            <div className="custom-select__container">
                <button
                    type="button"
                    onClick={toggleOptions}
                    className={'custom-select__file'}
                >
                    <div>
                        {upload.compare_to_id ? upload.compare_to_id : value}
                    </div>
                    <div className={`arrow ${isOptionsOpen ? 'arrow-active' : ''}`}>

                    </div>
                </button>
                {isOptionsOpen &&
                <ul className={'custom-select__list'} style={{maxHeight: '400px', overflowY: 'auto'}}>
                    {projects.allProjects.map(({id, name}) => (
                        <li onClick={() => setAllDatabase(id)} className={'custom-select__import'} key={id}>
                            {name}
                        </li>
                    ))}
                </ul>}
            </div>
        </div> : null
    )
}

export default CustomProjectsSelect
