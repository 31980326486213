import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../../../../../../../shared/loader/loader";
import {getProjects} from "../../../../../../../../../store/slices/projects-slice";
import {getProjectsApi} from "../../../../../../../../../api/project/project";
import {
    filterSelector,
    resetFilters,
    resetSort,
    setFilter, setPage,
    typesReset
} from "../../../../../../../../../store/slices/project-filter-slice";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface props {
    value: string
}

const Status: React.FC<props> = ({value}) => {
    const [sortStatus, setSortStatus] = useState({
        filerBy: '',
        filterValue: ''
    })
    const {teamId} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const {projectOptions} = useSelector(filterSelector)
    const sortingOptions = [
        {
            id: 0,
            value: 'All',
            text: t('projectStatuses.all')
        },
        {
            id: 1,
            value: 'Completed',
            text: t('projectStatuses.completed')
        },
        {
            id: 2,
            value: "Indexing",
            text: t('projectStatuses.indexing')
        },
        {
            id: 3,
            value: "Failed",
            text: t('projectStatuses.failed')
        },
        {
            id: 4,
            value: "Intersection searching",
            text: t('projectStatuses.intersection searching')
        },
        {
            id: 5,
            value: "Intersection researching",
            text: t('projectStatuses.intersection researching')
        },
        {
            id: 6,
            value: "Intersection calculating",
            text: t('projectStatuses.intersection calculating')
        }
    ]
    const dispatch = useDispatch()
    const sortStatusHandler = async (status: string) => {
        if (sortStatus.filterValue === status) {
            await dispatch(typesReset())
            await dispatch(resetSort())
            await dispatch(resetFilters())
            await dispatch(setPage(1))
            setSortStatus({
                ...sortStatus, filterValue: '', filerBy: ""
            })
            dispatch(setFilter({filterValue: '', filterBy: ''}))
            request('', '')
            return
        }
        await dispatch(typesReset())
        await dispatch(resetSort())
        await dispatch(resetFilters())
        await dispatch(setPage(1))
        setSortStatus({
            ...sortStatus, filterValue: status, filerBy: value
        })
        dispatch(setFilter({filterValue: status, filterBy: value}))
        request(value, status)
    }

    function request(by: string, value: string) {
        setConfirmLoading(true)
        getProjectsApi(`?page=1`, '',
            ``,
            by ? `&filterBy=${by}` : '',
            by ? `&filterValue=${value}` : '',
            teamId ? `&teamID=${teamId}` : '')
            .then((response) => {
                dispatch(getProjects(response))
            })
            .catch((e) => {
                if (e.response.status === 500 || e.response.status === 0) {
                    navigate('/500')
                }
            }).finally(() => setConfirmLoading(false))
    }

    return (
        <>
            {confirmLoading && <Loader/>}
            {sortingOptions.map(({text, id, value}) => (
                <button key={id} onClick={() => sortStatusHandler(value.toLowerCase())}
                        className={`project-sorting__btn ${projectOptions.filter.filterValue === value.toLowerCase() ? 'project-sorting__btn--active' : ""}`}>
                    {text}
                </button>
            ))}
        </>
    )
}

export default Status
