import makeRequest from "../makeRequest";

export function getTeamRolesApi (teamID: string) {
    return makeRequest.get(`api/roles?teamID=${teamID}`)
}

export function editTeamRolesApi (teamID: string, data: any) {
    return makeRequest({
        method: "PUT",
        url: `api/roles/permissions/edit/${teamID}`,
        data: data
    })
}