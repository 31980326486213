import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getTeamsThunk, teamSelector} from "../../../../store/slices/team-slice";
import Loader from "../../../../shared/loader/loader";
import {deleteTeam} from "../../../../api/team/team";

interface props {

}

const DeleteTeamModal: React.FC<props> = () => {
    const [visible, setVisible] = useState<boolean>(false)
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const {teamId} = useParams()
    const {t} = useTranslation()
    const {team} = useSelector(teamSelector)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const removeMemberFromTeam = () => {
        if (teamId) {
            setConfirmLoading(true)
            deleteTeam(Number(teamId), team.entity.name)
                .then((response) => {
                    if (response && response.status === 200) {
                        dispatch(getTeamsThunk())
                        navigate('/projects')
                    }
                }).catch((e) => {
                if (e.response && e.response.data) {
                    setError(e.response.data.message)
                }
            }).finally(() => setConfirmLoading(false))
        }
    }
    const hideHandler = () => {
        setVisible(false)
        document.body.style.overflow = 'visible'
    }
    const showHandler = () => {
        setVisible(true)
        document.body.style.overflow = 'hidden'
    }

    return (
        <>
            {confirmLoading && <Loader/>}
            <button onClick={showHandler} className={'leave-btn'}>
                {t('delete')}
            </button>
            {visible && <div className={'logout-modal-wrapper'}>
                <div onClick={hideHandler} className={'create-team__backdrop'}>

                </div>
                <div className={'logout-modal'}>
                    <svg onClick={hideHandler} className={'create-team__icon'} width="11" height="11"
                         viewBox="0 0 11 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.804688 10.2803C0.86849 10.3486 0.945964 10.3919 1.03711 10.4102C1.12826 10.4329 1.21712 10.4329 1.30371 10.4102C1.3903 10.3919 1.47005 10.3486 1.54297 10.2803L5.7334 6.08301L9.93066 10.2803C10.0309 10.3805 10.154 10.4307 10.2998 10.4307C10.4456 10.4352 10.5687 10.3851 10.6689 10.2803C10.7692 10.1755 10.8193 10.0501 10.8193 9.9043C10.8193 9.75846 10.7692 9.6377 10.6689 9.54199L6.47168 5.34473L10.6689 1.14746C10.7692 1.05176 10.8193 0.93099 10.8193 0.785156C10.8239 0.634766 10.7738 0.50944 10.6689 0.40918C10.5687 0.308919 10.4456 0.258789 10.2998 0.258789C10.154 0.258789 10.0309 0.308919 9.93066 0.40918L5.7334 4.60645L1.54297 0.40918C1.47005 0.345378 1.3903 0.302083 1.30371 0.279297C1.21712 0.25651 1.12826 0.25651 1.03711 0.279297C0.945964 0.297526 0.86849 0.34082 0.804688 0.40918C0.736328 0.477539 0.690755 0.557292 0.667969 0.648438C0.645182 0.735026 0.645182 0.823893 0.667969 0.915039C0.690755 1.00618 0.736328 1.08366 0.804688 1.14746L4.99512 5.34473L0.804688 9.54199C0.736328 9.60579 0.688477 9.68327 0.661133 9.77441C0.638346 9.86556 0.638346 9.95671 0.661133 10.0479C0.683919 10.139 0.731771 10.2165 0.804688 10.2803Z"
                            fill="#C6C7C7"/>
                    </svg>
                    <h4 className={'logout-modal__title'}>
                        {t('deleteTeam')}
                    </h4>
                    <p className={'logout-modal__text'}>
                        {t('deleteTeamConfirm')} {team.entity.name}?
                    </p>
                    <div className={'logout-modal__btn-wrapper'}>
                        <div className={'logout-modal__btn'} onClick={hideHandler}>
                            {t('no')}
                        </div>
                        <div onClick={removeMemberFromTeam} className={'logout-modal__btn'}>
                            {t('yes')}
                        </div>
                    </div>
                    {error.length > 0 && <p className={'upload-form__error'}>
                        {error}
                    </p>}
                </div>
            </div>}
        </>
    )
}

export default DeleteTeamModal
