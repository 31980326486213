import React from 'react'
import {useTranslation} from "react-i18next";
import DeleteTeamModal from "./delete-team-modal";

interface props {

}

const DeleteTeam: React.FC<props> = () => {
	const {t} = useTranslation()
	return (
		<div className={'intersection-range'}>
			<h4 className={'intersection-range__title'}>
				{t('deleteTeam')}
			</h4>
			<div className={'intersection-range__inputs'}>
				<p className={'intersection-range__text'}>
					{t('deleteTeamText')}
				</p>
			</div>
			<div className={'intersection-range__btn leave-btn__wrapper'}>
				<DeleteTeamModal/>
			</div>
		</div>
	)
}

export default DeleteTeam
