import React, {useState} from 'react'
import './ticket-form.css'
import TicketFormModal from "./ticket-form-modal";
import {useTranslation} from "react-i18next";

interface props {

}

const TicketForm: React.FC<props> = () => {
    const [visible, setVisible] = useState<boolean>(false)
const {t} = useTranslation()
    const showHandler = () => {
        if (!visible) {
            document.body.style.overflow = 'hidden'
            setVisible(true)
        }
    }
    const hideHandler = () => {
        document.body.style.overflow = 'visible'
        setVisible(false)
    }
    return (
        <>
            <div className={'account__name ticket'}>
                <h4 className={'account__title ticket__title'}>
                    {t('tickets')}
                </h4>
                <div onClick={showHandler} className={'account__subtitle'} style={{cursor: 'pointer'}}>
                    <p className={'account__text ticket__text'}>
                        {t('createTicket')}
                    </p>
                    <TicketFormModal visible={visible} hideHandler={hideHandler}/>
                </div>
                <p className={'account__sub-text'}>
                    {t('ticketText')}
                </p>
            </div>
        </>
    )
}

export default TicketForm
