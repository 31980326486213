import {Action, createSlice, PayloadAction, ThunkAction} from "@reduxjs/toolkit"
import {ICategories, ICategoriesState, IIntersection} from "../../interfaces/intersections/intersections";
import {ICounter, ICounterState} from "../../interfaces/counters/counters";
import {getProjectCounters} from "../../api/counters/counters";
import {countersError, getCounters} from "./counters-slice";
import {getCategoriesApi} from "../../api/categories/categories";


const initialState: ICategoriesState = {
    categories: {
        entity: {
            current_page: 0,
            items: [],
            items_at_page: 0,
            pages_count: 0
        },
        loaded: false,
        errors: {
            isError: false,
            message: ''
        }
    }
}


export const categoriesSelector = (state: { categories: ICategoriesState }) => state.categories


const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        getCategories: (state, action: PayloadAction<any>) => {
            state.categories.entity = action.payload ? action.payload : []
            state.categories.loaded = true
            state.categories.errors.isError = false
        },
        categoriesError: (state, action: PayloadAction<string>) => {
            state.categories.errors.isError = true
            state.categories.loaded = true
            state.categories.errors.message = action.payload
        },
        resetCategories: (state) => {
            state.categories.entity.items = []
            state.categories.loaded = false
        },
        reverseCategories: (state) => {
            state.categories.entity.items = state.categories.entity.items.reverse()
        }
    }
})


export const {
    categoriesError,
    getCategories,
    resetCategories,
    reverseCategories
} = categoriesSlice.actions


export const getCategoriesThunk = (data: string, page: number): ThunkAction<void, ICategoriesState, unknown, Action<string>> => {
    return async dispatch => {
        try {
            const categories: ICategories[] | undefined = await getCategoriesApi(data, page).then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            dispatch(getCategories(categories))
        } catch (e: any) {
            dispatch(categoriesError(e.response.data.message))
        }
    }
}

export default categoriesSlice.reducer