import React from 'react'
import './help.css'
import Header from "../../components/header/header";
import UploadProject from "./components/upload-project";
import Analysis from "./components/analysis";
import {setDocumentTitle} from "../../utils/setDocumentTitle/setDocumentTitle";
import {useTranslation} from "react-i18next";
import Teams from "./components/teams";
import User from "./components/user";

interface props {

}

const Help: React.FC<props> = () => {
    const {t} = useTranslation()
    setDocumentTitle(t('help'))
    return (
        <>
            <UploadProject/>
            <Analysis/>
            <Teams/>
            <User/>
        </>
    )
}

export default Help
