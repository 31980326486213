import makeRequest from "../../api/makeRequest";

export function getHashesApi(id: string, page: string, ignored?: string, count?:string) {
    return makeRequest.get(`api/project_intersection/${id}/${page ? page : ''}${ignored ? ignored : ''}${count ? count : ''}`)
}

export function ignoreHash(id: string, hash: string) {
    const data = {
        hash: hash
    }
    return makeRequest({
        method: "PUT",
        headers: {"Content-Type": "application/json"},
        url: `api/project_intersection/${id}/ignore`,
        data: data
    })
}

export function unIgnoreHash(id: string, hash: string) {
    const data = {
        hash: hash
    }
    return makeRequest({
        method: "PUT",
        headers: {"Content-Type": "application/json"},
        url: `api/project_intersection/${id}/unignore`,
        data: data
    })
}