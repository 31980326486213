import React, {useState} from 'react'
import Loader from "../../../../../shared/loader/loader";
import {useDispatch, useSelector} from "react-redux";
import {
    resetFilters,
    resetSort,
    setFilter,
    setPage,
    ticketFilter
} from "../../../../../store/slices/ticket-filter-slice";
import {getTicketsApi} from "../../../../../api/tickets/tickets";
import {getTickets} from "../../../../../store/slices/tickets-slice";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";


interface props {
    value: string
}

const Status: React.FC<props> = ({value}) => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const {ticketFilters} = useSelector(ticketFilter)
    const sortStatusHandler = async (status: string) => {
        if (status === ticketFilters.filters.filterValue) {
            await dispatch(resetSort())
            await dispatch(resetFilters())
            await dispatch(setFilter({filterBy: "", filterValue: ""}))
            request('', '')
            return
        }
        await dispatch(resetSort())
        await dispatch(resetFilters())
        await dispatch(setPage(1))
        await dispatch(setFilter({filterValue: status, filterBy: value}))
        request(value, status)
    }
    const {t} = useTranslation()
    const sortingOptions = [
        {
            id: 0,
            text: t('projectStatuses.all'),
            value: 'all'
        },
        {
            id: 1,
            text: t('ticketStatuses.created'),
            value: 'created'
        },
        {
            id: 2,
            text: t('ticketStatuses.under_consideration'),
            value: 'under_consideration'
        },
        {
            id: 3,
            text: t('ticketStatuses.waiting_for_answer'),
            value: 'waiting_for_answer'
        },
        {
            id: 4,
            text: t('ticketStatuses.closed'),
            value: 'closed'
        }

    ]
    const request = (by: string, value: string) => {
        setConfirmLoading(true)
        getTicketsApi(`?page=1`, '',
            ``,
            by ?`&filterBy=${by}` : '',
            value ?`&filterValue=${value}` : '')
            .then((response) => {
                dispatch(getTickets(response.data))
            })
            .catch((e) => {

            }).finally(() => setConfirmLoading(false))
    }


    return (
        <>
            {confirmLoading && <Loader/>}
            {sortingOptions.map(({text, id, value}) => (
                <button key={id} onClick={() => sortStatusHandler(value)}
                        className={`project-sorting__btn ${ticketFilters.filters.filterValue === value ? 'project-sorting__btn--active' : ""}`}>
                    {text}
                </button>
            ))}
        </>
    )
}

export default Status
