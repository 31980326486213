import './pagination.css'
import React from "react";

interface PaginationHasPrevProps {
    value: number;

    onSet(): void;

    onDecrement(): void;

    isNoArrows?: boolean
}

export const PaginationHasPrev: React.FC<PaginationHasPrevProps> = ({
                                                                        value,
                                                                        onSet,
                                                                        onDecrement,
                                                                        isNoArrows
                                                                    }) => {

    return (
        <>
            {!isNoArrows && <button onClick={onDecrement} className={'pagination-button__icon--prev'}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.588867 7.66309C0.588867 7.89941 0.681966 8.10352 0.868164 8.27539L7.65723 15.0645C7.8291 15.2292 8.02604 15.3115 8.24805 15.3115C8.47005 15.3115 8.65983 15.2363 8.81738 15.0859C8.97493 14.9427 9.05371 14.7565 9.05371 14.5273C9.05371 14.4199 9.03223 14.3161 8.98926 14.2158C8.95345 14.1084 8.89974 14.0189 8.82812 13.9473L6.25 11.3262L2.44727 7.8457L2.24316 8.29688L5.41211 8.47949H18.6465C18.8757 8.47949 19.0654 8.4043 19.2158 8.25391C19.3734 8.10352 19.4521 7.90658 19.4521 7.66309C19.4521 7.42676 19.3734 7.2334 19.2158 7.08301C19.0654 6.93262 18.8757 6.85742 18.6465 6.85742H5.41211L2.24316 7.0293L2.44727 7.50195L6.25 4.01074L8.82812 1.38965C8.89974 1.31803 8.95345 1.2321 8.98926 1.13184C9.03223 1.02441 9.05371 0.916992 9.05371 0.80957C9.05371 0.580404 8.97493 0.394206 8.81738 0.250977C8.65983 0.100586 8.47005 0.0253906 8.24805 0.0253906C8.13346 0.0253906 8.02604 0.046875 7.92578 0.0898438C7.82552 0.132812 7.72168 0.204427 7.61426 0.304688L0.868164 7.06152C0.681966 7.2334 0.588867 7.43392 0.588867 7.66309Z"
                        fill="#535353"/>
                </svg>
            </button>
            }
            <button className={"pagination-button"} onClick={onSet}>
                <span>{value}</span>
            </button>
        </>
    );
};
