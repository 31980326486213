import makeRequest from "../makeRequest";

export function getProjectCounters(id: string) {
    return makeRequest.get(`api/project/${id}/counters/`)
}

export function getCounterFiles(id: string, page?: string, count?: string, ignore?: string, isCrossed?: string) {
    return makeRequest.get(`api/project_counter/${id}/hashes${page ? page : ''}${count ? count : ''}${ignore ? ignore : ''}${isCrossed ? `&sortByIsCrossed=${isCrossed}` : ''}`)
}

export function ignoreFiles(counterId: number, hash: string) {
    const data = {
        hash: hash
    }
    return makeRequest({
        method: "PUT",
        url: `api/project_counter/${counterId}/hashes/ignore`,
        headers: {"Content-Type": "application/json"},
        data: data
    })
}

export function unignoreFiles(counterId: number, hash: string) {
    const data = {
        hash: hash
    }
    return makeRequest({
        method: "PUT",
        url: `api/project_counter/${counterId}/hashes/unignore`,
        headers: {"Content-Type": "application/json"},
        data: data
    })
}