import React, {useState} from 'react'
import Intersections from "./intersections";
import Hashdata from "../hashdata/hashdata";

interface props {

}

const IntersectionWrapper: React.FC<props> = () => {
    const [visible, setVisible] = useState<boolean>(false)
    const showHashes = () => setVisible(true)
    const hideHashes = () => setVisible(false)
    return (
        <div className={'analysis-data'}>
            <Intersections hideHashes={hideHashes} showHashes={showHashes}/>
            {visible && <Hashdata hideHashes={hideHashes}/>}
        </div>
    )
}

export default IntersectionWrapper
