import {Action, createSlice, PayloadAction, ThunkAction} from "@reduxjs/toolkit"
import {IProjectFilterState} from "../../interfaces/projects/projectFilters";
import {ITicketFilterState} from "../../interfaces/tickets/ticket-filters";


const initialState: ITicketFilterState = {
    ticketFilters: {
        sort: {
            sortBy: '',
            sortValue: ''
        },
        filters: {
            filterBy: '',
            filterValue: ''
        },
        page: 1,
    }
}


export const ticketFilter = (state: { ticketFilters: ITicketFilterState }) => state.ticketFilters


const ticketFiltersSlice = createSlice({
    name: 'ticketFilters',
    initialState,
    reducers: {
        setSort: (state, action: PayloadAction<{ sortBy: string, sortValue: string }>) => {
            state.ticketFilters.sort.sortBy = action.payload.sortBy
            state.ticketFilters.sort.sortValue = action.payload.sortValue
        },
        resetSort: (state) => {
            state.ticketFilters.sort.sortBy = ''
            state.ticketFilters.sort.sortValue = ''
        },
        setFilter: (state, action: PayloadAction<{ filterBy: string, filterValue: string }>) => {
            state.ticketFilters.filters.filterBy = action.payload.filterBy
            state.ticketFilters.filters.filterValue = action.payload.filterValue
        },
        resetFilters: (state) => {
            state.ticketFilters.filters.filterBy = ''
            state.ticketFilters.filters.filterValue = ''
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.ticketFilters.page = action.payload
        },
    }
})


export const {
    resetFilters,
    setPage,
    resetSort,
    setSort,
    setFilter
} = ticketFiltersSlice.actions



export default ticketFiltersSlice.reducer