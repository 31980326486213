import React, {ReactElement} from 'react'
import './account.css'
import ChangePasswordModal from "./components/change-password-modal/change-password-modal";
import ChangeUsername from "./components/change-username/change-username";
import EmailChangeModal from "./components/email-change-modal/email-change-modal";
import {useSelector} from "react-redux";
import {userSelector} from "../../../../store/slices/user-slice";
import {useTranslation} from "react-i18next";

interface props {

}

interface IAccountItem {
    id: number
    icon: ReactElement
    name: string
    title: string
}

const Account: React.FC<props> = () => {
    const {user} = useSelector(userSelector)
    const {t} = useTranslation()
    const accountItems: IAccountItem[] =
        [
            {
                id: 1,
                icon: <ChangeUsername id={user.entity.id}/>,
                name: t('username'),
                title: user.entity.username
            }
        ]
    return (
        <section className={'account'}>
            <h2 className={'account__title'}>
                {t('account')}
            </h2>
            <div className={'account__info'}>
                {accountItems.map(({title, icon, id, name}) => (
                    <div key={id} className={'account__name'}>
                        <h4 className={'account__title'}>
                            {name}
                        </h4>
                        <div className={'account__subtitle'}>
                            <p className={'account__text'}>
                                {title}
                            </p>
                            {id === 2 ? null : icon}
                        </div>
                    </div>
                ))}
                <div className={'account__name'}>
                    <h4 className={'account__title'}>
                        {t('password')}
                    </h4>
                    <ChangePasswordModal id={user.entity.id}/>
                </div>
            </div>
        </section>
    )
}

export default Account
