import React from 'react'
import '../../../members.css'
import MembersList from "./members-list";

interface props {

}

const MembersWrapper: React.FC<props> = () => {
    return (
        <section className={'database'}>
            <MembersList/>
        </section>
    )
}

export default MembersWrapper
