import React, {useEffect, useState} from 'react'
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {teamSelector} from "../../../../store/slices/team-slice";
import EmailInput from "./email-input";
import {useLocation, useNavigate} from "react-router-dom";
import {addMemberToTeam} from "../../../../api/team/team";
import Loader from "../../../../shared/loader/loader";
import {memberRoles} from "../../../../constants/user-roles";
import {useTranslation} from "react-i18next";

interface props {
    hideHandler: () => void
}

export interface IEmail {
    id: number
    email: string
    isError: boolean,
    errorMessage: string
    memberId: null | number

}

const AddTeamMemberForm: React.FC<props> = ({hideHandler}) => {
    const {register, handleSubmit, reset, watch, formState: {errors}} = useForm();
    const location = useLocation()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const confirmLoadingToggle = (data: boolean) => setConfirmLoading(data)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        if (location.pathname === `/teams/${team.entity.id}/members`) {
            hideHandler()
        }
    }, [location.pathname])
    const {team} = useSelector(teamSelector)
    const [emails, setEmails] = useState<IEmail[]>([
        {
            id: 0,
            email: '',
            memberId: null,
            isError: false,
            errorMessage: ''
        }
    ])

    const addEmail = () => {
        const newEmail: IEmail = {
            id: emails.length,
            email: '',
            memberId: null,
            isError: false,
            errorMessage: ''
        }
        const newEmails = [...emails, newEmail]
        setEmails(newEmails)
    }
    const setEmail = (id: number, email: string, memberId: number) => {
        const newEmails = emails.map((e) => {
            if (id === e.id) {
                return {...e, email: email, memberId: memberId, isError: false, errorMessage: ''}
            }
            return e
        })
        setEmails(newEmails)
    }
    const removeEmail = (id: number) => {
        const newEmails = emails.filter((email) => {
            if (id === email.id) {
                return false
            }
            return email
        })
        setEmails(newEmails)
    }
    const setError = (id: number, err: string) => {
        const newEmails: IEmail[] = emails.map((email) => {
            if (email.id === id) {
                return {...email, isError: true, errorMessage: err, memberId: null}
            }
            return email
        })
        setEmails(newEmails)
    }
    const onSubmit = async (e: any) => {
        const error = await emails.find((email) => email.isError)
        if (error && error.isError && !error.memberId) {
            setIsError(true)
            return
        }
        setConfirmLoading(true)
        return Promise.all(emails.map(({memberId, id}) => {
            if (memberId) {
                const data = {
                    teamID: team.entity.id,
                    role: memberRoles.viewer,
                    userID: memberId
                }
                return addMemberToTeam(data)
            }
        })).then(async (response) => {
            hideHandler()
            navigate(`/teams/${team.entity.id}/members`)
        }).finally(() => setConfirmLoading(false))
    }
    return (
        <>
            <form className={'team-member-add__form'} onSubmit={handleSubmit(onSubmit)}>
                {confirmLoading && <Loader/>}
                <svg onClick={hideHandler} className={'create-team__icon'} width="11" height="11"
                     viewBox="0 0 11 11" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.804688 10.2803C0.86849 10.3486 0.945964 10.3919 1.03711 10.4102C1.12826 10.4329 1.21712 10.4329 1.30371 10.4102C1.3903 10.3919 1.47005 10.3486 1.54297 10.2803L5.7334 6.08301L9.93066 10.2803C10.0309 10.3805 10.154 10.4307 10.2998 10.4307C10.4456 10.4352 10.5687 10.3851 10.6689 10.2803C10.7692 10.1755 10.8193 10.0501 10.8193 9.9043C10.8193 9.75846 10.7692 9.6377 10.6689 9.54199L6.47168 5.34473L10.6689 1.14746C10.7692 1.05176 10.8193 0.93099 10.8193 0.785156C10.8239 0.634766 10.7738 0.50944 10.6689 0.40918C10.5687 0.308919 10.4456 0.258789 10.2998 0.258789C10.154 0.258789 10.0309 0.308919 9.93066 0.40918L5.7334 4.60645L1.54297 0.40918C1.47005 0.345378 1.3903 0.302083 1.30371 0.279297C1.21712 0.25651 1.12826 0.25651 1.03711 0.279297C0.945964 0.297526 0.86849 0.34082 0.804688 0.40918C0.736328 0.477539 0.690755 0.557292 0.667969 0.648438C0.645182 0.735026 0.645182 0.823893 0.667969 0.915039C0.690755 1.00618 0.736328 1.08366 0.804688 1.14746L4.99512 5.34473L0.804688 9.54199C0.736328 9.60579 0.688477 9.68327 0.661133 9.77441C0.638346 9.86556 0.638346 9.95671 0.661133 10.0479C0.683919 10.139 0.731771 10.2165 0.804688 10.2803Z"
                        fill="#C6C7C7"/>
                </svg>
                <h2 className={'create-team__title'}>
                    {t('addMember')}
                </h2>
                <p className={'create-team__text'}>
                    {t('addMemberText')}
                </p>
                <div className={'create-team__fields'}>
                    <div className={'create-team__email'}>
                        {emails.map((item) => (
                            <EmailInput setConfirmLoading={confirmLoadingToggle}
                                        setError={setError}
                                        key={item.id}
                                        setEmail={setEmail}
                                        item={item}
                                        removeEmail={removeEmail}
                            />
                        ))}
                    </div>
                    <div className={'create-team__add-input'} onClick={addEmail}>
                        <p>
                            {t('addAnotherMember')}
                        </p>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.882812 6.16406C0.882812 6.33073 0.942708 6.47396 1.0625 6.59375C1.18229 6.70833 1.32292 6.76562 1.48438 6.76562H6.33594V11.6172C6.33594 11.7786 6.39323 11.9167 6.50781 12.0312C6.6276 12.151 6.77083 12.2109 6.9375 12.2109C7.09896 12.2109 7.23698 12.151 7.35156 12.0312C7.46615 11.9167 7.52344 11.7786 7.52344 11.6172V6.76562H12.3906C12.5521 6.76562 12.6901 6.70833 12.8047 6.59375C12.9245 6.47396 12.9844 6.33073 12.9844 6.16406C12.9844 6.0026 12.9245 5.86458 12.8047 5.75C12.6901 5.63021 12.5521 5.57031 12.3906 5.57031H7.52344V0.710938C7.52344 0.554688 7.46615 0.416667 7.35156 0.296875C7.23698 0.177083 7.09896 0.117188 6.9375 0.117188C6.77083 0.117188 6.6276 0.177083 6.50781 0.296875C6.39323 0.416667 6.33594 0.554688 6.33594 0.710938V5.57031H1.48438C1.32292 5.57031 1.18229 5.63021 1.0625 5.75C0.942708 5.86458 0.882812 6.0026 0.882812 6.16406Z"
                                fill="black"/>
                        </svg>

                    </div>
                    <button disabled={confirmLoading} type={'submit'} className={'create-team__btn'}>
                        {t('finish')}
                    </button>
                </div>
                <div onClick={() => {
                    navigate(`/teams/${team.entity.id}/members`)
                    hideHandler()
                }} className={'create-team__skip'}>
                    {t('skip')}
                </div>

            </form>
        </>
    )

}


export default AddTeamMemberForm
