import React from 'react'

interface props {

}

const ServiceProviders: React.FC<props> = () => {
    return (
        <section className={'account privacy'}>
            <h2 className={'account__title'}>
                Service Providers
            </h2>
            <div className={'account__info'}>
                <p className={'privacy__text'}>
                    I may employ third-party companies and individuals due to the following reasons:
                    To facilitate our Service;
                    To provide the Service on our behalf;
                    To perform Service-related services; or
                    To assist us in analyzing how our Service is used.
                    I want to inform users of this Service that these third parties have access to their Personal
                    Information. The reason is to perform the tasks assigned to them on our behalf. However, they are
                    obligated not to disclose or use the information for any other purpose.
                </p>
            </div>
        </section>
    )
}

export default ServiceProviders
