import {Action, createSlice, PayloadAction, ThunkAction} from "@reduxjs/toolkit"
import {ITicket, ITicketEntity, ITicketState} from "../../interfaces/tickets/tickets";
import {ITeam, ITeamState} from "../../interfaces/team/team";
import {getTeamsApi} from "../../api/team/team";
import {getTeams, teamsError} from "./team-slice";
import {createTicketApi, getTicketApi, getTicketsApi} from "../../api/tickets/tickets";
import {IProject} from "../../interfaces/projects/projects";


const initialState: ITicketState = {
    tickets: {
        entity: {
            currentPage: 0,
            items: [],
            items_at_page: 0,
            pages_count: 0,
            totalCount: 0
        },
        confirmLoading: false,
        loaded: false,
        errors: {
            isError: false,
            message: ''
        }
    },
    ticket: {
        entity: {} as ITicket,
        confirmLoading: false,
        loaded: false,
        errors: {
            isError: false,
            message: ''
        }
    }
}


export const ticketSelector = (state: { tickets: ITicketState }) => state.tickets


const ticketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        getTickets: (state, action: PayloadAction<ITicketEntity>) => {
            state.tickets.loaded = true
            state.tickets.errors.isError = false
            state.tickets.entity = action.payload
        },
        ticketsError: (state, action: PayloadAction<string>) => {
            state.tickets.loaded = true
            state.tickets.errors.isError = true
            state.tickets.errors.message = action.payload
        },
        getTicket: (state, action: PayloadAction<ITicket>) => {
            state.ticket.entity = action.payload
            state.ticket.loaded = true
            state.ticket.errors.isError = false
        },
        ticketError: (state, action: PayloadAction<string>) => {
            state.ticket.loaded = true
            state.ticket.errors.isError = true
            state.ticket.errors.message = action.payload
        },
        resetTickets: (state) => {
            state.tickets.entity = {} as ITicketEntity
            state.tickets.loaded = false
        },
        setLoaded: (state, action: PayloadAction<boolean>) => {
            state.tickets.loaded = action.payload
        },
        setConfirmLoaded: (state, action: PayloadAction<boolean>) => {
            state.tickets.confirmLoading = action.payload
        },
        createTicket: (state, action: PayloadAction<ITicket>) => {
            state.tickets.loaded = true
            state.tickets.entity.items = [...state.tickets.entity.items, action.payload]
            state.tickets.errors.isError = false
        },
        resetTicket: (state) => {
            state.ticket.entity = {} as ITicket
            state.ticket.loaded = false
        }
    }
})


export const {
    getTickets,
    ticketsError,
    resetTickets,
    setLoaded,
    setConfirmLoaded,
    createTicket,
    ticketError,
    getTicket,
    resetTicket
} = ticketsSlice.actions


export const getTicketsThunk = (page: string, sortBy?: string, sortValue?: string, filterBy?: string, filterValue?: string): ThunkAction<void, ITicketState, unknown, Action<string>> => {
    return async dispatch => {
        try {
            const tickets: ITicketEntity = await getTicketsApi(page, sortBy, sortValue, filterBy, filterValue)
                .then((response) => {
                    if (response && response.data) {
                        return response.data
                    }
                })
            dispatch(getTickets(tickets))

        } catch (e: any) {
            dispatch(setLoaded(true))
            const errorMessage = e.response.data.detail || e.response.data.message
            dispatch(ticketsError(errorMessage))
        }
    }
}

export const getTicketThunk = (id: string): ThunkAction<void, ITicketState, unknown, Action<string>> => {
    return async dispatch => {
        try {
            dispatch(setLoaded(false))
            const ticket: ITicket = await getTicketApi(id)
                .then((response) => {
                    if (response && response.data) {
                        dispatch(setLoaded(true))
                        return response.data
                    }
                })
            dispatch(getTicket(ticket))
        } catch (e: any) {
            dispatch(setLoaded(true))
            const errorMessage = e.response.data.detail || e.response.data.message
            dispatch(ticketError(errorMessage))
        }
    }
}

export const createTicketsThunk = (data: { text: string, topic: string, fileName?: File }, redirect: (id: number) => void, hideHandler: () => void): ThunkAction<void, ITicketState, unknown, Action<string>> => {
    return async dispatch => {
        try {
            dispatch(setConfirmLoaded(true))
            await createTicketApi(data)
                .then((response) => {
                    if (response && response.data) {
                        dispatch(setConfirmLoaded(false))
                        hideHandler()
                        redirect(response.data.ticket.id)
                    }
                })
        } catch (e: any) {
            dispatch(setConfirmLoaded(false))
            const errorMessage = e.response.data.detail || e.response.data.message
            dispatch(ticketsError(errorMessage))
        }
    }
}

export default ticketsSlice.reducer