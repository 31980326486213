import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import {createBrowserHistory} from "history";
import App from "./App";
import {Provider} from "react-redux";
import store from "./store";

export const history = createBrowserHistory()
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>
);


serviceWorkerRegistration.register()