import {Action, createSlice, PayloadAction, ThunkAction} from "@reduxjs/toolkit"
import {IProvider, IProviderState} from "../../interfaces/providersOauth/providersOauth";


const initialState: IProviderState = {
    provider: {
        entity: {
            provider_url: '',
            auth_url: ''
        }
    }
}


export const providerSelector = (state: { provider: IProviderState }) => state.provider


const providerSlice = createSlice({
    name: 'provider',
    initialState,
    reducers: {
        setProvider: (state, action: PayloadAction<IProvider>) => {
            state.provider.entity = action.payload
        }
    }
})


export const {
    setProvider
}
    = providerSlice.actions


export default providerSlice.reducer