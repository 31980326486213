import React, {useEffect, useState} from 'react'
import './upload-form.css'
import {useDispatch, useSelector} from "react-redux";
import {setCompareToId, setFileName, uploadSelector} from "../../../../store/slices/upload-project-slice";
import {IUploadData, IViaLinkUpload} from "../../../../interfaces/upload-project/upload-project";
import {uploadProject, uploadViaLinkProjects} from "../../../../api/project/project";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {TOKEN} from "../../../../constants/token";
import Loader from "../../../../shared/loader/loader";
import {useWindowSize} from "../../../../hooks/useWindowParams";
import {getProjectsThunk} from "../../../../store/slices/projects-slice";
import Selects from "./components/selects";
import ProgressResult from "./components/progress-result";
import {addProgress, changeProgress, progressSelector, setProjectId} from "../../../../store/slices/progress-slice";
import {auth, userSelector} from "../../../../store/slices/user-slice";
import ProjectSearchInput from "../database/components/components/project-search-input";
import {getUserInfo} from "../../../../api/user/user";
import MembersSearchInput from "../../../members/components/members/components/members-search-input";
import {filterSelector} from "../../../../store/slices/project-filter-slice";
import {useTranslation} from "react-i18next";

interface props {
    isFromTeam?: boolean
}

export interface IDataSelects {
    fileUpload: boolean,
    type: boolean,
    compare: boolean
}

export interface IProgress {
    id: number
    percentage: null | number
    loaded: null | number
    total: null | number
    projectName: null | string
    projectType: null | string
    isCanceled: boolean
    speed: null | number
    leftTime: string | null
    projectId: null | number
    isError: boolean,
    messageError: ''
    abort: any
}

const UploadForm: React.FC<props> = ({isFromTeam}) => {
    const {pathname} = useLocation()
    const {teamId} = useParams()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const {upload} = useSelector(uploadSelector)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {progress} = useSelector(progressSelector)
    const [visible, setVisible] = useState<boolean>(true)
    const visibleToggle = () => {
        if (width > 575) {
            return
        }
        setVisible(!visible)
    }
    const {user} = useSelector(userSelector)
    const {width} = useWindowSize()
    useEffect(() => {
        const preventReload = (e: BeforeUnloadEvent) => {
            e.preventDefault()
            e.returnValue = 'reject'
        }
        if (confirmLoading) {
            window.addEventListener('beforeunload', preventReload)
        }
        if (!confirmLoading) {
            window.removeEventListener('beforeunload', preventReload)
        }
        return () => {
            window.removeEventListener('beforeunload', preventReload)
        }
    }, [confirmLoading])
    useEffect(() => {
        if (width && width <= 575) {
            setVisible(false)
        }
    }, [width])
    const defaultValue: IProgress = {
        id: progress.entity.length + 1,
        percentage: null,
        loaded: null,
        total: null,
        projectName: '',
        projectType: '',
        isCanceled: false,
        speed: null,
        leftTime: null,
        projectId: null,
        isError: false,
        messageError: '',
        abort: null
    }
    const {projectOptions} = useSelector(filterSelector)

    const [validation, setValidation] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const resetSuccess = () => setSuccess(false)
    const [result, setResult] = useState({
        id: null,
        isError: false,
        errorMessage: '',
        isSuccess: false
    })


    const refreshProgress = (progress: IProgress) => {
        dispatch(changeProgress(progress))
    }

    const refreshUser = () => {
        getUserInfo()
            .then((response) => {
                if (response && response.data) {
                    dispatch(auth(response.data.user))
                }
            }).catch((e) => {
            if (e.response.status === 403) {
                navigate('/403')
            }
            if (e.response.status === 500) {
                navigate('/500')
            }
        })
    }
    const onUpload = () => {

        if (!upload.fileName && !upload.archiveUrl.length) {
            setValidation(true)
            return
        }

        setValidation(false)
        const data: IUploadData = {
            type: upload.type.toLowerCase(),
            fileName: upload.fileName
        }
        const viaLinkData: IViaLinkUpload = {
            type: upload.type.toLowerCase(),
            archiveUrl: upload.archiveUrl
        }
        if (upload.compare_to_id) {
            data['compare_to_id'] = upload.compare_to_id.toString()
        }
        if (isFromTeam && teamId) {
            data['teamID'] = Number(teamId)
            viaLinkData['teamID'] = Number(teamId)
        }
        setResult({
            ...result, id: null
        })

        if (upload.fileName) {
            const archiveFormat = upload.fileName.name.split('.').at(-1).toLowerCase()
            const allowedFormats = ['zip', '7z', 'tar', 'rar', 'gz']
            if (!allowedFormats.some((item) => item === archiveFormat)) {
                setValidation(true)
                return
            }
            setConfirmLoading(true)
            dispatch(addProgress(defaultValue))
            dispatch(setFileName(null))
            setSuccess(false)
            uploadProject(data, defaultValue, refreshProgress, setConfirmLoading)
                .then((response) => {
                    if (response && response.data) {
                        dispatch(setCompareToId(null))
                        dispatch(setProjectId({projectId: response.data.project.id, id: defaultValue.id,}))
                        dispatch(getProjectsThunk(projectOptions.page, teamId ? `&teamID=${teamId}` : '',
                            null, projectOptions.sort.sortBy.length ? `&sortBy=${projectOptions.sort.sortBy}` : '',
                            projectOptions.sort.sortValue.length ? `&sortValue=${projectOptions.sort.sortValue}` : '',
                            projectOptions.filter.filterBy.length ? `&filterBy=${projectOptions.filter.filterBy}` : '',
                            projectOptions.filter.filterValue.length ? `&filterValue=${projectOptions.filter.filterValue}` : ""))
                        if (!user.entity.hasProjects) {
                            refreshUser()
                        }
                    }
                }).catch((e) => {
                if (e.response?.status === 400) {
                    const finded = progress.entity.find((item) => item.id === defaultValue.id)
                    if (finded) {
                        const newArr: IProgress = {
                            ...finded,
                            isError: true,
                            messageError: e.response.data.message
                        }
                        refreshProgress(newArr)
                    }
                    return
                }
                if (e.name === 'TypeError') {
                    const finded = progress.entity.find((item) => item.id === defaultValue.id)
                    if (finded) {
                        const newArr: IProgress = {
                            ...finded,
                            isCanceled: true,
                        }
                        refreshProgress(newArr)
                        return
                    }
                }
                if (e.response.data.status === 403) {
                    navigate('/403')
                }
                if (e.response.data.status === 500) {
                    navigate('/500')
                }
                if (e.response && e.response.status === 401) {
                    localStorage.removeItem(TOKEN)
                    navigate('/login')
                }


            }).finally(() => {
                setConfirmLoading(false)
            })
        } else {
            setConfirmLoading(true)
            dispatch(setFileName(null))
            uploadViaLinkProjects(viaLinkData)
                .then((response) => {
                    if (response && response.data) {
                        setSuccess(true)
                        dispatch(setCompareToId(null))
                        dispatch(setProjectId({projectId: response.data.project.id, id: defaultValue.id,}))
                        dispatch(getProjectsThunk(projectOptions.page, teamId ? `&teamID=${teamId}` : '',
                            null, projectOptions.sort.sortBy.length ? `&sortBy=${projectOptions.sort.sortBy}` : '',
                            projectOptions.sort.sortValue.length ? `&sortValue=${projectOptions.sort.sortValue}` : '',
                            projectOptions.filter.filterBy.length ? `&filterBy=${projectOptions.filter.filterBy}` : '',
                            projectOptions.filter.filterValue.length ? `&filterValue=${projectOptions.filter.filterValue}` : ""))
                        if (!user.entity.hasProjects) {
                            refreshUser()
                        }
                    }
                }).catch((e) => {
                setSuccess(false)
                if (e.response.data.status === 403) {
                    navigate('/403')
                }
                if (e.response.data.status === 500) {
                    navigate('/500')
                }
                if (e.response && e.response.status === 401) {
                    localStorage.removeItem(TOKEN)
                    navigate('/login')
                }

            }).finally(() => {
                setConfirmLoading(false)
            })
        }
    }
    if (!user.entity.id) return <Loader/>
    const permission = user.entity.role?.find((item) => item.teamID.toString() === teamId)?.permissions
    return (
        <section className={'upload'}>
            <div className={'upload__intro'}>
                {teamId ? permission && permission.canUploadProjects && <div className={'upload-form'}>
                    <div onClick={visibleToggle} className={'upload-form__title-wrapper'}>
                        <h2 className={'upload-form__title'}>
                            {t('analyzeTitle')}
                        </h2>
                        <div
                            className={`upload-form__arrow ${visible ? 'upload-form__arrow--hide' : ''}`
                            }/>
                    </div>
                    {visible && <>
                        <div className={'upload-form__inputs'}>
                            <Selects resetSuccess={resetSuccess}/>
                            <div className={'upload-form__btn-wrapper'}>
                                <button onClick={onUpload} type={'submit'}
                                        className={'upload-form__btn'}>
                                    {t('runAnalyze')}
                                </button>
                                {success && <p className={'upload-form__success'}>
                                    {t('projectUploaded')}
                                </p>}
                                {validation && <p className={'upload-form__error'}>
                                    {t('selectProject')}
                                </p>}
                            </div>
                        </div>
                        {progress.entity.length > 0 &&
                        progress.entity.map((item) => <ProgressResult key={item.id}
                                                                      result={result}
                                                                      progressEntity={item}/>)}
                    </>}
                </div> : <div className={'upload-form'}>
                    <div onClick={visibleToggle} className={'upload-form__title-wrapper'}>
                        <h2 className={'upload-form__title'}>
                            {t('analyzeTitle')}
                        </h2>
                        <div onClick={visibleToggle}
                             className={
                                 `upload-form__arrow ${visible ? 'upload-form__arrow--hide' : ''}`
                             }/>
                    </div>
                    {visible && <>
                        <div className={'upload-form__inputs'}>
                            <Selects resetSuccess={resetSuccess}/>
                            <div className={'upload-form__btn-wrapper'}>
                                <button onClick={onUpload} type={'submit'}
                                        className={'upload-form__btn'}>
                                    {t('runAnalyze')}
                                </button>
                                {success && <p className={'upload-form__success'}>
                                    {t('projectUploaded')}
                                </p>}
                                {validation && <p className={'upload-form__error'}>
                                    {t('selectProject')}
                                </p>}
                            </div>
                        </div>
                        {progress.entity.length > 0 &&
                        progress.entity.map((item) => <ProgressResult key={item.id}
                                                                      result={result}
                                                                      progressEntity={item}/>)}
                    </>}
                </div>}
                <div className={'database__wrapper'}>
                    <div className={'database__wrapper--item'}>
                        {teamId ? <>
                            <Link to={`/teams/${teamId}/projects`}
                                  className={`database__title ${pathname === `/teams/${teamId}/projects` ? 'database__title--active' : ''}`}>
                                <svg width="17" height="17" viewBox="0 0 20 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M1.12024 0.5C0.523232 0.5 0.0592274 1.01972 0.126636 1.61291L0.519347 5.06877H18.7012L18.2828 1.38709C18.2254 0.881746 17.7978 0.5 17.2892 0.5H1.12024ZM18.8716 6.56877H0.689801L1.71755 15.6129C1.77497 16.1183 2.20255 16.5 2.71115 16.5H18.8801C19.4771 16.5 19.9411 15.9803 19.8737 15.3871L18.8716 6.56877ZM3.99975 8.74992C3.58553 8.74992 3.24975 9.08571 3.24975 9.49992C3.24975 9.91413 3.58553 10.2499 3.99975 10.2499H13.9997C14.414 10.2499 14.7497 9.91413 14.7497 9.49992C14.7497 9.08571 14.414 8.74992 13.9997 8.74992H3.99975ZM3.74975 12.4999C3.74975 12.0857 4.08553 11.7499 4.49975 11.7499H14.4997C14.914 11.7499 15.2497 12.0857 15.2497 12.4999C15.2497 12.9141 14.914 13.2499 14.4997 13.2499H4.49975C4.08553 13.2499 3.74975 12.9141 3.74975 12.4999Z"
                                          fill="#252425"/>
                                </svg>
                                {width && width > 575 ? t('projects') : ''}
                                {pathname === `/teams/${teamId}/projects` && width <= 575 ? t('projects') : ''}
                            </Link>
                            <Link to={`/teams/${teamId}/members`}
                                  className={`database__title ${pathname === `/teams/${teamId}/members` ? 'database__title--active' : ''}`}>
                                <svg width="20" height="17" viewBox="0 0 20 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M0.126636 1.61291C0.0592274 1.01972 0.523232 0.5 1.12024 0.5H17.2892C17.7978 0.5 18.2254 0.881746 18.2828 1.38709L19.3183 10.4998H9.15085C8.66047 10.4998 8.24245 10.8554 8.1638 11.3394L7.3252 16.5H2.71115C2.20255 16.5 1.77497 16.1183 1.71755 15.6129L0.126636 1.61291ZM10.0015 12.4998H19.5456L19.8737 15.3871C19.9398 15.9692 19.4943 16.4805 18.9135 16.4995L9.35148 16.4997L10.0015 12.4998ZM14.9998 5.99978C14.9998 6.82821 14.3283 7.49978 13.4998 7.49978C12.6714 7.49978 11.9998 6.82821 11.9998 5.99978C11.9998 5.17136 12.6714 4.49978 13.4998 4.49978C14.3283 4.49978 14.9998 5.17136 14.9998 5.99978ZM16.9998 5.99978C16.9998 7.93278 15.4328 9.49978 13.4998 9.49978C11.5668 9.49978 9.99984 7.93278 9.99984 5.99978C9.99984 4.06679 11.5668 2.49978 13.4998 2.49978C15.4328 2.49978 16.9998 4.06679 16.9998 5.99978ZM8.00041 6.5C8.00041 7.60457 7.10498 8.5 6.00041 8.5C4.89584 8.5 4.00041 7.60457 4.00041 6.5C4.00041 5.39543 4.89584 4.5 6.00041 4.5C7.10498 4.5 8.00041 5.39543 8.00041 6.5ZM6.2195 14.5001L4.244 14.5002C3.60511 14.5002 3.13006 13.9093 3.26733 13.2853L3.92732 10.2854C4.0282 9.82683 4.43447 9.50022 4.90397 9.50022H8.01983C7.54315 9.50022 7.13273 9.83668 7.03925 10.3041L6.23926 14.3041C6.22605 14.3701 6.21964 14.4357 6.2195 14.5001Z"
                                          fill="#252425"/>
                                </svg>
                                {width && width > 575 ? t('members') : ''}
                                {pathname === `/teams/${teamId}/members` && width <= 575 ? t('members') : ''}
                            </Link>
                            {<Link to={`/teams/${teamId}/settings`}
                                   className={`database__title ${pathname === `/teams/${teamId}/settings` ? 'database__title--active' : ''}`}>
                                <svg width="20" height="17" viewBox="0 0 20 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M9.88639 0.5C9.8696 0.528365 9.85304 0.556874 9.83671 0.585526L10.8739 1.473C10.6451 1.93184 10.4901 2.43174 10.423 2.95563L9.06733 3.11603C9.04331 3.45089 9.04717 3.79231 9.08102 4.13771C9.11488 4.4831 9.17739 4.81877 9.26596 5.1426L10.627 5.03671C10.7945 5.53761 11.0436 5.99791 11.3572 6.40357L10.512 7.47555C10.9592 7.99218 11.4919 8.42976 12.0855 8.76811L12.973 7.73088C13.4318 7.95976 13.9317 8.11474 14.4556 8.1818L14.616 9.53749C14.9509 9.56151 15.2923 9.55765 15.6377 9.52379C15.9831 9.48994 16.3188 9.42743 16.6426 9.33885L16.5367 7.97782C17.0376 7.81031 17.4979 7.56122 17.9036 7.2476L18.9755 8.09277C18.9967 8.07448 19.0177 8.05606 19.0385 8.03749L19.8737 15.3871C19.9411 15.9803 19.4771 16.5 18.8801 16.5H10.5345C10.7413 16.2241 10.9266 15.9321 11.0884 15.6266L9.91643 14.6848C10.1529 14.1718 10.3065 13.6161 10.3626 13.037L11.8495 12.8145C11.8646 12.4451 11.8488 12.0695 11.7998 11.6905C11.7508 11.3112 11.6706 10.9436 11.562 10.59L10.0673 10.7527C9.86586 10.207 9.57599 9.70867 9.21695 9.27271L10.111 8.06399C9.60115 7.51036 8.99982 7.04667 8.33477 6.69429L7.39293 7.86623C6.88006 7.62985 6.32451 7.47621 5.74552 7.42015L5.52299 5.9331C5.15345 5.918 4.77763 5.93382 4.39845 5.98281C4.01937 6.03179 3.65197 6.11197 3.29849 6.22045L3.46121 7.71529C2.91549 7.91667 2.41724 8.20646 1.98129 8.56542L0.819001 7.70572L0.126636 1.61291C0.0592274 1.01972 0.523232 0.5 1.12024 0.5H9.88639ZM15.3125 6.20636C16.7783 6.06269 17.85 4.75801 17.7064 3.29228C17.5627 1.82654 16.258 0.754794 14.7923 0.898461C13.3265 1.04213 12.2548 2.34681 12.3985 3.81254C12.5421 5.27828 13.8468 6.35002 15.3125 6.20636ZM8.35989 12.1349C8.58212 13.8549 7.36789 15.4295 5.64783 15.6517C3.92777 15.8739 2.35323 14.6597 2.131 12.9396C1.90877 11.2196 3.123 9.64503 4.84306 9.4228C6.56312 9.20057 8.13766 10.4148 8.35989 12.1349Z"
                                          fill="#252425"/>
                                </svg>
                                {width && width > 575 ? t('settings') : ''}
                                {pathname === `/teams/${teamId}/settings` && width <= 575 ? t('settings') : ''}
                            </Link>}
                        </> : <Link to={'/projects'} className={'database__title database__title--active'}>
                            <svg width="17" height="17" viewBox="0 0 20 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M1.12024 0.5C0.523232 0.5 0.0592274 1.01972 0.126636 1.61291L0.519347 5.06877H18.7012L18.2828 1.38709C18.2254 0.881746 17.7978 0.5 17.2892 0.5H1.12024ZM18.8716 6.56877H0.689801L1.71755 15.6129C1.77497 16.1183 2.20255 16.5 2.71115 16.5H18.8801C19.4771 16.5 19.9411 15.9803 19.8737 15.3871L18.8716 6.56877ZM3.99975 8.74992C3.58553 8.74992 3.24975 9.08571 3.24975 9.49992C3.24975 9.91413 3.58553 10.2499 3.99975 10.2499H13.9997C14.414 10.2499 14.7497 9.91413 14.7497 9.49992C14.7497 9.08571 14.414 8.74992 13.9997 8.74992H3.99975ZM3.74975 12.4999C3.74975 12.0857 4.08553 11.7499 4.49975 11.7499H14.4997C14.914 11.7499 15.2497 12.0857 15.2497 12.4999C15.2497 12.9141 14.914 13.2499 14.4997 13.2499H4.49975C4.08553 13.2499 3.74975 12.9141 3.74975 12.4999Z"
                                      fill="#252425"/>
                            </svg>
                            {t('projects')}
                        </Link>}
                    </div>
                    {pathname !== `/teams/${teamId}/settings` ? pathname === `/teams/${teamId}/members` ?
                        <MembersSearchInput/> :
                        <ProjectSearchInput/> : null}
                </div>
            </div>
        </section>
    )
}

export default UploadForm
