import React from 'react'
import '../server-work-page.css'
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

interface props {

}

const NotificationsForm: React.FC<props> = () => {
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm();
    const {t} = useTranslation()
    const onFinish = (e: any) => {

    }
    return (
        <form onSubmit={handleSubmit(onFinish)} className={'notification__form'}>
            <input {...register('email', {required: true, onBlur: e => setValue('email', e.target.value.trim())})}
                   className={'notification__input'} placeholder={t('emailPlaceholder')}/>
            <button type={'submit'} className={'notification__btn'}>
                {t('maintenance.getNotification')}
            </button>
        </form>
    )
}

export default NotificationsForm
