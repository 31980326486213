import React from 'react'
import './login.css'
import LoginForm from "./components/login-form/login-form";
import {setDocumentTitle} from "../../utils/setDocumentTitle/setDocumentTitle";
import {useTranslation} from "react-i18next";

interface props {

}

const Login: React.FC<props> = () => {
    const {t} = useTranslation()
    setDocumentTitle(t('enter'))
    return (
        <section className={'login'}>
            <div className={'login-top'}>
                <h2 className={'login-top__title'}>
                    {t('title')}
                </h2>
                <p className={'login-top__text'}>
                    {t('description')}
                </p>
            </div>
            <LoginForm/>
        </section>
    )
}

export default Login
