import makeRequest from "../makeRequest";
import {IAddMemberData} from "../../interfaces/team/team";

export function getTeamsApi() {
    return makeRequest.get('api/teams').then((response) => {
        if (response && response.data) {
            return response.data
        }
    })
}

export function getTeamApi(id: string) {
    return makeRequest.get(`api/teams/${id}`).then((response) => {
        if (response && response.data) {
            return response.data
        }
    })
}

export function createTeamApi(data: any) {

    return makeRequest({
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        url: 'api/teams',
        data: data
    })
}


export function addMemberToTeam(data: IAddMemberData) {
    return makeRequest({
        method: "POST",
        url: "api/invitations",
        data: data
    })
}


export function editPercentageRange(id: string, data: { mediumPointIntersection: string, highPointIntersection: string }) {
    const newData = {
        mediumPointIntersection: Number(data.mediumPointIntersection),
        highPointIntersection: Number(data.highPointIntersection)
    }
    return makeRequest({
        method: "PUT",
        url: `api/teams/${id}`,
        data: newData
    })
}

export function deleteTeam(id: number, name: string) {
    return makeRequest({
        method: 'DELETE',
        url: `api/teams/${id}`,
        data: {
            name: name
        }
    })
}