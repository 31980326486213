import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setCount} from "../../../../../../../store/slices/hash-slice";
import {hashSelector} from "../../../../../../../store/slices/hash-slice";
import {useTranslation} from "react-i18next";

interface props {
    disableFirst: () => void
    handlePageChange: (count: number) => void
}

const ItemsAtPage: React.FC<props> = ({handlePageChange, disableFirst}) => {
    const pages = [25, 50, 100]
    const [visible, setVisible] = useState<boolean>(false)
    const {hashes} = useSelector(hashSelector)
    const {t} = useTranslation()
    const visibleHandler = (e: any) => {
        setVisible(!visible)
    }
    const dispatch = useDispatch()
    const hideHandler = () => {
        setVisible(false)
    }
    const wrapperRef = useRef(null);
    const hideOptions = (e: any) => {
        if (wrapperRef.current) {
            // @ts-ignore
            if (!wrapperRef.current.contains(e.target)) {
                hideHandler()
            }
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', hideOptions)
        return () => document.removeEventListener('mouseup', hideOptions)
    }, [])
    const onSetCount = (page: number) => {
        disableFirst()
        handlePageChange(1)
        dispatch(setCount(page))
        hideHandler()
    }
    return (
        <div ref={wrapperRef} className={'change-page hashes-page'}>
            <span>{t('onPage')}</span>
            <div onClick={visibleHandler} className={'change-page__btn'}>
                {hashes.count} <span className={'change-page__arrow'}/>
            </div>
            {visible && <div className={'change-page__dropdown'}>
                {pages.map((page) => (
                    <div onClick={() => onSetCount(page)} className={'change-page__dropdown--item'} key={page}>
                        {page}
                    </div>
                ))}
            </div>}
        </div>
    )
}

export default ItemsAtPage
