import {Action, createSlice, PayloadAction, ThunkAction} from "@reduxjs/toolkit"
import {IUser, IAuth, IUserState, ILogin} from '../../interfaces/user/user'
import {removeToken, setToken} from "../../utils/authLocalStorage";
import {userAuth} from "../../api/user/user";

const initialState: IUserState = {
    user: {
        entity: {} as IUser,
        loaded: false,
        errors: {
            isError: false,
            message: ''
        }
    }
}

export const userSelector = (state: { user: IUserState }) => state.user
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        auth: (state, action: PayloadAction<IUser>) => {
            state.user.entity = action.payload
            state.user.loaded = true
            state.user.errors.isError = false
        },
        authError: (state, action: PayloadAction<string>) => {
            state.user.loaded = true
            state.user.errors.isError = true
            state.user.errors.message = action.payload
        },
        logout: (state) => {
            state.user.entity = {} as IUser
            removeToken()
        },
        setLoaded: (state, action: PayloadAction<boolean>) => {
            state.user.loaded = action.payload
        }
    }
})

export const {auth, authError, logout, setLoaded} = userSlice.actions


export default userSlice.reducer