import React from 'react'
import {useParams} from "react-router-dom";
import IntersectionRange from "./intersection-range/intersection-range";
import TeamRoles from "./team-roles/team-roles";
import {useSelector} from "react-redux";
import {userSelector} from "../../../store/slices/user-slice";
import Loader from "../../../shared/loader/loader";
import LeaveFromTeam from "./leave-from-team/leave-from-team";
import DeleteTeam from "./delete-team/delete-team";
import {teamSelector} from "../../../store/slices/team-slice";

interface props {

}

const SettingsWrapper: React.FC<props> = () => {
    const {teamId} = useParams()
    const {user} = useSelector(userSelector)
    const {team} = useSelector(teamSelector)
    if (!user.loaded) {
        return (
            <Loader/>
        )
    }


    const role = user.entity?.role?.find((r) => r.teamID.toString() === teamId)?.role


    return (
        <section className={'database team-settings'}>
            {role && role === 'admin' && <IntersectionRange/>}
            {role && role === 'admin' && <TeamRoles/>}
            <LeaveFromTeam/>
            {team.entity?.owner?.id === user.entity.id && <DeleteTeam/>}
        </section>
    )
}

export default SettingsWrapper
