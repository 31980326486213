import React, {useEffect, useState} from 'react'
import MemberSortOptions from "./member-sort-options";
import MemberAddForm from "./member-add-form/member-add-form";
import {getProjects} from "../../../../../store/slices/projects-slice";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getMembersApi} from "../../../../../api/members/members";
import Loader from "../../../../../shared/loader/loader";
import {userSelector} from "../../../../../store/slices/user-slice";
import {membersOptionsSelector} from "../../../../../store/slices/members-sort-slice";
import {useTranslation} from "react-i18next";
import {getTeamRolesThunk} from "../../../../../store/slices/team-roles-slice";
import MemberChangePage from "./member-change-page";

interface props {
    handlePageChange: (num: number) => void
}

const MembersSort: React.FC<props> = ({handlePageChange}) => {
    const [currentSortingOption, setCurrentSortingOption] = useState<string>('')
    const {teamId} = useParams()
    const {user} = useSelector(userSelector)
    const {members_options} = useSelector(membersOptionsSelector)
    const setSortingOption = (value: string) => {
        if (currentSortingOption === value) {
            return setCurrentSortingOption('')
        }
        setCurrentSortingOption(value)
    }
    const {t} = useTranslation()
    const sortingOptions =
        [
            {
                text: t('username'),
                value: 'username'
            }
        ]
    const navigate = useNavigate()

    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const request = () => {
        setCurrentSortingOption('')
        setConfirmLoading(true)
        getMembersApi(`?page=1`, ``, ``
            , ``, teamId ? `&teamID=${teamId}` : '')
            .then((response) => {
                dispatch(getProjects(response))
            })
            .catch((e) => {
                if (e.response.status === 500 || e.response.status === 0) {
                    navigate('/500')
                }
            }).finally(() => setConfirmLoading(false))
    }
    const permission = user.entity?.role?.find((item) => item.teamID.toString() === teamId)?.permissions
    useEffect(() => {
        if (teamId) {
            if (permission && permission.canAddParticipants) {
                dispatch(getTeamRolesThunk(teamId))
            }
        }
    }, [permission])
    return (
        <div className={'project-sorting__wrapper member-sorting__wrapper'}>
            <div className={'project-sorting__wrap'}>
                {confirmLoading && <Loader/>}
                <div className={'project-sorting__title'}>
                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.08594 0.5L0.818359 3.82227C0.704427 3.93164 0.647461 4.06152 0.647461 4.21191C0.647461 4.36686 0.695312 4.49219 0.791016 4.58789C0.886719 4.68359 1.01204 4.73145 1.16699 4.73145C1.31283 4.73145 1.43587 4.68132 1.53613 4.58105L2.93066 3.14551L4.01074 1.92188L3.94922 3.3916V11.8203C3.94922 11.9707 3.99707 12.096 4.09277 12.1963C4.19303 12.3011 4.32064 12.3535 4.47559 12.3535C4.63053 12.3535 4.75586 12.3011 4.85156 12.1963C4.94727 12.096 4.99512 11.9707 4.99512 11.8203V3.3916L4.94043 1.92188L6.02051 3.14551L7.41504 4.58105C7.5153 4.68132 7.63835 4.73145 7.78418 4.73145C7.93457 4.73145 8.05762 4.68359 8.15332 4.58789C8.25358 4.49219 8.30371 4.36686 8.30371 4.21191C8.30371 4.06152 8.24674 3.93164 8.13281 3.82227L4.86523 0.5C4.76042 0.386068 4.63053 0.329102 4.47559 0.329102C4.3252 0.324544 4.19531 0.38151 4.08594 0.5ZM12.1318 12.1758L15.3994 8.84668C15.5133 8.73275 15.5703 8.60059 15.5703 8.4502C15.5703 8.2998 15.5202 8.17676 15.4199 8.08105C15.3242 7.98535 15.2012 7.9375 15.0508 7.9375C14.9095 7.9375 14.7887 7.98991 14.6885 8.09473L13.2939 9.52344L12.2139 10.7539L12.2686 9.27734V0.848633C12.2686 0.698242 12.2184 0.575195 12.1182 0.479492C12.0225 0.379232 11.8971 0.329102 11.7422 0.329102C11.5918 0.329102 11.4665 0.379232 11.3662 0.479492C11.2705 0.575195 11.2227 0.698242 11.2227 0.848633V9.27734L11.2773 10.7539L10.1973 9.52344L8.80273 8.09473C8.70247 7.98991 8.57943 7.9375 8.43359 7.9375C8.27865 7.9375 8.15332 7.98535 8.05762 8.08105C7.96647 8.17676 7.9209 8.2998 7.9209 8.4502C7.9209 8.60059 7.97786 8.73275 8.0918 8.84668L11.3525 12.1758C11.4619 12.2897 11.5918 12.3467 11.7422 12.3467C11.8926 12.3512 12.0225 12.2943 12.1318 12.1758Z"
                            fill="#DDDDDD"/>
                    </svg>
                    {t('sorting')}
                </div>
                <div className={'project-sorting__item--additional'}>
                    <div
                        className={`project-sorting ${currentSortingOption.length > 0 ? 'project-sorting--active' : ''}`}>
                        {sortingOptions.map(({value, text}) => (
                            <div onClick={() => setSortingOption(value)} key={value}
                                 className={`project-sorting__item ${currentSortingOption === value ? "project-sorting__item--active" : ""}`}>
                                {text}
                            </div>
                        ))}
                        {members_options.sort.sortValue.length > 0 &&
                        <div onClick={request} className={`project-sorting__reset`}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.0625 11.8203C1.13542 11.8984 1.22396 11.9479 1.32812 11.9688C1.43229 11.9948 1.53385 11.9948 1.63281 11.9688C1.73177 11.9479 1.82292 11.8984 1.90625 11.8203L6.69531 7.02344L11.4922 11.8203C11.6068 11.9349 11.7474 11.9922 11.9141 11.9922C12.0807 11.9974 12.2214 11.9401 12.3359 11.8203C12.4505 11.7005 12.5078 11.5573 12.5078 11.3906C12.5078 11.224 12.4505 11.0859 12.3359 10.9766L7.53906 6.17969L12.3359 1.38281C12.4505 1.27344 12.5078 1.13542 12.5078 0.96875C12.513 0.796875 12.4557 0.653646 12.3359 0.539062C12.2214 0.424479 12.0807 0.367188 11.9141 0.367188C11.7474 0.367188 11.6068 0.424479 11.4922 0.539062L6.69531 5.33594L1.90625 0.539062C1.82292 0.466146 1.73177 0.416667 1.63281 0.390625C1.53385 0.364583 1.43229 0.364583 1.32812 0.390625C1.22396 0.411458 1.13542 0.460938 1.0625 0.539062C0.984375 0.617188 0.932292 0.708333 0.90625 0.8125C0.880208 0.911458 0.880208 1.01302 0.90625 1.11719C0.932292 1.22135 0.984375 1.3099 1.0625 1.38281L5.85156 6.17969L1.0625 10.9766C0.984375 11.0495 0.929688 11.138 0.898438 11.2422C0.872396 11.3464 0.872396 11.4505 0.898438 11.5547C0.924479 11.6589 0.979167 11.7474 1.0625 11.8203Z"
                                    fill="#A9A9A8"/>
                            </svg>
                        </div>}
                    </div>
                    <MemberSortOptions value={currentSortingOption}/>
                </div>
            </div>
            <div className={'project-sorting__member'}>
                <MemberChangePage handlePageChange={handlePageChange}/>
                {permission && permission.canAddParticipants && <MemberAddForm/>}
            </div>
        </div>
    )
}

export default MembersSort
