import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {changePerPage, membersSelector} from "../../../../../store/slices/members-slice";

interface props {
    handlePageChange: (count: number) => void
}

const MemberChangePage: React.FC<props> = ({handlePageChange}) => {
    const pages = [10, 20, 50, 100]
    const [visible, setVisible] = useState<boolean>(false)
    const {members} = useSelector(membersSelector)
    const visibleHandler = () => {
        setVisible(!visible)
    }
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const hideHandler = () => {
        setVisible(false)
    }
    const wrapperRef = useRef(null);
    const hideOptions = (e: any) => {
        if (wrapperRef.current) {
            // @ts-ignore
            if (!wrapperRef.current.contains(e.target)) {
                hideHandler()
            }
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', hideOptions)
        return () => document.removeEventListener('mouseup', hideOptions)
    }, [])
    const onSetCount = (page: number) => {
        handlePageChange(1)
        dispatch(changePerPage(page))
        hideHandler()
    }
    return (
        <div ref={wrapperRef} className={'change-page member-change-page'} style={{marginRight: '15px'}}>
            <span>{t('onPage')}</span>
            <div onClick={visibleHandler} className={'change-page__btn'}>
                {members.entity.items_at_page} <span className={'change-page__arrow'}/>
            </div>
            {visible && <div className={'change-page__dropdown'}>
                {pages.map((page) => (
                    <div onClick={() => onSetCount(page)} className={'change-page__dropdown--item'} key={page}>
                        {page}
                    </div>
                ))}
            </div>}
        </div>
    )
}

export default MemberChangePage
