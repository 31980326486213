import React, {useEffect, useState} from 'react'
import '../../../members.css'
import MembersItem from "./members-item";
import {useDispatch, useSelector} from "react-redux";
import {getMembersThunk, membersSelector, resetMembers, setPage} from "../../../../../store/slices/members-slice";
import Loader from "../../../../../shared/loader/loader";
import MembersSort from "./members-sort";
import {useNavigate, useParams} from "react-router-dom";
import {teamSelector} from "../../../../../store/slices/team-slice";
import {Pagination} from "../../../../../shared/pagination";

interface props {

}

const MembersList: React.FC<props> = () => {
    const {teamId} = useParams()
    const {team} = useSelector(teamSelector)
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
    const handlePageChange = (count: number) => {
        setIsFirstLoad(false)
        dispatch(setPage(count))
    };
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {members} = useSelector(membersSelector)
    useEffect(() => {
        if (teamId) {
            dispatch(getMembersThunk(members.page, teamId))
        }
        return () => {
            dispatch(resetMembers())
        }
    }, [teamId])
    useEffect(() => {
        if (!isFirstLoad) {
            dispatch(getMembersThunk(members.page, teamId, members.entity.items_at_page))
        }
    }, [members.page, members.entity.items_at_page])

    if (!members.loaded) {
        return (
            <Loader/>
        )
    }
    if (members.errors?.isError && members.errors?.message === 'Permission denied') {
        navigate('/projects')
    }
    const owner = members.entity?.items?.find((member) => member.id === team.entity.owner.id)
    return (
        <>
            <div className={'filter__search members__search'}>
                {members.confirmLoading && <Loader/>}
                <MembersSort handlePageChange={handlePageChange}/>
            </div>
            <div className={'members__wrapper'}>
                {owner && <MembersItem member={owner}/>}
                {members.entity?.items.filter((item) => {
                    if (item.id === team.entity.owner.id) {
                        return false
                    }
                    return item
                }).map((member) => (
                    <MembersItem key={member.id} member={member}/>
                ))}
            </div>
            {members.entity?.items?.length > 0 &&
            <Pagination current={members.page}
                        onChange={handlePageChange}
                        last={members.entity.pages_count}
                        hasNext={members.entity.pages_count > members.page}
                        disabled={false}/>}
        </>
    )
}

export default MembersList
