import makeRequest from "../makeRequest";

export function getTicketsApi(page: string, sortBy?: string, sortValue?: string, filterBy?: string, filterValue?: string) {
    return makeRequest.get(`api/tickets${page ? page : ''}${sortBy ? sortBy : ''}${sortValue ? sortValue : ''}${filterBy ? filterBy : ''}${filterValue ? filterValue : ''}
    `,)
}


export function getTicketApi(id: string) {
    return makeRequest.get(`api/tickets/${id}`)
}

export function createTicketApi(data: { text: string, topic: string, fileName?: File }) {
    const newData = new FormData()
    newData.append('text', data.text)
    newData.append('topic', data.topic)
    if (data.fileName) {
        newData.append('fileName', data.fileName)
    }
    return makeRequest({
        method: "POST",
        url: `api/tickets`,
        data: newData,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}


