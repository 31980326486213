import React, {useEffect, useState} from 'react'
import '../../aside.css'
import {createTeamApi} from "../../../../api/team/team";
import {useForm} from "react-hook-form";
import Loader from "../../../../shared/loader/loader";
import {useDispatch, useSelector} from "react-redux";
import {createTeam, setVisible, teamSelector} from "../../../../store/slices/team-slice";
import {sidebarSelector} from "../../../../store/slices/sidebar-slice";
import TeamsCreateSteps from "./teams-create-steps";
import {useTranslation} from "react-i18next";

interface props {

}

const TeamsCreateModal: React.FC<props> = () => {
    const {teams} = useSelector(teamSelector)
    const dispatch = useDispatch()
    const {sidebar} = useSelector(sidebarSelector)
    const showHandler = () => {
        document.body.style.overflow = 'hidden'
        dispatch(setVisible(true))
    }


    const {t} = useTranslation()

    return (
        <>
            <div>
                <button onClick={showHandler}
                        className={`aside__create-team ${sidebar.isVisible ? "" : "aside__item--email--hide"}`}>
                    <p>{t('createTeam')}</p>
                    <svg width="16" height="19" viewBox="0 0 13 13" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.882812 6.16406C0.882812 6.33073 0.942708 6.47396 1.0625 6.59375C1.18229 6.70833 1.32292 6.76562 1.48438 6.76562H6.33594V11.6172C6.33594 11.7786 6.39323 11.9167 6.50781 12.0312C6.6276 12.151 6.77083 12.2109 6.9375 12.2109C7.09896 12.2109 7.23698 12.151 7.35156 12.0312C7.46615 11.9167 7.52344 11.7786 7.52344 11.6172V6.76562H12.3906C12.5521 6.76562 12.6901 6.70833 12.8047 6.59375C12.9245 6.47396 12.9844 6.33073 12.9844 6.16406C12.9844 6.0026 12.9245 5.86458 12.8047 5.75C12.6901 5.63021 12.5521 5.57031 12.3906 5.57031H7.52344V0.710938C7.52344 0.554688 7.46615 0.416667 7.35156 0.296875C7.23698 0.177083 7.09896 0.117188 6.9375 0.117188C6.77083 0.117188 6.6276 0.177083 6.50781 0.296875C6.39323 0.416667 6.33594 0.554688 6.33594 0.710938V5.57031H1.48438C1.32292 5.57031 1.18229 5.63021 1.0625 5.75C0.942708 5.86458 0.882812 6.0026 0.882812 6.16406Z"
                            fill="white"/>
                    </svg>
                </button>
            </div>

        </>
    )
}

export default TeamsCreateModal
