import React, {useEffect} from 'react'
import Header from "../../components/header/header";
import TicketForm from "./components/ticket-form/ticket-form";
import TicketList from "./components/ticket-list/ticket-list";
import {setDocumentTitle} from "../../utils/setDocumentTitle/setDocumentTitle";
import {useDispatch} from "react-redux";
import {resetFilters, resetSort} from "../../store/slices/ticket-filter-slice";
import {useTranslation} from "react-i18next";

interface props {

}

const Support: React.FC<props> = () => {
    const {t} = useTranslation()
    setDocumentTitle(t(`support`))
    const dispatch = useDispatch()
    useEffect(() => {
        return () => {
            dispatch(resetFilters())
            dispatch(resetSort())
        }
    }, [])
    return (
        <>
            <TicketForm/>
            <TicketList/>
        </>
    )
}

export default Support
