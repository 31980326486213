import React, {useEffect} from 'react'
import './counters.css'
import {useDispatch, useSelector} from "react-redux";
import {
    counterSelector,
    getCounterFilesThunk,
    getProjectCountersThunk, resetProject, setId, setPage
} from "../../../../../../store/slices/counters-slice";
import {useParams} from "react-router-dom";
import Loader from "../../../../../../shared/loader/loader";
import {findColor} from "../../../../../../utils/colorDetecter";
import {projectsSelector} from "../../../../../../store/slices/projects-slice";
import {useTranslation} from "react-i18next";

interface props {
    showHashes: () => void
    hideHashes: () => void
}


const Counters: React.FC<props> = ({showHashes, hideHashes}) => {
    const dispatch = useDispatch()
    const {project} = useSelector(projectsSelector)
    const {t} = useTranslation()
    const {counters, files} = useSelector(counterSelector)
    const getCounterFiles = async (id: number) => {
        hideHashes()
        dispatch(setId(id))
        dispatch(setPage(1))
        dispatch(getCounterFilesThunk(id.toString(), `?page=1`, `&count=${files.count}`, `&ignored=all`, '1'))
        showHashes()
    }


    const {id} = useParams()
    useEffect(() => {
        if (id) {
            dispatch(getProjectCountersThunk(id))
        }
        return () => {
            dispatch(resetProject())
        }
    }, [])

    if (!counters.loaded) {
        return (
            <Loader/>
        )
    }
    return (
        <div className={'intersections counters'}>
            <h3 className={'counters__title'}>{t('intersectionsCounter')}</h3>
            {project.entity.state === 'indexing' || project.entity.state === 'intersection searching' &&
            <div className={'counters__title'}>{t('searchingProgress')}</div>}
            <div className={'counters__wrapper'}>

                {counters.entity.items.length > 0 ? counters.entity.items.map(({count, percentage, id, title}) => (
                    <div onClick={() => getCounterFiles(id)} key={id}
                         className={`counters__item ${files.id === id ? 'counters__item--active' : ''}`}>
                        <h4 className={'counters__name'}>
                            {title}
                        </h4>
                        <div className={'counters__info'}>
                            {files.id === id && <p className={'counters__info--count'}>
                                {count}
                            </p>}
                            <div className={'intersection__item--info'}>
                                <span>{percentage}%</span>
                                <div className={'intersection__item--percentage'}>
                                    <div className={'intersection__item--percentage-info'}
                                         style={{
                                             width: `${percentage === 100 ? percentage - 4 : percentage}%`,
                                             backgroundColor: findColor(percentage)
                                         }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : null}
            </div>
        </div>
    )
}

export default Counters
