import {Action, createSlice, PayloadAction, ThunkAction} from "@reduxjs/toolkit"
import {IProjectFilterState} from "../../interfaces/projects/projectFilters";

const initialState: IProjectFilterState = {
    projectOptions: {
        sort: {
            sortBy: '',
            sortValue: ''
        },
        filter: {
            filterBy: '',
            filterValue: ''
        },
        currentOption: '',
        page: 1,
        types: '',
        count: 10
    }
}


export const filterSelector = (state: { projectOptions: IProjectFilterState }) => state.projectOptions


const filterSlice = createSlice({
    name: 'projectOptions',
    initialState,
    reducers: {
        setSort: (state, action: PayloadAction<{ sortBy: string, sortValue: string }>) => {
            state.projectOptions.sort.sortBy = action.payload.sortBy
            state.projectOptions.sort.sortValue = action.payload.sortValue
        },
        resetSort: (state) => {
            state.projectOptions.sort.sortBy = ''
            state.projectOptions.sort.sortValue = ''
        },
        setFilter: (state, action: PayloadAction<{ filterBy: string, filterValue: string }>) => {
            state.projectOptions.filter.filterBy = action.payload.filterBy
            state.projectOptions.filter.filterValue = action.payload.filterValue
        },
        resetFilters: (state) => {
            state.projectOptions.filter.filterBy = ''
            state.projectOptions.filter.filterValue = ''
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.projectOptions.page = action.payload
        },
        setType: (state, action: PayloadAction<string>) => {
            state.projectOptions.types = action.payload
        },
        typesReset: (state) => {
            state.projectOptions.types = ''
        },
        setOptions: (state, action: PayloadAction<string>) => {
            state.projectOptions.currentOption = action.payload
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.projectOptions.count = action.payload
        }
    }
})

export const {
    resetFilters,
    setFilter,
    resetSort,
    setSort,
    setPage,
    setType,
    typesReset,
    setOptions,
    setCount
} = filterSlice.actions


export default filterSlice.reducer