import React from 'react'

interface props {

}

const Security: React.FC<props> = () => {
    return (
        <section className={'account privacy'}>
            <h2 className={'account__title'}>
                Security
            </h2>
            <div className={'account__info'}>
                <p className={'privacy__text'}>
                    I value your trust in providing us your Personal Information, thus we are striving to use
                    commercially acceptable means of protecting it. But remember that no method of transmission over the
                    internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its
                    absolute security.sclose or use the information for any other purpose.
                </p>
            </div>
        </section>
    )
}

export default Security
