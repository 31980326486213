import React, {useEffect} from 'react'
import PrivacyPolicy from "./components/Privacy-policy";
import InformationCollection from "./components/information-collection";
import LogData from "./components/log-data";
import Cookies from "./components/cookies";
import ServiceProviders from "./components/service-providers";
import Security from "./components/security";
import {setDocumentTitle} from "../../utils/setDocumentTitle/setDocumentTitle";
import {Link} from "react-router-dom";

interface props {

}

const Privacy: React.FC<props> = () => {
    setDocumentTitle(`Privacy and Policy`)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className={'login-top'}>
                <h2 className={'login-top__title'}>
                    AppRefactoring
                </h2>
                <p className={'login-top__text'}>
                    Improved automated code analysis tool
                </p>
                <div className={'privacy__link--wrapper'}>
                    <Link to={'/login'} className={'privacy__link'}>
                        Login
                    </Link>
                    <a href={'https://apprefactoring.com/'} className={'privacy__link'}>
                        Site
                    </a>
                </div>
            </div>
            <PrivacyPolicy/>
            <InformationCollection/>
            <LogData/>
            <Cookies/>
            <ServiceProviders/>
            <Security/>
        </>
    )
}

export default Privacy
