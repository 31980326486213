import React, {useState} from 'react'
import {getProjects} from "../../../../../../../../../store/slices/projects-slice";
import {useDispatch, useSelector} from "react-redux";
import {getProjectsApi} from "../../../../../../../../../api/project/project";
import Loader from "../../../../../../../../../shared/loader/loader";
import {
    filterSelector,
    resetFilters,
    resetSort, setPage,
    setSort,
    typesReset
} from "../../../../../../../../../store/slices/project-filter-slice";
import {useNavigate, useParams} from "react-router-dom";

interface props {
    value: string
}

const Name: React.FC<props> = ({value}) => {
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const {teamId} = useParams()
    const {projectOptions} = useSelector(filterSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const sortStatusHandler = async (status: string) => {
        if (status === projectOptions.sort.sortValue) {
            await dispatch(typesReset())
            await dispatch(resetSort())
            await dispatch(resetFilters())
            dispatch(setSort({sortBy: '', sortValue: ''}))
            request('', '')
            return
        }
        await dispatch(typesReset())
        await dispatch(resetSort())
        await dispatch(resetFilters())
        dispatch(setSort({sortBy: value, sortValue: status}))
        request(value, status)
    }
    const request = (by: string, value: string) => {
        setConfirmLoading(true)
        getProjectsApi(`?page=1`, '', '',
            by ? `&sortBy=${by}` : '',
            value ? `&sortValue=${value}` : '',
            teamId ? `&teamID=${teamId}` : '')
            .then((response) => {
                dispatch(getProjects(response))
                dispatch(setPage(1))
            })
            .catch((e) => {
                if (e.response.status === 500 || e.response.status === 0) {
                    navigate('/500')
                }
            }).finally(() => setConfirmLoading(false))
    }


    return (
        <>
            {confirmLoading && <Loader/>}
            <button onClick={() => sortStatusHandler('asc')}
                    className={`project-sorting__btn ${projectOptions.sort.sortBy === value && projectOptions.sort.sortValue === 'asc' ? 'project-sorting__btn--active' : ""}`}>
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.43848 0.171152L0.170898 3.49342C0.0569661 3.60279 0 3.73268 0 3.88307C0 4.03801 0.0478516 4.16334 0.143555 4.25904C0.239258 4.35475 0.364583 4.4026 0.519531 4.4026C0.665365 4.4026 0.788411 4.35247 0.888672 4.25221L2.2832 2.81666L3.36328 1.59303L3.30176 3.06275V11.4915C3.30176 11.6419 3.34961 11.7672 3.44531 11.8674C3.54557 11.9723 3.67318 12.0247 3.82812 12.0247C3.98307 12.0247 4.1084 11.9723 4.2041 11.8674C4.2998 11.7672 4.34766 11.6419 4.34766 11.4915V3.06275L4.29297 1.59303L5.37305 2.81666L6.76758 4.25221C6.86784 4.35247 6.99089 4.4026 7.13672 4.4026C7.28711 4.4026 7.41016 4.35475 7.50586 4.25904C7.60612 4.16334 7.65625 4.03801 7.65625 3.88307C7.65625 3.73268 7.59928 3.60279 7.48535 3.49342L4.21777 0.171152C4.11296 0.0572193 3.98307 0.000253183 3.82812 0.000253183C3.67773 -0.00430411 3.54785 0.052662 3.43848 0.171152Z"
                        fill="white"/>
                </svg>
            </button>
            <button onClick={() => sortStatusHandler('desc')}
                    className={`project-sorting__btn ${projectOptions.sort.sortBy === value && projectOptions.sort.sortValue === 'desc' ? 'project-sorting__btn--active' : ""}`}>
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.43848 11.8535L0.170898 8.53124C0.0569661 8.42187 0 8.29198 0 8.14159C0 7.98664 0.0478516 7.86132 0.143555 7.76562C0.239258 7.66991 0.364583 7.62206 0.519531 7.62206C0.665365 7.62206 0.788411 7.67219 0.888672 7.77245L2.2832 9.208L3.36328 10.4316L3.30176 8.96191V0.533195C3.30176 0.382804 3.34961 0.257479 3.44531 0.157218C3.54557 0.0524006 3.67318 -8.58307e-06 3.82812 -8.58307e-06C3.98307 -8.58307e-06 4.1084 0.0524006 4.2041 0.157218C4.2998 0.257479 4.34766 0.382804 4.34766 0.533195V8.96191L4.29297 10.4316L5.37305 9.208L6.76758 7.77245C6.86784 7.67219 6.99089 7.62206 7.13672 7.62206C7.28711 7.62206 7.41016 7.66991 7.50586 7.76562C7.60612 7.86132 7.65625 7.98664 7.65625 8.14159C7.65625 8.29198 7.59928 8.42187 7.48535 8.53124L4.21777 11.8535C4.11296 11.9674 3.98307 12.0244 3.82812 12.0244C3.67773 12.029 3.54785 11.972 3.43848 11.8535Z"
                        fill="white"/>
                </svg>
            </button>

        </>
    )
}

export default Name
