import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {IProgressState} from "../../interfaces/progress/progress";
import {IProgress} from "../../pages/upload/components/upload-form/upload-form";


const initialState: IProgressState = {
    progress: {
        entity: []
    }
}

export const progressSelector = (state: { progress: IProgressState }) => state.progress


const progressSlice = createSlice({
    name: 'progress',
    initialState,
    reducers: {
        addProgress: (state, action: PayloadAction<IProgress>) => {
            state.progress.entity = [...state.progress.entity, action.payload]
        },
        changeProgress: (state, action: PayloadAction<IProgress>) => {
            state.progress.entity = state.progress.entity.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload
                }
                return item
            })
        },
        setProjectId: (state, action: PayloadAction<{ id: number, projectId: number }>) => {
            state.progress.entity = state.progress.entity.map((item) => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        projectId: action.payload.projectId
                    }
                }
                return item
            })
        },
        clearProjectProgress: (state, action: PayloadAction<number>) => {
            state.progress.entity = state.progress.entity.filter((item) => {
                if (item.id === action.payload) {
                    return false
                }
                return item
            })
        },
        clearProgresses: (state) => {
            state.progress.entity = []
        }
    }
})


export const {
    addProgress,
    changeProgress,
    setProjectId,
    clearProjectProgress,
    clearProgresses
} = progressSlice.actions

export default progressSlice.reducer