import React from 'react'
import {useTranslation} from "react-i18next";

interface props {
    register: any
}

const ProjectIdInput: React.FC<props> = ({register}) => {
    const {t} = useTranslation()
    return (
        <>
            <label className={'password__label'} style={{color: '#000000', cursor: 'default'}}>
                {t('enterProjectId')}
            </label>
            <div className={'password__input'}>
                <input type={'number'} min={0} {...register('projectId', {required: true})} className={'ticket__input'}
                       placeholder={t('projectId')}/>
            </div>
        </>
    )
}

export default ProjectIdInput
