import React from 'react'
import {IError} from "../../../../../../../interfaces/projects/projectErrors";

interface props {
    error: IError
}

const ErrorsItem: React.FC<props> = ({error}) => {
    const {id, exception, file} = error
    return (
        <div key={id} className={'errors__item'}>
            <h3 className={'errors__item--title'}>
                {file.filepath}
            </h3>
            <code className={'errors__item--text'}>
                {exception}
            </code>
        </div>
    )
}

export default ErrorsItem
