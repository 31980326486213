import React, {useEffect, useState} from 'react'
import './intersections.css'
import Intersection from "./intersection";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {resetHash,} from "../../../../../../store/slices/hash-slice";
import {
    categoriesSelector,
    getCategories,
    getCategoriesThunk,
    resetCategories,
    reverseCategories
} from "../../../../../../store/slices/categories-slice";
import Loader from "../../../../../../shared/loader/loader";
import {Pagination} from "../../../../../../shared/pagination";
import {getCategoriesApi} from "../../../../../../api/categories/categories";
import {useTranslation} from "react-i18next";

interface props {
    showHashes: () => void
    hideHashes: () => void
}

const Intersections: React.FC<props> = ({showHashes, hideHashes}) => {
    const [visible, setVisible] = useState<boolean>(true)
    const {id} = useParams()
    const {t} = useTranslation()
    const [page, setPage] = useState<number>(1)
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const {categories} = useSelector(categoriesSelector)
    const dispatch = useDispatch()
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
    const handlePageChange = (count: number) => {
        setIsFirstLoad(false)
        setPage(count)
    };
    useEffect(() => {
        if (id) {
            dispatch(getCategoriesThunk(id, page))
        }
        return () => {
            dispatch(resetCategories())
            dispatch(resetHash())
        }
    }, [])

    useEffect(() => {
        if (!isFirstLoad && id) {
            setConfirmLoading(true)
            getCategoriesApi(id, page)
                .then((response) => {
                    if (response && response.data) {
                        dispatch(getCategories(response.data))
                    }
                }).finally(() => setConfirmLoading(false))

        }
        return () => {
            setIsFirstLoad(true)
        }
    }, [page])
    const reverseArr = () => dispatch(reverseCategories())
    if (!categories.loaded) {
        return (
            <Loader/>
        )
    }


    return (
        <div className={'intersections'}>
            {confirmLoading && <Loader/>}
            <div className={'intersections__sort-wrapper'}>
                <button onClick={reverseArr} className={'intersections__sort-btn'}>
                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.08594 0.5L0.818359 3.82227C0.704427 3.93164 0.647461 4.06152 0.647461 4.21191C0.647461 4.36686 0.695312 4.49219 0.791016 4.58789C0.886719 4.68359 1.01204 4.73145 1.16699 4.73145C1.31283 4.73145 1.43587 4.68132 1.53613 4.58105L2.93066 3.14551L4.01074 1.92188L3.94922 3.3916V11.8203C3.94922 11.9707 3.99707 12.096 4.09277 12.1963C4.19303 12.3011 4.32064 12.3535 4.47559 12.3535C4.63053 12.3535 4.75586 12.3011 4.85156 12.1963C4.94727 12.096 4.99512 11.9707 4.99512 11.8203V3.3916L4.94043 1.92188L6.02051 3.14551L7.41504 4.58105C7.5153 4.68132 7.63835 4.73145 7.78418 4.73145C7.93457 4.73145 8.05762 4.68359 8.15332 4.58789C8.25358 4.49219 8.30371 4.36686 8.30371 4.21191C8.30371 4.06152 8.24674 3.93164 8.13281 3.82227L4.86523 0.5C4.76042 0.386068 4.63053 0.329102 4.47559 0.329102C4.3252 0.324544 4.19531 0.38151 4.08594 0.5ZM12.1318 12.1758L15.3994 8.84668C15.5133 8.73275 15.5703 8.60059 15.5703 8.4502C15.5703 8.2998 15.5202 8.17676 15.4199 8.08105C15.3242 7.98535 15.2012 7.9375 15.0508 7.9375C14.9095 7.9375 14.7887 7.98991 14.6885 8.09473L13.2939 9.52344L12.2139 10.7539L12.2686 9.27734V0.848633C12.2686 0.698242 12.2184 0.575195 12.1182 0.479492C12.0225 0.379232 11.8971 0.329102 11.7422 0.329102C11.5918 0.329102 11.4665 0.379232 11.3662 0.479492C11.2705 0.575195 11.2227 0.698242 11.2227 0.848633V9.27734L11.2773 10.7539L10.1973 9.52344L8.80273 8.09473C8.70247 7.98991 8.57943 7.9375 8.43359 7.9375C8.27865 7.9375 8.15332 7.98535 8.05762 8.08105C7.96647 8.17676 7.9209 8.2998 7.9209 8.4502C7.9209 8.60059 7.97786 8.73275 8.0918 8.84668L11.3525 12.1758C11.4619 12.2897 11.5918 12.3467 11.7422 12.3467C11.8926 12.3512 12.0225 12.2943 12.1318 12.1758Z"
                            fill="#DDDDDD"/>
                    </svg>
                    <span className={'intersections__sort-btn--text'}>{t('sorting')}</span>
                </button>

            </div>
            <div className={'intersections__bottom'}>
                <h3 className={'intersections__sort--title'}>
                    {t('mostIntersections')}
                </h3>
                <button onClick={() => setVisible(!visible)}
                        className={`intersections__hide ${visible ? '' : 'intersections__show'}`}>
                    {t('hideAll')} <span><svg width="16" height="11" viewBox="0 0 16 11" fill="none"
                                              xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M1.45481 10.1816C1.05315 10.1816 0.727539 9.85603 0.727539 9.45437C0.727539 9.05271 1.05315 8.7271 1.45481 8.7271H14.5457C14.9474 8.7271 15.273 9.05271 15.273 9.45437C15.273 9.85603 14.9474 10.1816 14.5457 10.1816H1.45481ZM1.2933 7.27246C0.836287 7.27246 0.618803 6.70991 0.956968 6.40249L7.32733 0.611254C7.70875 0.264507 8.29125 0.264508 8.67267 0.611255L15.043 6.40249C15.3812 6.70991 15.1637 7.27246 14.7067 7.27246H1.2933Z"
                                  fill="#DDDDDD"/>
                               </svg>
                            </span>
                </button>
            </div>
            {visible && categories.entity.items.map(({categories, total_percentage, target, total_count}) => (
                <Intersection key={target.id} categories={categories} target={target} hideHashes={hideHashes}
                              totalPercentage={total_percentage} totalCount={total_count} showHashes={showHashes}/>
            ))}
            {categories.entity.items.length < 1 && <div style={{marginTop: '25px', color: "#808080"}}>
                There is no intersections here
            </div>}
            {visible && categories.entity.items?.length ? <Pagination current={page}
                                                           onChange={handlePageChange}
                                                           last={categories.entity.pages_count}
                                                           hasNext={categories.entity.pages_count > page}
                                                           disabled={false}
                                                           isWhiteDots={true}
                                                           isNoArrows={true}
            /> : null}
        </div>
    )
}

export default Intersections
