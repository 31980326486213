import {Action, createSlice, PayloadAction, ThunkAction} from "@reduxjs/toolkit"
import {ITicketMessage, ITicketMessageState} from "../../interfaces/message/message";
import {IMembers, IMembersState} from "../../interfaces/members/members";
import {getMembersApi} from "../../api/members/members";
import {getMembers, membersError} from "./members-slice";
import {getMessagesApi} from "../../api/messages/messages";
import {ticketsError} from "./tickets-slice";

const initialState: ITicketMessageState = {
    ticketMessages: {
        entity: [],
        errors: {
            message: '',
            isError: false
        },
        loaded: false
    }
}


export const ticketMessagesSelector = (state: { ticketMessages: ITicketMessageState }) => state.ticketMessages


const ticketMessagesSlice = createSlice({
    name: 'ticketMessages',
    initialState,
    reducers: {
        getTicketMessages: (state, action: PayloadAction<ITicketMessage[]>) => {
            state.ticketMessages.loaded = true
            state.ticketMessages.entity = action.payload
            state.ticketMessages.errors.isError = false
        },
        ticketMessagesError: (state, action: PayloadAction<string>) => {
            state.ticketMessages.loaded = true
            state.ticketMessages.errors.isError = true
            state.ticketMessages.errors.message = action.payload
        },
        addTicketMessage: (state, action: PayloadAction<ITicketMessage>) => {
            state.ticketMessages.entity = [...state.ticketMessages.entity, action.payload]
            state.ticketMessages.loaded = true
            state.ticketMessages.errors.isError = false
        },
        setLoaded: (state, action: PayloadAction<boolean>) => {
            state.ticketMessages.loaded = action.payload
        }
    }
})


export const {
    getTicketMessages,
    ticketMessagesError,
    setLoaded,
    addTicketMessage
} = ticketMessagesSlice.actions


export const getTicketsMessagesThunk = (id: string): ThunkAction<void, ITicketMessageState, unknown, Action<string>> => {
    return async dispatch => {
        try {
            dispatch(setLoaded(false))
            const messages: ITicketMessage[] = await getMessagesApi(id)
                .then((response) => {
                    if (response && response.data) {
                        return response.data
                    }
                })
            dispatch(getTicketMessages(messages))
            dispatch(setLoaded(true))
        } catch (e: any) {
            dispatch(ticketsError(e.response.data.message))
            dispatch(setLoaded(true))

        }
    }
}


export default ticketMessagesSlice.reducer