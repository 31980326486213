import React, {useEffect} from 'react'
import './network-error.css'
import Languages from "../../components/header/components/languages/languages";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import error from '../../assets/network-error.png'

interface props {

}

const NetworkError: React.FC<props> = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    useEffect(() => {
        if (window.navigator.onLine) {
            navigate(-1)
        }
    }, [window.navigator.onLine])

    return (
        <section className={'server-work'}>
            <div className={'server-work__top'}>
                <svg onClick={() => navigate(-1)} style={{cursor: "pointer"}} width="44" height="21" viewBox="0 0 44 21"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M25.4395 1.14524C26.2098 1.52284 26.5282 2.45342 26.1506 3.22373L17.9493 19.9545C17.5717 20.7248 16.6411 21.0432 15.8708 20.6656C15.1005 20.288 14.7821 19.3574 15.1597 18.5871L23.361 1.85631C23.7386 1.08599 24.6692 0.767636 25.4395 1.14524ZM1.34077 9.84251C0.826516 10.4287 0.826516 11.3052 1.34077 11.8914L8.64651 20.2185C9.21229 20.8634 10.1937 20.9275 10.8386 20.3618C11.4835 19.796 11.5476 18.8146 10.9818 18.1697L4.57485 10.8669L10.9818 3.56421C11.5476 2.91933 11.4835 1.9379 10.8386 1.37212C10.1937 0.806342 9.21228 0.870467 8.64651 1.51535L1.34077 9.84251ZM34.8229 1.85631C34.562 1.32405 34.0209 0.986678 33.4281 0.986678C32.8354 0.986678 32.2943 1.32405 32.0333 1.85631L23.8321 18.5871C23.4545 19.3574 23.7728 20.288 24.5431 20.6656C25.3135 21.0432 26.244 20.7248 26.6216 19.9545L28.3635 16.401L38.4928 16.401L40.2346 19.9545C40.6122 20.7248 41.5428 21.0432 42.3131 20.6656C43.0834 20.288 43.4018 19.3574 43.0242 18.5871L34.8229 1.85631ZM36.9699 13.2944L33.4281 6.06912L29.8864 13.2944L36.9699 13.2944Z"
                          fill="url(#paint0_linear_4149_99896)"/>
                    <defs>
                        <linearGradient id="paint0_linear_4149_99896" x1="43.1831" y1="20.8245" x2="0.955078"
                                        y2="20.8245" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#5EBC84"/>
                            <stop offset="1" stopColor="#6BBF44"/>
                        </linearGradient>
                    </defs>
                </svg>
                <Languages/>
            </div>
            <div className={'network-error__wrapper'}>
                <div className={'network-error__item'}>
                    <h1 className={'network-error__title'}>
                        There is no Internet Сonnection
                    </h1>
                    <p className={'network-error__text'}>
                        Try:
                    </p>
                    <p className={'network-error__checklist'}>
                        Checking the network cables, modern, and router
                    </p>
                    <p className={'network-error__checklist'}>
                        Reconnecting to Wi-Fi
                    </p>
                </div>
                <div className={'network-error__item'}>
                    <img src={error} alt={'Error'}/>
                </div>
            </div>
        </section>
    )
}

export default NetworkError
