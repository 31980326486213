import React from 'react'
import SortingItem from "./sortingItem";

interface props {
    value: string
}

const MemberSortOptions: React.FC<props> = ({value}) => {
    return (
        <div className={`project-sorting__options ${value.length ? 'project-sorting__options--show' : ''}`}>
            {value === 'username' && <SortingItem value={value}/>}
            {value === 'role' && <SortingItem value={value}/>}
        </div>
    )
}

export default MemberSortOptions
