import React from 'react'
import './database.css'
import Project from "./components/project";

interface props {

}

const Database: React.FC<props> = () => {
    return (
        <section className={'database'}>
            <Project/>
        </section>
    )
}

export default Database
