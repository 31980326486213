import React, {useEffect, useState} from 'react'
import '../database.css'
import ProjectItem from "./components/project-item";
import ProjectItemColumn from "./components/project-item-column/project-item-column";
import {useDispatch, useSelector} from "react-redux";
import {getProjects, projectsError, projectsSelector} from "../../../../../store/slices/projects-slice";
import Loader from "../../../../../shared/loader/loader";
import {useWindowSize} from "../../../../../hooks/useWindowParams";
import {Pagination} from "../../../../../shared/pagination";
import {getProjectsApi} from "../../../../../api/project/project";
import ProjectFilters from "./components/project-filters";
import {filterSelector, setPage} from "../../../../../store/slices/project-filter-slice";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ItemsAtPageProject from "./components/items-at-page/items-at-page-project";

interface props {

}

const Project: React.FC<props> = () => {
    const dispatch = useDispatch()
    const {teamId} = useParams()
    const {width} = useWindowSize()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const {projects} = useSelector(projectsSelector)
    const {projectOptions} = useSelector(filterSelector)
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
    const handlePageChange = (count: number) => {
        setIsFirstLoad(false)
        dispatch(setPage(count))
    };
    const request = (isLoader?: boolean) => {
        isLoader && setConfirmLoading(true)
        getProjectsApi(`?page=${projectOptions.page}`,
            `${projectOptions.sort.sortBy ? `&sortBy=${projectOptions.sort.sortBy}` : '&sortBy=updatedAt'}`,
            `${projectOptions.sort.sortValue ? `&sortValue=${projectOptions.sort.sortValue}` : '&sortValue=desc'}`,
            `${projectOptions.filter.filterBy ? `&filterBy=${projectOptions.filter.filterBy}` : ''}`,
            `${projectOptions.filter.filterValue ? `&filterValue=${projectOptions.filter.filterValue}` : ''}`,
            teamId ? `&teamID=${teamId}` : '', projectOptions.count > 0 ? `&count=${projectOptions.count}` : '')
            .then((response) => {
                if (response) {
                    dispatch(getProjects(response))
                }
            }).catch((e) => {
            if (e.response.status === 500 || e.response.status === 0) {
                navigate('/500')
            }
            dispatch(projectsError(e.response.data.message))
        }).finally(() => isLoader && setConfirmLoading(false))
    }

    useEffect(() => {
        setIsFirstLoad(false)
        if (!isFirstLoad) {
            request(true)
        }
        return () => {
            setIsFirstLoad(true)
        }
    }, [projectOptions.page, projectOptions.count])



    useEffect(() => {
        if (projects.entity.items.some((item) => item.state !== 'completed')) {
            const interval = setInterval(() => {
                request()
                clearInterval(interval)
            }, 30000)
            return () => {
                clearInterval(interval)
            }

        }
    }, [projects.entity])


    const [isRow, setIsRow] = useState<boolean>(false)
    const rowToggler = () => setIsRow(!isRow)
    useEffect(() => {
        if (width && width <= 992) {
            setIsRow(true)
        }
    }, [width])

    if (!projects.loaded) {
        return (
            <Loader/>
        )
    }
    return (
        <>
            <ProjectFilters rowToggler={rowToggler} isRow={isRow}/>
            {confirmLoading && <Loader/>}
            <ItemsAtPageProject/>

            <div
                className={`project-database ${projectOptions.currentOption.length > 0 ? 'project-database--active' : ''}`}>
                {projects.entity.items?.length ? projects.entity.items.map((project) => (
                    isRow ? <ProjectItem project={project} key={project.id}/> :
                        <ProjectItemColumn project={project} key={project.id}/>
                )) : <div className={'not-found--projects'}>
                    {t('noProjectFound')}
                </div>}
            </div>
            {projects.entity.items.filter((item) => item.state !== 'deleting')?.length > 0 ?
                <Pagination current={projectOptions.page}
                            onChange={handlePageChange}
                            hasNext={projects.entity.pages_count > projectOptions.page}
                            disabled={false}
                            isWhiteDots={true}
                            last={projects.entity.pages_count}
                /> : null}
        </>
    )
}

export default Project
