import React, {useEffect} from 'react'
import './ticket-info.css'
import {useDispatch, useSelector} from "react-redux";
import {getTicketThunk, resetTicket, ticketSelector} from "../../../../store/slices/tickets-slice";
import {Link, useParams} from "react-router-dom";
import Loader from "../../../../shared/loader/loader";
import moment from "moment";
import {setDocumentTitle} from "../../../../utils/setDocumentTitle/setDocumentTitle";
import {useTranslation} from "react-i18next";


interface props {

}

const TicketInfo: React.FC<props> = () => {
    const {id} = useParams()
    const {ticket} = useSelector(ticketSelector)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    useEffect(() => {
        if (id) {
            dispatch(getTicketThunk(id))
        }
        return () => {
            dispatch(resetTicket())
        }
    }, [])

    if (!ticket.loaded) return (<Loader/>)
    setDocumentTitle(`Ticket: ${ticket.entity.topic}`)
    return (
        <div className={'ticket-info'}>
            <Link to={'/support'} className={'ticket-info__breadcrumb'}>
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.375 6.56445C0.375 6.65104 0.390951 6.73307 0.422852 6.81055C0.454753 6.88346 0.502604 6.95182 0.566406 7.01562L5.9873 12.3135C6.11035 12.4365 6.25846 12.498 6.43164 12.498C6.55013 12.498 6.65495 12.4707 6.74609 12.416C6.8418 12.3613 6.91699 12.2861 6.97168 12.1904C7.03092 12.0993 7.06055 11.9945 7.06055 11.876C7.06055 11.7074 6.99902 11.5592 6.87598 11.4316L1.89941 6.56445L6.87598 1.69727C6.99902 1.56966 7.06055 1.42155 7.06055 1.25293C7.06055 1.13444 7.03092 1.02962 6.97168 0.938477C6.91699 0.842773 6.8418 0.767578 6.74609 0.712891C6.65495 0.658203 6.55013 0.630859 6.43164 0.630859C6.25846 0.630859 6.11035 0.690104 5.9873 0.808594L0.566406 6.11328C0.502604 6.17708 0.454753 6.24772 0.422852 6.3252C0.390951 6.39811 0.375 6.47786 0.375 6.56445Z"
                        fill="#808080"/>
                </svg>
                <span>{t('backToAllTickets')}</span>
            </Link>
            <p>
                {ticket.entity.topic ? t(`ticketThemes.${ticket.entity.topic}`) : null}
            </p>
            <p>
                {ticket.entity.dateTime && ticket.entity.dateTime.length > 0 ? moment(ticket.entity.dateTime).format('DD.MM.YYYY') : null}
            </p>
            <p className={'ticket-info__status'}>
                {ticket.entity.status ? t(`ticketStatuses.${ticket.entity.status}`) : null}
            </p>
        </div>
    )
}

export default TicketInfo
