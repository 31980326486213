import React from 'react'
import {useWindowSize} from "../../../hooks/useWindowParams";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {sidebarSelector} from "../../../store/slices/sidebar-slice";
import {useTranslation} from "react-i18next";

interface props {
    hideSidebarHandler: () => void
}

const Help: React.FC<props> = ({hideSidebarHandler}) => {
    const {width} = useWindowSize()
    const {sidebar} = useSelector(sidebarSelector)
    const hide = () => {
        if (width && width <= 768) {
            hideSidebarHandler()
        }
    }
    const {t} = useTranslation()
    return (
        <Link onClick={hide} to={'/help'} className={'aside__item--wrapper'}>
            <div className={'aside__item--icon'}>
                <svg width="19" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.98089 4.21623C9.99344 4.2795 10 4.34475 10 4.41129L10 14.5824L15 13.588L15 3.21811L9.98089 4.21623ZM9 15.7816C9.07781 15.7817 9.15399 15.7727 9.22737 15.7556L15.195 14.5688C15.663 14.4758 16 14.0651 16 13.588L16 3.21811C16 2.58751 15.4234 2.11432 14.805 2.23732L9 3.39171L3.19504 2.23732C2.57655 2.11432 2 2.58751 2 3.21811V13.588C2 14.0651 2.33703 14.4758 2.80495 14.5688L8.77263 15.7556C8.84601 15.7727 8.92219 15.7817 9 15.7816Z" fill="#5ABE60"/>
                </svg>

            </div>
            <div className={`aside__item--info ${sidebar.isVisible ? "" : "aside__item--info-hidden"}`}>
                <h3 className={'aside__item--title'}>
                    {t('help')}
                </h3>
            </div>
        </Link>
    )
}

export default Help
