import React from 'react'
import SignUpForm from "../login/components/sign-up-form/sign-up-form";
import gif from "../../assets/gif/alpha18fps_online.gif";
import {setDocumentTitle} from "../../utils/setDocumentTitle/setDocumentTitle";
import {useTranslation} from "react-i18next";

interface props {

}

const Registration: React.FC<props> = () => {
    const {t} = useTranslation()
    setDocumentTitle(t('registration'))

    return (
        <section className={'login'}>
            <div className={'login-top'}>
                <h2 className={'login-top__title'}>
                    {t('title')}
                </h2>
                <p className={'login-top__text'}>
                    {t('description')}
                </p>
            </div>
            <div className={'login-wrap'}>
                <div className={'login__gif'}>
                    <img src={gif} alt={'gif'} loading="lazy"/>
                </div>
                <div className={'login-wrapper register-wrapper'}>
                    <SignUpForm/>
                </div>
            </div>
        </section>
    )
}

export default Registration
