import makeRequest from "../makeRequest";

export function deleteNotification (id: number) {
    return makeRequest({
        method: "DELETE",
        url: `api/user_notifications/${id}`
    })
}

export function makeAsReadNotification (id: number) {
    return makeRequest({
        method: "POST",
        url: `api/user_notifications/viewed/${id}`
    })
}