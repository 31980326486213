import React, {useEffect, useRef, useState} from 'react'
import './user-dropdown.css'
import Logout from "../logout";
import {useSelector} from "react-redux";
import {userSelector} from "../../../../store/slices/user-slice";

interface props {

}

const UserDropdown: React.FC<props> = () => {
    const [visible, setVisible] = useState<boolean>(false)
    const {user}= useSelector(userSelector)
    const wrapperRef = useRef(null);
    const visibleToggle = () => {
        setVisible(!visible)
    }
    const hideOptions = (e: any) => {
        if (wrapperRef.current) {
            // @ts-ignore
            if (!wrapperRef.current.contains(e.target)) {
                setVisible(false)
            }
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', hideOptions)
        return()=> document.removeEventListener('mouseup', hideOptions)
    }, [])
    return (
        <>
            <svg onClick={visibleToggle} width="18" height="20" viewBox="0 0 18 20" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.99998 0.400393C8.18824 0.400495 7.38978 0.606455 6.67928 0.999014C5.96878 1.39157 5.36943 1.95791 4.93729 2.64506C4.50516 3.33221 4.25434 4.11772 4.2083 4.92816C4.16226 5.73859 4.32251 6.54746 4.67405 7.27912C5.02558 8.01079 5.55693 8.64136 6.21841 9.11186C6.87989 9.58235 7.64988 9.87741 8.45638 9.96945C9.26289 10.0615 10.0796 9.94747 10.83 9.63809C11.5805 9.32871 12.2402 8.83406 12.7476 8.20039H8.99998V7.00039H13.452C13.6044 6.62239 13.71 6.22039 13.764 5.80039H8.99998V4.60039H13.764C13.7127 4.18852 13.6078 3.78511 13.452 3.40039H8.99998V2.20039H12.7476C12.2982 1.63816 11.728 1.18439 11.0792 0.872771C10.4304 0.561153 9.71972 0.399696 8.99998 0.400393ZM3.01078 11.2004C2.69474 11.1991 2.38156 11.2603 2.0892 11.3803C1.79685 11.5003 1.53106 11.6769 1.30709 11.8999C1.08311 12.1228 0.905357 12.3878 0.784015 12.6796C0.662673 12.9715 0.600131 13.2844 0.599976 13.6004C0.599976 15.6296 1.59958 17.1596 3.16198 18.1568C4.70038 19.1372 6.77398 19.6004 8.99998 19.6004C11.0328 19.6004 12.9384 19.214 14.424 18.4004H8.99998V17.2004H16.0032C16.3428 16.8404 16.6296 16.4372 16.8528 15.9944H8.99998V14.7944H17.2752C17.3592 14.402 17.4011 14.0017 17.4 13.6004V13.5944H8.99998V12.3944H17.076C16.8648 12.031 16.5619 11.7295 16.1975 11.5199C15.8332 11.3104 15.4203 11.2002 15 11.2004H3.01078Z"
                    fill="#5ABE60"/>
            </svg>
            {visible && <div ref={wrapperRef} className={'user-dropdown'}>
                <svg className={'user-dropdown__rectangle'} width="14" height="8" viewBox="0 0 14 8" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 7.5H1.375L6.2 1.06667C6.6 0.533333 7.4 0.533333 7.8 1.06667L12.625 7.5H13"
                          stroke="#A9A9A8" strokeLinecap="round"/>
                </svg>
                <div className={'header__profile--info'}>
                    <p className={'header__profile--email'}>
                        {user.entity.username}
                    </p>
                </div>
                <Logout/>
            </div>}
        </>
    )
}

export default UserDropdown
