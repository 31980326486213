import React from 'react';
import './App.css';
import Layout from "./layouts/layout";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "keen-slider/keen-slider.min.css"
import {mainRoutes, otherRoutes} from "./routes";


function App() {


    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={<Layout/>}>
                        {mainRoutes.map(({path, component}) => (
                            <Route key={path} path={path} element={component}/>
                        ))}
                    </Route>
                    {otherRoutes.map(({path, component}) => (
                        <Route key={path} path={path} element={component}/>
                    ))}
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
