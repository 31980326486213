import React, {useEffect, useState} from 'react'
import './ticket-item.css'
import {ITicket} from "../../../../interfaces/tickets/tickets";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import makeRequest from "../../../../api/makeRequest";
import {useTranslation} from "react-i18next";

interface props {
    ticket: ITicket
}

const TicketItem: React.FC<props> = ({ticket}) => {
    const navigate = useNavigate()
    const [unread, setUnread] = useState<number>(0)
    const {
        id, dateTime, status,  topic
    } = ticket
    const {t} = useTranslation()

    useEffect(() => {
        makeRequest.get(`api/tickets/${id}/messages/unread`)
            .then((response) => {
                if (response && response.data && response.data.count) {
                    setUnread(response.data.count)
                }
            }).catch((e) => {
            console.log(e.response)
        })
    }, [])
    return (
        <div onClick={() => {
            navigate(`/support/${id}`)
        }} className={'ticket-item'}>
            {unread > 0 && <div className={'ticket-item__notification'}>
                {unread}
            </div>}
            <div className={'ticket-item__info'}>
                <div className={'ticket-item__id'}>
                    {t('ticket')} №{id}
                </div>
                <div className={'ticket-item__status'}>
                    {t(`ticketStatuses.${status}`)}
                </div>
            </div>
            <div className={'ticket-item__issue'}>
                <div className={'ticket-item__theme'}>
                    {t(`ticketThemes.${topic}`)}
                </div>
                <div className={'ticket-item__date'}>
                    {moment(dateTime).format('DD.MM.YYYY')}
                </div>
            </div>
        </div>
    )
}

export default TicketItem
