import React, {useEffect} from 'react'
import './team-settings.css'
import Header from "../../components/header/header";
import UploadForm from "../upload/components/upload-form/upload-form";
import SettingsWrapper from "./components/settings-wrapper";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getTeamThunk, teamSelector} from "../../store/slices/team-slice";
import {setDocumentTitle} from "../../utils/setDocumentTitle/setDocumentTitle";
import {useTranslation} from "react-i18next";
import {getProjectsApi} from "../../api/project/project";
import {setAllProjects} from "../../store/slices/projects-slice";

interface props {

}

const TeamSettings: React.FC<props> = () => {

    const {t} = useTranslation()
    const {teamId} = useParams()
    const {team} = useSelector(teamSelector)
    const dispatch = useDispatch()
    useEffect(() => {
        if (teamId) {
            dispatch(getTeamThunk(teamId))
            getProjectsApi('?page=1', '', '', '', '', teamId ? `&teamID=${teamId}` : '', '&count=500')
                .then((response) => {
                    dispatch(setAllProjects(response.items))
                }).catch(e => {

            })
            return () => {
                dispatch(setAllProjects([]))
            }
        }
    }, [teamId])
    setDocumentTitle(`${team.entity?.name ? `${team.entity?.name}'s` : ''} Settings`)
    return (
        <>
            <UploadForm isFromTeam={true}/>
            <SettingsWrapper/>
        </>
    )
}

export default TeamSettings
