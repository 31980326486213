import React, {useEffect, useState} from 'react'
import './custom-file-select.css'
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {setArchiveUrl, setFileName} from "../../../../../../store/slices/upload-project-slice";
import {useTranslation} from "react-i18next";
import useDebounce from "../../../../../../hooks/useDebounce";
import axios from "axios";
import Loader from "../../../../../../shared/loader/loader";
import {useNavigate} from "react-router-dom";

interface props {
    toggleOptions: () => void
}

interface IBranch {
    name: string
    commit: {
        sha: string
        url: string
    }
    protected: boolean
}

const GitLinkModal: React.FC<props> = ({toggleOptions}) => {
    const linkPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [visible, setVisible] = useState<boolean>(false)
    const [link, setLink] = useState<string>('')
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const [branches, setBranches] = useState<IBranch[]>([])
    const debouncedLink = useDebounce(link, 1000)
    const {t} = useTranslation()
    const [error, setError] = useState<string>('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const showHandler = () => {
        document.body.style.overflow = 'hidden'
        setVisible(true)
    }
    const hideHandler = () => {
        setVisible(false)
        document.body.style.overflow = 'visible'
    }
    useEffect(() => {
        if (debouncedLink) {
            if (linkPattern.test(debouncedLink)) {
                setError('')
                const mainer = debouncedLink.split('/')
                setConfirmLoading(true)
                axios.get(`https://api.github.com/repos/${mainer[mainer.length - 2]}/${mainer[mainer.length - 1]}/branches`)
                    .then((response) => {
                        if (response && response.data) {
                            setBranches(response.data)
                        }
                    }).catch((e) => {
                    if (e.code === 'ERR_NETWORK') {
                        navigate('/maintenance')
                    }
                    if (e.response && e.response.data) {
                        setError(e.response.data.message)
                        setBranches([])
                    }
                }).finally(() => setConfirmLoading(false))
            } else {
                setError(t('linkError'))
            }
        }
    }, [debouncedLink])
    const onSubmit = (e: any) => {

        const mainer = debouncedLink.split('/')
        const url = `https://api.github.com/repos/${mainer[mainer.length - 2]}/${mainer[mainer.length - 1]}/zipball/${e.branchName}`
        dispatch(setArchiveUrl(url))
        toggleOptions()
        hideHandler()
    }
    const onChange = (e: any) => setLink(e.target.value)

    return (
        <li className={'custom-select__import'}>
            <div className={'create-team__github'} onClick={showHandler}>
                <p>Github</p>
                <p>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M9.96942 0C4.46483 0 0 4.58933 0 10.2474C0 14.7739 2.87462 18.6088 6.78899 19.9919C7.27829 20.0547 7.46177 19.7404 7.46177 19.4889C7.46177 19.2375 7.46177 18.6088 7.46177 17.7286C4.70948 18.3573 4.09786 16.3456 4.09786 16.3456C3.66972 15.1511 2.99694 14.8367 2.99694 14.8367C2.07951 14.2081 3.0581 14.2081 3.0581 14.2081C4.0367 14.2709 4.58716 15.2768 4.58716 15.2768C5.50459 16.8485 6.91131 16.4084 7.46177 16.1569C7.52294 15.4654 7.82875 15.0253 8.07339 14.7739C5.87156 14.5224 3.5474 13.6422 3.5474 9.6816C3.5474 8.54998 3.91437 7.66984 4.58716 6.91543C4.52599 6.72682 4.15902 5.65808 4.70948 4.27499C4.70948 4.27499 5.56575 4.02352 7.46177 5.34374C8.25688 5.09227 9.11315 5.0294 9.96942 5.0294C10.8257 5.0294 11.682 5.15514 12.4771 5.34374C14.3731 4.02352 15.2294 4.27499 15.2294 4.27499C15.7798 5.65808 15.4128 6.72682 15.3517 6.97829C15.9633 7.66984 16.3914 8.61285 16.3914 9.74446C16.3914 13.7051 14.0673 14.5224 11.8654 14.7739C12.2324 15.0882 12.5382 15.7169 12.5382 16.6599C12.5382 18.043 12.5382 19.1117 12.5382 19.4889C12.5382 19.7404 12.7217 20.0547 13.211 19.9919C17.1865 18.6088 20 14.7739 20 10.2474C19.9388 4.58933 15.474 0 9.96942 0Z"
                              fill="black"/>
                    </svg>

                </p>
            </div>
            {visible && <div className={'logout-modal-wrapper'}>
                {confirmLoading && <Loader/>}
                <div onClick={hideHandler} className={'create-team__backdrop'}>

                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={'logout-modal change-password'}>
                    <svg onClick={hideHandler} className={'create-team__icon'} width="11" height="11"
                         viewBox="0 0 11 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.804688 10.2803C0.86849 10.3486 0.945964 10.3919 1.03711 10.4102C1.12826 10.4329 1.21712 10.4329 1.30371 10.4102C1.3903 10.3919 1.47005 10.3486 1.54297 10.2803L5.7334 6.08301L9.93066 10.2803C10.0309 10.3805 10.154 10.4307 10.2998 10.4307C10.4456 10.4352 10.5687 10.3851 10.6689 10.2803C10.7692 10.1755 10.8193 10.0501 10.8193 9.9043C10.8193 9.75846 10.7692 9.6377 10.6689 9.54199L6.47168 5.34473L10.6689 1.14746C10.7692 1.05176 10.8193 0.93099 10.8193 0.785156C10.8239 0.634766 10.7738 0.50944 10.6689 0.40918C10.5687 0.308919 10.4456 0.258789 10.2998 0.258789C10.154 0.258789 10.0309 0.308919 9.93066 0.40918L5.7334 4.60645L1.54297 0.40918C1.47005 0.345378 1.3903 0.302083 1.30371 0.279297C1.21712 0.25651 1.12826 0.25651 1.03711 0.279297C0.945964 0.297526 0.86849 0.34082 0.804688 0.40918C0.736328 0.477539 0.690755 0.557292 0.667969 0.648438C0.645182 0.735026 0.645182 0.823893 0.667969 0.915039C0.690755 1.00618 0.736328 1.08366 0.804688 1.14746L4.99512 5.34473L0.804688 9.54199C0.736328 9.60579 0.688477 9.68327 0.661133 9.77441C0.638346 9.86556 0.638346 9.95671 0.661133 10.0479C0.683919 10.139 0.731771 10.2165 0.804688 10.2803Z"
                            fill="#C6C7C7"/>
                    </svg>
                    <div className={'create-team__icon-wrapper'}>
                        <h2 className={'create-team__title'}>
                            Github
                        </h2>

                    </div>
                    <label className={'password__label'}>
                        {t('link')}
                    </label>
                    <div className={'password__input'}>
                        <input type={'text'} value={link} onChange={onChange}
                               placeholder={t('gitHubLinkPlaceholder')}/>
                    </div>
                    {error.length > 0 && <p className={'upload-form__error'}>
                        {error}
                    </p>}
                    {branches.length > 0 && <label className={'password__label'}>
                        {t('branch')}
                    </label>}
                    <div className={'password__input'}>
                        {branches.length > 0 && <select {...register('branchName', {
                            required: {
                                value: true,
                                message: t('required')
                            }
                        })}>
                            {branches.map(({name}) => (
                                <option key={name} value={name}>
                                    {name}
                                </option>
                            ))}
                        </select>}
                    </div>
                    {errors.branchName && errors.branchName.type.length > 0 && <p className={'upload-form__error'}>
                        {errors.branchName.message}
                    </p>}
                    <div className={'logout-modal__btn-wrapper'}>
                        <button disabled={!branches.length}
                                className={`logout-modal__btn ${!branches.length ? 'logout-modal__btn--disabled' : ''}`}>
                            {t('upload')}
                        </button>
                    </div>
                </form>
            </div>}
        </li>
    )
}

export default GitLinkModal
