import React, {useState} from 'react'
import './intersections.css'
import {useDispatch, useSelector} from "react-redux";
import {findColor} from "../../../../../../utils/colorDetecter";
import {getHashesThunk, hashSelector, setCurrentId, setHashType} from "../../../../../../store/slices/hash-slice";
import {types} from "../../../../../../constants/types";
import {IProject} from "../../../../../../interfaces/projects/projects";
import {ICategories} from "../../../../../../interfaces/intersections/intersections";
import {useTranslation} from "react-i18next";
import {SpinnerDotted} from "spinners-react";
import {Link, useNavigate, useParams} from "react-router-dom";

interface props {
    showHashes: () => void
    hideHashes: () => void
    target: IProject
    categories: ICategories[]
    totalPercentage: number
    totalCount: number
}

const Intersection: React.FC<props> = ({showHashes, hideHashes, target, categories, totalCount, totalPercentage}) => {
    const [visible, setVisible] = useState<boolean>(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {teamId} = useParams()
    const {t} = useTranslation()
    const getCurrentHash = async (id: number, hashType: number) => {
        await hideHashes()
        await dispatch(setCurrentId(id))
        await dispatch(setHashType(hashType))
        showHashes()
        dispatch(getHashesThunk(id.toString(), hashes.count, false))
    }
    const {hashes} = useSelector(hashSelector)

    const redirectToProject = () => {
        if (teamId) {
            navigate(`/teams/${teamId}/projects/${target.id}`)
            return
        }
        navigate(`/projects/${target.id}`)
    }


    return (
        <div className={'intersection'}>
            <div className={'intersection__top'}>
                <div className={'intersection__title'}>
                    <h2>
                        <Link to={teamId ? `/teams/${teamId}/projects/${target.id}` : `/projects/${target.id}`}>
                            <svg style={{cursor: 'pointer'}}  width="15" height="16"
                                 viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.62354 15.21H12.1279C12.9912 15.21 13.6442 14.9886 14.0869 14.5459C14.5296 14.1087 14.751 13.464 14.751 12.6118V3.07422C14.751 2.22201 14.5296 1.57454 14.0869 1.13184C13.6442 0.689128 12.9912 0.467773 12.1279 0.467773H2.62354C1.76579 0.467773 1.11279 0.689128 0.664551 1.13184C0.221842 1.56901 0.000488281 2.21647 0.000488281 3.07422V12.6118C0.000488281 13.4696 0.221842 14.117 0.664551 14.5542C1.11279 14.9914 1.76579 15.21 2.62354 15.21ZM2.74805 13.5581C2.39941 13.5581 2.13102 13.4668 1.94287 13.2842C1.76025 13.1016 1.66895 12.8249 1.66895 12.4541V3.23193C1.66895 2.86117 1.76025 2.58447 1.94287 2.40186C2.13102 2.21924 2.39941 2.12793 2.74805 2.12793H12.0034C12.3521 2.12793 12.6204 2.21924 12.8086 2.40186C12.9967 2.58447 13.0908 2.86117 13.0908 3.23193V12.4541C13.0908 12.8249 12.9967 13.1016 12.8086 13.2842C12.6204 13.4668 12.3521 13.5581 12.0034 13.5581H2.74805ZM9.82861 9.91406C10.0334 9.91406 10.1966 9.84489 10.3184 9.70654C10.4401 9.5682 10.501 9.38835 10.501 9.16699V5.53955C10.501 5.25179 10.4235 5.0415 10.2686 4.90869C10.1191 4.77035 9.91439 4.70117 9.6543 4.70117H6.00195C5.7806 4.70117 5.60075 4.76204 5.4624 4.88379C5.32959 5.00553 5.26318 5.16878 5.26318 5.37354C5.26318 5.57829 5.32959 5.74154 5.4624 5.86328C5.60075 5.98503 5.7806 6.0459 6.00195 6.0459H7.28857L8.32617 5.92139L7.18896 6.95898L4.49121 9.65674C4.33626 9.80615 4.25879 9.98047 4.25879 10.1797C4.25879 10.401 4.32796 10.5809 4.46631 10.7192C4.60465 10.8521 4.78174 10.9185 4.99756 10.9185C5.21338 10.9185 5.39876 10.841 5.55371 10.686L8.24316 8.00488L9.26416 6.87598L9.15625 7.96338V9.17529C9.15625 9.40218 9.21712 9.58203 9.33887 9.71484C9.46061 9.84766 9.62386 9.91406 9.82861 9.91406Z"
                                    fill="#55AAF9"/>
                            </svg>
                        </Link>
                        {target.name.slice(0, 20)}
                    </h2>
                    <span>{target.type}</span>
                </div>
                <button onClick={() => setVisible(!visible)}
                        className={`intersections__hide ${visible ? '' : 'intersections__show'}`}
                        style={{marginRight: '0'}}>
                    <svg width="16" height="11" viewBox="0 0 16 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M1.45481 10.1816C1.05315 10.1816 0.727539 9.85603 0.727539 9.45437C0.727539 9.05271 1.05315 8.7271 1.45481 8.7271H14.5457C14.9474 8.7271 15.273 9.05271 15.273 9.45437C15.273 9.85603 14.9474 10.1816 14.5457 10.1816H1.45481ZM1.2933 7.27246C0.836287 7.27246 0.618803 6.70991 0.956968 6.40249L7.32733 0.611254C7.70875 0.264507 8.29125 0.264508 8.67267 0.611255L15.043 6.40249C15.3812 6.70991 15.1637 7.27246 14.7067 7.27246H1.2933Z"
                              fill="#707070"/>
                    </svg>
                </button>
            </div>
            {target.state === 'indexing' ?
                <div className={'intersection__item--total'}><SpinnerDotted size={25} enabled={true}/></div> :
                <div className={'intersection__item--total'}>
                    <p className={'intersection__item--title'}>{t('totalIntersections')}</p>
                    <div className={'intersection__item--info'}>
                        <span>{totalPercentage}%</span>
                        <div className={'intersection__item--percentage'}>
                            <div className={'intersection__item--percentage-info'} style={{
                                width: `${totalPercentage === 100 ? totalPercentage - 4 : totalPercentage}%`,
                                backgroundColor: findColor(totalPercentage)
                            }}/>
                        </div>
                    </div>
                </div>}
            {visible &&
            <ul className={'intersection__list'}>

                {categories.length ? categories.map(({
                                                         id,
                                                         groupCount,
                                                         hashCount,
                                                         hashType,
                                                         level,
                                                         percentage
                                                     }) => {
                    const findedType = types.find((type) => type.number === hashType)?.title
                    return (
                        (
                            <li onClick={() => getCurrentHash(id, hashType)} key={id}
                                className={`intersection__item ${hashes.id === id ? 'intersection__item--active' : ''}`}>
                                <p className={'intersection__item--title child'}>{findedType}: <span
                                    style={{fontWeight: '500'}}>{hashCount}</span></p>
                                <div className={'intersection__item--info'}>
                                    <span>{percentage}%</span>
                                    <div className={'intersection__item--percentage'}>
                                        <div className={'intersection__item--percentage-info'}
                                             style={{
                                                 width: `${percentage === 100 ? percentage - 4 : percentage}%`,
                                                 backgroundColor: findColor(percentage)
                                             }}/>
                                    </div>
                                </div>
                            </li>
                        )
                    )
                }) : null}

            </ul>
            }
        </div>
    )
}

export default Intersection
