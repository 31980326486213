import React, {useEffect, useState} from 'react'
import '../../database.css'
import {IProject} from "../../../../../../interfaces/projects/projects";
import {useNavigate, useParams} from "react-router-dom";
import {findColor, findStatusColor} from "../../../../../../utils/colorDetecter";
import {useDispatch, useSelector} from "react-redux";
import {userSelector} from "../../../../../../store/slices/user-slice";
import EditProject from "./project-item-column/edit-project/edit-project";
import makeRequest from "../../../../../../api/makeRequest";
import {getProjectsThunk} from "../../../../../../store/slices/projects-slice";
import {IIndexedInfo} from "./project-item-column/project-item-column";
import {setCounters, setErrors} from "../../../../../../store/slices/tabs-slice";
import {teamSelector} from "../../../../../../store/slices/team-slice";
import {filterSelector} from "../../../../../../store/slices/project-filter-slice";
import {SpinnerDotted} from "spinners-react";
import {useTranslation} from "react-i18next";
import moment from "moment";

interface props {
    project: IProject
}

const ProjectItem: React.FC<props> = ({project}) => {
    const {teamId} = useParams()
    const {t} = useTranslation()
    const {projectOptions} = useSelector(filterSelector)
    const {
        id,
        state,
        type,
        name,
        crossPercentage
    } = project
    const {user} = useSelector(userSelector)
    const {team} = useSelector(teamSelector)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [data, setData] = useState<IIndexedInfo>({
        remainedFiles: 0,
        indexedFiles: 0,
        filesCount: 0,
        id: 0
    })
    const projectNavigation = () => {
        dispatch(setCounters())
        navigate(teamId ? `/teams/${teamId}/projects/${id}` : `/projects/${id}`)
    }

    const errorsNavigation = () => {
        dispatch(setErrors())
        navigate(teamId ? `/teams/${teamId}/projects/${id}` : `/projects/${id}`)
    }
    useEffect(() => {
        if (state === 'indexing' || state === 'index saving') {
            const interval = setInterval(async () => {
                await makeRequest.get(`api/projects/indexing_status/?ids=${id}`)
                    .then((response) => {
                        if (response && response.data) {
                            if (response.data[0].remainedFiles === 0) {
                                clearInterval(interval)
                                dispatch(getProjectsThunk(projectOptions.page, teamId ? teamId : '',
                                    '', projectOptions.sort.sortBy ? `&sortBy=${projectOptions.sort.sortBy}` : '&sortBy=updatedAt', projectOptions.sort.sortValue ? `&sortValue=${projectOptions.sort.sortValue}` : '&sortValue=desc', projectOptions.filter.filterBy ? `&filterBy=${projectOptions.filter.filterBy}` : '', projectOptions.filter.filterValue ? `&filterValue=${projectOptions.filter.filterValue}` : '' , projectOptions.count.toString()))
                            }
                            setData(response.data[0])
                        }
                    })
            }, 2000)
            return () => clearInterval(interval)
        }
    }, [state])
    const percentage = data?.indexedFiles && data.filesCount ? Math.floor(data.indexedFiles / data.filesCount * 100) : ''
    const permission = user.entity.role?.find((item) => item.teamID.toString() === teamId)?.permissions

    return (
        <div className={'project-database__item'}>
            <div className={'project-database__item--top'}>
                <div onClick={projectNavigation} className={'project-database__item--tech'}>
                    <h2>{name.length > 25 ? `${name?.slice(0, 25)}...` : name}</h2>
                    <p>

                    </p>
                </div>
                <div className={'project-info__wrapper'}><p className={'project-database__item--status'}
                                                            style={{color: findStatusColor(state)}}>
                    {state === 'indexing' && data.remainedFiles || state === 'index saving' && data.remainedFiles ? `${state} - ${data?.indexedFiles}/${data?.filesCount}(${percentage}%)` : t(`projectStatuses.${state}`)}
                </p>
                    {state === 'indexing' && data.remainedFiles || state === 'index saving' && data.remainedFiles ?
                        <div className={'project-database__progress'}>
                            <div className={'project-database__width'} style={{width: `${percentage}%`}}/>
                        </div> : null}
                </div>
            </div>
            <div className={'project-database__item--info'}>
                <div className={'project-database__item--name'} style={{flex: '0 0 45%'}}>
                    <h3>
                        {t('language')}
                    </h3>
                    <h4>– {type === 'csharp' ? 'C#' : type}</h4>
                </div>
                <div className={'project-database__item--statistic'} style={{flex: '0 0 45%'}}>
                    <h3>
                        {t('totalIntersections')}
                    </h3>
                    <div className={'intersection__item--info project-database__item--percentage'}>
                        {crossPercentage || state === 'completed' ? <>
                            <span className={'project-database__item--percent'}>{crossPercentage} %</span>
                            <div className={'intersection__item--percentage'}
                                 style={{background: '#000000', width: '100px'}}>
                                <div className={'intersection__item--percentage-info'}
                                     style={{
                                         width: `${crossPercentage}%`,
                                         backgroundColor: teamId ? findColor(crossPercentage, team.entity.mediumPointIntersection, team.entity.highPointIntersection) : findColor(crossPercentage)
                                     }}/>
                            </div>
                        </> : <h4>
                            <SpinnerDotted size={25} enabled={true}/>
                        </h4>}
                    </div>
                </div>
                <div onClick={errorsNavigation} className={'column-project__errors'}
                     style={{marginTop: '30px', flex: '0 0 45%'}}>
                    <p className={'column-project__uploaded'}>
                        {t('errors')}
                    </p>
                    {state === 'indexing' || state === 'index saving' ? <SpinnerDotted size={25} enabled={true}/> :
                        <span
                            className={`mods-current__item--value`}>
                                    {project.errorsCount} {project.errorsCount > 0 ?
                            <svg style={{marginLeft: '5px'}} width="14" height="13" viewBox="0 0 14 13"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.88477 12.9219H12.0361C12.3551 12.9219 12.6309 12.8512 12.8633 12.71C13.1003 12.5687 13.2826 12.3796 13.4102 12.1426C13.5378 11.9056 13.6016 11.6436 13.6016 11.3564C13.6016 11.2243 13.5833 11.0944 13.5469 10.9668C13.515 10.8392 13.4626 10.7139 13.3896 10.5908L8.30371 1.51953C8.16243 1.25521 7.96875 1.05697 7.72266 0.924805C7.48112 0.792643 7.22591 0.726562 6.95703 0.726562C6.69727 0.726562 6.44434 0.792643 6.19824 0.924805C5.95215 1.05697 5.75618 1.25521 5.61035 1.51953L0.524414 10.5908C0.387695 10.846 0.319336 11.1012 0.319336 11.3564C0.319336 11.6436 0.383138 11.9056 0.510742 12.1426C0.638346 12.3796 0.818359 12.5687 1.05078 12.71C1.28776 12.8512 1.56576 12.9219 1.88477 12.9219ZM6.9707 8.61523C6.65169 8.61523 6.48991 8.45117 6.48535 8.12305L6.40332 4.60254C6.39876 4.44759 6.44889 4.31999 6.55371 4.21973C6.65853 4.11491 6.79525 4.0625 6.96387 4.0625C7.12793 4.0625 7.26237 4.11491 7.36719 4.21973C7.47201 4.32454 7.52441 4.45443 7.52441 4.60938L7.43555 8.12305C7.43555 8.45117 7.2806 8.61523 6.9707 8.61523ZM6.9707 10.7344C6.78385 10.7344 6.62207 10.6706 6.48535 10.543C6.35319 10.4154 6.28711 10.2627 6.28711 10.085C6.28711 9.90267 6.35319 9.74772 6.48535 9.62012C6.62207 9.48796 6.78385 9.42188 6.9707 9.42188C7.15299 9.42188 7.31022 9.48568 7.44238 9.61328C7.5791 9.74089 7.64746 9.89811 7.64746 10.085C7.64746 10.2673 7.5791 10.4222 7.44238 10.5498C7.30566 10.6729 7.14844 10.7344 6.9707 10.7344Z"
                                    fill="#FF333C"/>
                            </svg>
                            : <svg style={{marginLeft: '5px'}} width="14" height="14" viewBox="0 0 14 14"
                                   fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M12.0757 13.0977H1.92432C1.60531 13.0977 1.32731 13.027 1.09033 12.8857C0.85791 12.7445 0.677897 12.5553 0.550293 12.3184C0.422689 12.0814 0.358887 11.8193 0.358887 11.5322C0.358887 11.277 0.427246 11.0218 0.563965 10.7666L5.6499 1.69531C5.79574 1.43099 5.9917 1.23275 6.23779 1.10059C6.48389 0.968424 6.73682 0.902344 6.99658 0.902344C7.26546 0.902344 7.52067 0.968424 7.76221 1.10059C8.0083 1.23275 8.20199 1.43099 8.34326 1.69531L13.4292 10.7666C13.5021 10.8896 13.5545 11.015 13.5864 11.1426C13.6229 11.2702 13.6411 11.4001 13.6411 11.5322C13.6411 11.8193 13.5773 12.0814 13.4497 12.3184C13.3221 12.5553 13.1398 12.7445 12.9028 12.8857C12.6704 13.027 12.3947 13.0977 12.0757 13.0977ZM6.3208 10.7384C6.20744 10.9128 6.04434 11 5.8315 11C5.63948 11 5.47753 10.9188 5.34567 10.7563L3.6522 8.62007C3.59668 8.55317 3.55735 8.48984 3.53421 8.43011C3.51108 8.36798 3.49951 8.30227 3.49951 8.23298C3.49951 8.08483 3.54809 7.96177 3.64526 7.8638C3.74243 7.76583 3.86504 7.71685 4.0131 7.71685C4.17967 7.71685 4.31848 7.78734 4.42953 7.92832L5.81761 9.72043L8.53826 5.27599C8.60073 5.17563 8.66666 5.10514 8.73606 5.06452C8.80547 5.02151 8.89107 5 8.99286 5C9.14092 5 9.26238 5.04898 9.35723 5.14695C9.45209 5.24253 9.49951 5.3644 9.49951 5.51254C9.49951 5.5675 9.49026 5.62605 9.47175 5.68817C9.45324 5.74791 9.42432 5.81004 9.38499 5.87455L6.3208 10.7384Z"
                                      fill="#79D171"/>
                            </svg>
                        }
                                </span>}
                </div>
            </div>
            <div className={'project-database__item--buttons'}>
                <div className={'column-project__teams'}>
                    {project.team ? <>
                        <p className={'column-project__team-count'}>
                            {t('openedFor')} <span>1</span>
                        </p>
                        <div className={'column-project__team'}>
                            {project.team?.name}
                        </div>
                    </> : null}
                </div>
                <div>
                    <p className={'project__date'} style={{marginBottom: '5px'}}>
                        {project.createdAt ? moment(project.createdAt).format('DD.MM.YYYY') : null}
                    </p>
                    {teamId ? permission && permission.canDeleteProjects && <EditProject projectItem={project}/> :
                        <EditProject projectItem={project}/>}
                </div>
            </div>
        </div>
    )
}

export default ProjectItem
