import React, {useEffect, useState} from 'react'
import CountersHashCheckbox from "./counters-hash-checkbox";
import CountersHashTop from "./counters-hash-top";
import {useDispatch, useSelector} from "react-redux";
import {counterSelector, filesError, getFiles, setPage} from "../../../../../../../store/slices/counters-slice";
import Loader from "../../../../../../../shared/loader/loader";
import {Pagination} from "../../../../../../../shared/pagination";
import {getCounterFiles} from "../../../../../../../api/counters/counters";
import Sorting from "./sorting/sorting";

interface props {
    hideHashes: () => void
}

const CountersHashForm: React.FC<props> = ({hideHashes}) => {
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const [firstLoad, setFirstLoad] = useState<boolean>(true)
    const [isIgnoredShow, setIsIgnoredShow] = useState<string>('all')
    const [isCrossed, setIsCrossed] = useState<boolean>(true)

    const isCrossedHandle = (e:boolean) => {
        setIsCrossed(e)
    }
    const ignoredHandler = (value: string) => {
        setIsIgnoredShow(value)
        disableFirst()
    }
    const handlePageChange = (count: number) => {
        dispatch(setPage(count))
        setFirstLoad(false)
    };
    const disableFirst = () => {
        setFirstLoad(false)
    }
    const {files} = useSelector(counterSelector)

    const dispatch = useDispatch()
    const request = () => {
        if (files.id) {
            setConfirmLoading(true)
            getCounterFiles(files.id.toString(), `?page=${files.page}`, files.count ? `&count=${files.count}` : '', `&ignored=${isIgnoredShow}`, isCrossed ? '1' : '')
                .then(response => {
                    if (response && response.data) {
                        dispatch(getFiles(response.data))
                    }
                }).catch((e) => {
                if (e.response && e.response.data) {
                    dispatch(filesError(e.response.data.message))
                }
            }).finally(() => setConfirmLoading(false))
        }
    }
    useEffect(() => {
        setIsIgnoredShow('all')
        setIsCrossed(true)
    }, [files.id])
    useEffect(() => {
        dispatch(setPage(1))
    }, [isIgnoredShow, files.entity.pages_count])
    useEffect(() => {
        if (!firstLoad) {
            request()
        }
    }, [files.page, files.count])


    if (!files.loaded) {
        return (
            <Loader/>
        )
    }
    return (
        <div className={'hash'}>
            {confirmLoading && <Loader/>}
            {files.confirmLoading && <Loader/>}
            <CountersHashTop handlePageChange={handlePageChange} disableFirst={disableFirst}
                             hide={hideHashes}/>
            <Sorting isCrossed={isCrossed} onCrossedChange={isCrossedHandle} firstLoad={firstLoad} page={files.page}  ignore={isIgnoredShow} ignoredHandler={ignoredHandler}/>
            {files.entity.items.length ? files.entity.items.map((file, index) => (
                <CountersHashCheckbox ignore={isIgnoredShow} file={file} key={file.id * index + 1}/>
            )) : null}
            {files.entity.items.length > 0 && <Pagination current={files.page}
                                                          onChange={handlePageChange}
                                                          hasNext={files.entity.pages_count > files.page}
                                                          last={files.entity.pages_count}
                                                          disabled={confirmLoading}/>}
        </div>
    )
}

export default CountersHashForm
