import React from 'react'
import Name from "./name";
import Status from "./status";

interface props {
    value: string
}

const TicketSortingOptions: React.FC<props> = ({value}) => {
    return (
        <div className={`project-sorting__options ${value.length ? 'project-sorting__options--show' : ''}`}>
            {value === 'topic' && <Name value={value}/>}
            {value === 'id' && <Name value={value}/>}
            {value === 'status' && <Status value={value}/>}
        </div>
    )
}

export default TicketSortingOptions
