import React, {useEffect} from 'react'
import {getToken} from "../../utils/authLocalStorage";
import {Link, useNavigate} from "react-router-dom";
import './404.css'
import {setDocumentTitle} from "../../utils/setDocumentTitle/setDocumentTitle";

interface props {

}

const NotFound: React.FC<props> = () => {
    const token = getToken()
    const navigate = useNavigate()
    useEffect(() => {
        if (!token) {
            navigate('/login')
        }
    }, [])
    setDocumentTitle(`Not Found`)
    return (
        <div className={'not-found'}>
            <div>
                <div className={'not-found__title'}>
                    Not found: 404
                </div>
                <div className={'not-found__text'}>
                    This page could not found
                </div>
                <div className={'not-found__text'}>
                    AppRefactoring will automatically try to reconnect
                </div>
                <Link to={'/projects'} className={'not-found__btn'}>
                    Go to dashboard
                </Link>
            </div>
            <p className={'error__links'}>
                For help visit <a href={`https://apprefactoring.com/`} target={'_blank'}>
                apprefactoring.com
            </a> or contact <a href={'mailto:support@apprefactoring.com'}>support@apprefactoring.com</a>
            </p>
        </div>
    )
}

export default NotFound
