import React, {useState} from 'react'
import first from "../../../assets/help/05.jpg";
import second from "../../../assets/help/06.jpg";
import third from "../../../assets/help/07.jpg";
import fourth from "../../../assets/help/08.jpg";
import fifth from "../../../assets/help/09.jpg";
import {useTranslation} from "react-i18next";

interface props {

}

const Analysis: React.FC<props> = () => {
    const {t} = useTranslation()
    const startGuide = [
        {
            img: first,
            text:  t('analysisItem.first')
        },
        {
            img: second,
            text:  t('analysisItem.second')
        }
    ]
    const intersections = [
        {
            img: third,
            text: t('intersectionsInfo.first')
        },
        {
            img: fourth,
            text: t('intersectionsInfo.second')
        },
        {
            img: fifth,
            text: t('intersectionsInfo.third'),
            secondText: t('intersectionsInfo.fourth'),
            thirdText: t('intersectionsInfo.fifth')

        }
    ]
    const [visible, setVisible] = useState<boolean>(false)
    return (
        <section className={'account help'}>
            <div onClick={() => setVisible(!visible)} className={`help__btn ${visible ? 'help__btn--hide' : ''}`}>
                <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M1.45481 10.1816C1.05315 10.1816 0.727539 9.85603 0.727539 9.45437C0.727539 9.05271 1.05315 8.7271 1.45481 8.7271H14.5457C14.9474 8.7271 15.273 9.05271 15.273 9.45437C15.273 9.85603 14.9474 10.1816 14.5457 10.1816H1.45481ZM1.2933 7.27246C0.836287 7.27246 0.618803 6.70991 0.956968 6.40249L7.32733 0.611254C7.70875 0.264507 8.29125 0.264508 8.67267 0.611255L15.043 6.40249C15.3812 6.70991 15.1637 7.27246 14.7067 7.27246H1.2933Z"
                          fill="#DDDDDD"/>
                </svg>
            </div>
            <h2 onClick={() => setVisible(!visible)} className={'account__title'}>
                {t('analysisItem.title')}
            </h2>
            <div className={'account__info help__info'}>
                <div className={'account__name'}>
                    <h4 onClick={() => setVisible(!visible)} className={'account__title  help__title'}>
                        {t('analysisItem.counters')}
                    </h4>
                    {visible && <>
                        <ul className={'help__list'}>
                            {startGuide.map(({img, text}) => (
                                <li className={'help__item'} key={text}>
                                    <div className={'help__image'}>
                                        <img src={img} alt={'guide'}/>
                                    </div>
                                    <p className={'help__item--text'}>
                                        {text}
                                    </p>
                                </li>
                            ))}
                        </ul>
                        <h4 className={'account__title help__title'}>
                            {t('intersectionsInfo.title')}
                        </h4>
                        <ul className={'help__list'}>
                            {intersections.map(({img, text, secondText, thirdText}) => (
                                <li className={'help__item'} key={text}>
                                    <div className={'help__image'}>
                                        <img src={img} alt={'guide'}/>
                                    </div>
                                    <p className={'help__item--text'}>
                                        {text}
                                    </p>
                                    {secondText &&<p className={'help__item--text'}>
                                        {secondText}
                                    </p> }
                                    {thirdText &&<p className={'help__item--text'}>
                                        {thirdText}
                                    </p> }
                                </li>
                            ))}
                        </ul>
                    </>}
                </div>
            </div>
        </section>
    )
}

export default Analysis
