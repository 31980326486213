import React, {useEffect, useState} from 'react'
import TeamsCreateModal from "./teams-create-modal";
import {useDispatch, useSelector} from "react-redux";
import {getTeamsThunk, teamSelector} from "../../../../store/slices/team-slice";
import Loader from "../../../../shared/loader/loader";
import {useWindowSize} from "../../../../hooks/useWindowParams";
import {hideSidebar, sidebarSelector, visibleToggle} from "../../../../store/slices/sidebar-slice";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface props {
    hideSidebarHandler: () => void
}

const Teams: React.FC<props> = ({
                                    hideSidebarHandler
                                }) => {
    const {pathname} = useLocation()
    const {t} = useTranslation()
    const {width} = useWindowSize()
    const {sidebar} = useSelector(sidebarSelector)
    const {teamId} = useParams()
    const [visible, setVisible] = useState<boolean>(false)
    const hide = () => {
        if (width && width <= 768) {
            hideSidebarHandler()
        }
    }
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const {teams} = useSelector(teamSelector)

    useEffect(() => {
        dispatch(getTeamsThunk())
    }, [pathname])

    if (!teams.loaded) {
        return (
            <Loader/>
        )
    }
    const slicedTeam = teams.entity.slice(0, 4)
    return (
        <>
            <div onClick={hide} className={'aside__item--icon'}>
                <svg onClick={() => dispatch(hideSidebar(true))}  width="19" height="16" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M14.0027 3C14.0027 4.65685 12.6596 6 11.0027 6C9.34586 6 8.00271 4.65685 8.00271 3C8.00271 1.34315 9.34586 0 11.0027 0C12.6596 0 14.0027 1.34315 14.0027 3ZM1.22251 11L5.24623 10.9999C5.24504 11.5328 5.67316 11.9908 6.22982 11.9998L1.22253 12C0.591481 12 0.118172 11.4227 0.241925 10.8039L0.841941 7.80363C0.935421 7.3362 1.34584 6.99973 1.82252 6.99973L7.82252 6.99973L8.16382 6.99973L8.16386 6.99973L14.1638 6.99973C14.6389 6.99973 15.0483 7.33393 15.1435 7.79934L15.7572 10.7994C15.884 11.4194 15.4103 11.9997 14.7775 11.9998L7.22253 12C6.59148 12 6.11817 11.4227 6.24193 10.8039L6.84194 7.80363C6.9339 7.34381 7.33256 7.01072 7.79929 7L6.90662 7C6.43713 7 6.03085 7.32661 5.92998 7.78514L5.88277 7.99973L1.82252 7.99973L1.22251 11ZM6.00271 3.5C6.00271 4.32843 5.33114 5 4.50271 5C3.67428 5 3.00271 4.32843 3.00271 3.5C3.00271 2.67157 3.67428 2 4.50271 2C5.33114 2 6.00271 2.67157 6.00271 3.5ZM7.00271 3.5C7.00271 4.88071 5.88342 6 4.50271 6C3.122 6 2.00271 4.88071 2.00271 3.5C2.00271 2.11929 3.122 1 4.50271 1C5.88342 1 7.00271 2.11929 7.00271 3.5Z"
                          fill="#5ABE60"/>
                </svg>
            </div>
            <div className={`aside__item--info ${sidebar.isVisible ? "" : "aside__item--info-hidden"}`}>
                <h3 className={'aside__item--title aside__item--team'}>
                    {t('teams')}
                </h3>
                <TeamsCreateModal/>
                {visible ? teams.entity.map(({id, name, isDefault}) => (
                    isDefault ? null : <Link onClick={hide} to={`/teams/${id}/projects`} key={id}
                                             className={`aside__item--email ${teamId === id.toString() ? 'aside__item--email-active' : ''} ${sidebar.isVisible ? "" : "aside__item--email--hide"}`}>
                        {name}
                    </Link>
                )) : slicedTeam .map(({id, name, isDefault}) => (
                    isDefault ? null : <Link onClick={hide} to={`/teams/${id}/projects`} key={id}
                                             className={`aside__item--email ${teamId === id.toString() ? 'aside__item--email-active' : ''} ${sidebar.isVisible ? "" : "aside__item--email--hide"}`}>
                        {name}
                    </Link>
                ))}
                {teams.entity.length > 4 && <div onClick={() => setVisible(!visible)} style={{cursor: 'pointer'}}>
                    <div
                         className={`aside__item--arrow ${visible ? 'aside__item--arrow--hide' : ''}`}/>
                </div>}

            </div>
        </>
    )
}

export default Teams
