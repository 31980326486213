export const userRoles = [
    "admin",
    "moderator",
    'project_access',
    "viewer",
]

export interface IMemberRoles {
    admin: string
    moderator: string
    project_access: string
    viewer: string
}

export interface IRolesDescription {
    canReindexProjects: string,
    canDeleteParticipants: string,
    canAddParticipants: string,
    canIgnoreHash: string,
    canUploadProjects: string,
    canDeleteProjects: string
}

export const memberRoles:IMemberRoles = {
    admin: "admin",
    moderator: "moderator",
    project_access: 'project_access',
    viewer: "viewer"
}
export const memberRolesNames = {
    admin: "Admin",
    moderator: "Moderator",
    project_access: 'Project Access',
    viewer: "Viewer"
}

export const rolesNames: IRolesDescription = {
    canReindexProjects: 'Reindex Projects',
    canDeleteParticipants: 'Delete Participants',
    canAddParticipants: 'Add Participants',
    canIgnoreHash: 'Ignore Hash',
    canUploadProjects: 'Upload Projects',
    canDeleteProjects: 'Delete Projects'
}