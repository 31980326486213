import React, {useState} from 'react'
import {useDispatch} from "react-redux";
import axios, {AxiosResponse} from "axios";
import {HOST} from "../../../../../../../constants/host";
import {IProviderGet} from "../../../../../../../interfaces/providersOauth/providersOauth";
import {setProvider} from "../../../../../../../store/slices/oauth-slice";
import Loader from "../../../../../../../shared/loader/loader";
import {useNavigate} from "react-router-dom";

interface props {
    create?: boolean
}

const Github: React.FC<props> = ({create}) => {
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onGithubAuth = () => {
        setConfirmLoading(true)
        axios.get(`${HOST}oauth/providers`)
            .then((response: AxiosResponse<IProviderGet>) => {
                if (response && response.data && response.data.github) {
                    {
                        dispatch(setProvider(response.data.github))
                        window.location.href = response.data.github.provider_url
                    }
                }
            }).catch((e) => {
            if (e.code === 'ERR_NETWORK') {
                navigate('/maintenance')
            }
        }).finally(() => setConfirmLoading(false))
    }
    return (
        <button onClick={onGithubAuth} className={`${create ? 'register-oauth' : ''} oauth__google`}>
            {confirmLoading && <Loader/>}
            <svg width="20" height="21" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M9.96942 0C4.46483 0 0 4.58933 0 10.2474C0 14.7739 2.87462 18.6088 6.78899 19.9919C7.27829 20.0547 7.46177 19.7404 7.46177 19.4889C7.46177 19.2375 7.46177 18.6088 7.46177 17.7286C4.70948 18.3573 4.09786 16.3456 4.09786 16.3456C3.66972 15.1511 2.99694 14.8367 2.99694 14.8367C2.07951 14.2081 3.0581 14.2081 3.0581 14.2081C4.0367 14.2709 4.58716 15.2768 4.58716 15.2768C5.50459 16.8485 6.91131 16.4084 7.46177 16.1569C7.52294 15.4654 7.82875 15.0253 8.07339 14.7739C5.87156 14.5224 3.5474 13.6422 3.5474 9.6816C3.5474 8.54998 3.91437 7.66984 4.58716 6.91543C4.52599 6.72682 4.15902 5.65808 4.70948 4.27499C4.70948 4.27499 5.56575 4.02352 7.46177 5.34374C8.25688 5.09227 9.11315 5.0294 9.96942 5.0294C10.8257 5.0294 11.682 5.15514 12.4771 5.34374C14.3731 4.02352 15.2294 4.27499 15.2294 4.27499C15.7798 5.65808 15.4128 6.72682 15.3517 6.97829C15.9633 7.66984 16.3914 8.61285 16.3914 9.74446C16.3914 13.7051 14.0673 14.5224 11.8654 14.7739C12.2324 15.0882 12.5382 15.7169 12.5382 16.6599C12.5382 18.043 12.5382 19.1117 12.5382 19.4889C12.5382 19.7404 12.7217 20.0547 13.211 19.9919C17.1865 18.6088 20 14.7739 20 10.2474C19.9388 4.58933 15.474 0 9.96942 0Z"
                      fill="white"/>
            </svg>

            Sign {create ? 'up' : 'in'} with Github
        </button>
    )
}

export default Github
