import React from 'react'
import {Link} from "react-router-dom";
import {useWindowSize} from "../../../hooks/useWindowParams";
import {useSelector} from "react-redux";
import {sidebarSelector} from "../../../store/slices/sidebar-slice";
import {useTranslation} from "react-i18next";

interface props {
    hideSidebarHandler: () => void
}

const Settings: React.FC<props> = ({hideSidebarHandler}) => {
    const {width} = useWindowSize()
    const {sidebar} = useSelector(sidebarSelector)
    const hide = () => {
        if (width && width <= 768) {
            hideSidebarHandler()
        }
    }
    const {t} = useTranslation()
    return (
        <Link onClick={hide} to={'/settings'} className={'aside__item--wrapper'}>
            <div className={'aside__item--icon'}>
                <svg width="19" height="18" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.12856 0C0.528555 0 0.0636244 0.52469 0.135823 1.12033L0.404837 3.33969L1.9722 4.64792C2.29904 4.42591 2.65549 4.24296 3.03529 4.10627L2.91466 1.68389C3.30208 1.58949 3.70269 1.52642 4.11389 1.49767C4.52567 1.46887 4.93171 1.47563 5.32903 1.51534L5.54435 3.90481C5.95161 3.98104 6.342 4.10922 6.7078 4.28264L8.30927 2.51331C9.00333 2.935 9.62169 3.47257 10.1358 4.10121L8.61964 5.91773C8.85223 6.25879 9.04224 6.63223 9.18144 7.0309L11.5387 6.91352C11.6333 7.30136 11.6965 7.70243 11.7252 8.11411C11.754 8.5254 11.7473 8.93099 11.7077 9.32786L9.35962 9.53946C9.27795 9.95493 9.14215 10.3525 8.95939 10.7238L10.3694 12H14.8715C15.4715 12 15.9364 11.4753 15.8642 10.8797L14.6521 0.879669C14.5913 0.377598 14.1651 0 13.6594 0H1.12856ZM4.85441 11.395C6.39102 11.2875 7.54958 9.95474 7.44213 8.41813C7.33468 6.88152 6.00191 5.72295 4.4653 5.8304C2.92868 5.93785 1.77012 7.27063 1.87757 8.80724C1.98502 10.3439 3.3178 11.5024 4.85441 11.395Z" fill="#5ABE60"/>
                </svg>

            </div>
            <div className={`aside__item--info ${sidebar.isVisible ? "" : "aside__item--info-hidden"}`}>
                <h3 className={'aside__item--title'}>
                    {t('settings')}
                </h3>
            </div>
        </Link>
    )
}

export default Settings
