import React, {useEffect} from 'react'
import './login-form.css'
import SignInForm from "../sign-in-form/sign-in-form";
import {getToken} from "../../../../utils/authLocalStorage";
import {useNavigate} from "react-router-dom";
import gif from '../../../../assets/gif/alpha18fps_online.gif'

interface props {

}

const LoginForm: React.FC<props> = () => {
    const token = getToken()
    const navigate = useNavigate()
    useEffect(() => {
        if (token) {
            navigate('/projects')
        }
    }, [token])

    return (
        <div className={'login-wrap'}>
            <div className={'login__gif'}>
                <img src={gif} alt={'gif'} loading="lazy"/>
            </div>
            <div className={'login-wrapper'}>
                <SignInForm/>
            </div>
        </div>
    )
}

export default LoginForm
