import React, {useEffect, useRef, useState} from 'react'
import './change-page.css'
import {useDispatch, useSelector} from "react-redux";
import {counterSelector, setCount} from "../../../../../../../../store/slices/counters-slice";
import {useTranslation} from "react-i18next";

interface props {
    disableFirst: () => void
    handlePageChange: (count: number) => void
}

const ChangePage: React.FC<props> = ({disableFirst, handlePageChange}) => {
    const pages = [25, 50, 100]
    const [visible, setVisible] = useState<boolean>(false)
    const {files} = useSelector(counterSelector)
    const visibleHandler = () => {
        setVisible(!visible)
    }
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const hideHandler = () => {
        setVisible(false)
    }
    const wrapperRef = useRef(null);
    const hideOptions = (e: any) => {
        if (wrapperRef.current) {
            // @ts-ignore
            if (!wrapperRef.current.contains(e.target)) {
                hideHandler()
            }
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', hideOptions)
        return () => document.removeEventListener('mouseup', hideOptions)
    }, [])
    const onSetCount = (page: number) => {
        disableFirst()
        handlePageChange(1)
        dispatch(setCount(page))
        hideHandler()
    }
    return (
        <div ref={wrapperRef} className={'change-page'}>
            <span>{t('onPage')}</span>
            <div onClick={visibleHandler} className={'change-page__btn'}>
                {files.count} <span className={'change-page__arrow'}/>
            </div>
            {visible && <div className={'change-page__dropdown'}>
                {pages.map((page) => (
                    <div onClick={() => onSetCount(page)} className={'change-page__dropdown--item'} key={page}>
                        {page}
                    </div>
                ))}
            </div>}
        </div>
    )
}

export default ChangePage
