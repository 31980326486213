export interface ITypes{
    number: number
    title: string
}

export const types: ITypes[] = [
    {
        number: 1,
        title: 'File name'
    },
    {
        number: 2,
        title: 'File content'
    },
    {
        number: 3,
        title: 'Image P'
    },
    {
        number: 4,
        title: 'Image D'
    },
    {
        number: 5,
        title: 'Image color'
    },
    {
        number: 6,
        title: 'Image average'
    },
    {
        number: 7,
        title: 'XML structure'
    },
    {
        number: 8,
        title: 'Json structure'
    },
    {
        number: 9,
        title: 'Java file structure'
    },
    {
        number: 10,
        title: 'Kotlin file structure'
    },
    {
        number: 11,
        title: 'Package name'
    },
    {
        number: 12,
        title: 'Class name'
    },
    {
        number: 13,
        title: 'Method name'
    },
    {
        number: 14,
        title: 'Variable name'
    },
    {
        number: 15,
        title: 'View id'
    },
    {
        number: 16,
        title: 'Swift structure'
    },
    {
        number: 17,
        title: 'Swift class name'
    },
    {
        number: 18,
        title: 'Swift function name'
    },
    {
        number: 19,
        title: 'Swift constant name'
    },
    {
        number: 20,
        title: 'Swift variable name'
    },
    {
        number: 21,
        title: 'Swift enum name'
    },
    {
        number: 22,
        title: 'Swift struct name'
    },
    {
        number: 23,
        title: 'Swift protocol name'
    },
    {
        number: 24,
        title: 'Swift extension name'
    },
    {
        number: 25,
        title: 'XML variable name'
    },
    {
        number: 26,
        title: 'Translation name'
    },
    {
        number: 27,
        title: 'Color name'
    },
    {
        number: 28,
        title: 'Strings'
    },
    {
        number: 29,
        title: 'Style name'
    },
    {
        number: 30,
        title: 'Serialized name'
    },
    {
        number: 31,
        title: 'DB table name'
    },
    {
        number: 32,
        title: 'DB column name'
    },
    {
        number: 33,
        title: 'Java class structure'
    },
    {
        number: 34,
        title: 'Kotlin class structure'
    },
    {
        number: 35,
        title: 'Swift class structure'
    },
    {
        number: 36,
        title: 'Java function structure'
    },
    {
        number: 37,
        title: 'Kotlin function structure'
    },
    {
        number: 38,
        title: 'Swift function structure'
    },
    {
        number: 39,
        title: 'CSharp file structure'
    },
    {
        number: 40,
        title: 'CSharp class structure'
    },
    {
        number: 41,
        title: 'CSharp function structure'
    },
    {
        number: 42,
        title: 'Objective C file structure'
    },
    {
        number: 43,
        title: 'Objective C class structure'
    },
    {
        number: 44,
        title: 'Objective C function structure'
    },
    {
        number: 45,
        title: 'JavaScript file structure'
    },
    {
       number: 46,
       title: 'JavaScript class structure'
    },
    {
        number: 47,
        title: 'JavaScript function structure'
    },
    {
        number: 48,
        title: 'TypeScript file structure'
    },
    {
        number: 49,
        title: 'TypeScript class structure'
    },
    {
        number: 50,
        title: 'TypeScript function structure'
    },
    {
        number: 51,
        title: 'PHP file structure'
    },
    {
        number: 52,
        title: 'PHP class structure'
    },
    {
        number: 53,
        title: 'PHP function structure'
    },
    {
        number: 54,
        title: 'GO file structure'
    },
    {
        number: 55,
        title: 'GO class structure'
    },
    {
        number: 56,
        title: 'GO function structure'
    },
    {
        number: 57,
        title: 'GO struct structure'
    },
    {
        number: 58,
        title: 'Imported package name'
    },
    {
        number: 59,
        title: 'Enum name'
    },
    {
        number: 60,
        title:"Actor name"
    },
    {
        number: 61,
        title: 'Dart class structure'
    },
    {
        number: 62,
        title: 'Dart function structure'
    },
    {
        number: 63,
        title: 'Dart file structure'
    }
]