import React, {useEffect} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {providerSelector} from "../../store/slices/oauth-slice";
import makeRequest from "../../api/makeRequest";
import Loader from "../../shared/loader/loader";
import {auth} from "../../store/slices/user-slice";
import {setToken} from "../../utils/authLocalStorage";

interface props {

}

const Github: React.FC<props> = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {provider} = useSelector(providerSelector)
    const dispatch = useDispatch()
    const code = searchParams.get('code')

    const navigate = useNavigate()

    useEffect(() => {
        if (code) {
            makeRequest.get(`https://api.apprefactoring.com/oauth/login/check-github?code=${code}`)
                .then((response) => {
                    if (response && response.data) {
                        dispatch(auth(response.data.user))
                        setToken(response.data.token)
                        navigate('/projects')
                    }
                })
        }
    }, [code])

    return (
        <section className={'login'}>
            <div className={'login-top'}>
            </div>
            <Loader/>
        </section>
    )
}

export default Github
