import {PaginationHasNext} from "./has-next";
import {PaginationHasPrev} from "./has-prev";
import './pagination.css'
import React from "react";

export interface PaginationProps {
    current: number;

    onChange(page: number): void;

    isWhiteDots?: boolean
    last: number
    hasNext: boolean;
    disabled: boolean;
    isNoArrows?: boolean
}

export const Pagination: React.FC<PaginationProps> = ({
                                                          current,
                                                          onChange,
                                                          hasNext,
                                                          disabled,
                                                          last,
                                                          isWhiteDots,
                                                          isNoArrows
                                                      }) => {
    const increment = disabled ? () => {
    } : () => onChange(current + 1);
    const decrement = disabled ? () => {
    } : () => onChange(current - 1);
    const setPage = disabled
        ? () => () => {
        }
        : (num: number) => () => onChange(num);
    const setFirst = () => onChange(1)
    const setLast = () => onChange(last)
    const active = `pagination-button active`;
    return (
        <div className={`pagination`}>
            <div className={'flex'}>
                {current > 1 && !isNoArrows && <button onClick={setFirst} className={'pagination-button__icon--first'}>
                    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5423 5.29175L3.83398 12.0001L10.5423 18.7084" stroke="#535353"
                              strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M15.3333 5.29175L8.625 12.0001L15.3333 18.7084" stroke="#535353" strokeWidth="1.91667"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>}
                {current !== 1 && current - 1 !== 1 && current - 2 !== 1 &&
                <div className={"pagination-button"} onClick={setFirst}>
                    <span>1</span>
                </div>}
                {current - 2 !== 1 && current - 1 !== 1 && current !== 1 && <div className={'pagination__dots'}>
                    <span className={`pagination__dot ${isWhiteDots ? 'pagination__dot--white' : ''}`}/>
                    <span className={`pagination__dot ${isWhiteDots ? 'pagination__dot--white' : ''}`}/>
                    <span className={`pagination__dot ${isWhiteDots ? 'pagination__dot--white' : ''}`}/>
                </div>}
                {current > 1 && (
                    <PaginationHasPrev
                        isNoArrows={isNoArrows}
                        value={current - 1}
                        onDecrement={decrement}
                        onSet={setPage(current - 1)}
                    />
                )}
                <div className={active}>
                    <span>{current}</span>
                </div>
                {hasNext && (
                    <PaginationHasNext
                        isNoArrows={isNoArrows}
                        value={current + 1}
                        onIncrement={increment}
                        onSet={setPage(current + 1)}
                    />
                )}
                {current + 2 !== last && current + 1 !== last && current !== last &&
                <div className={'pagination__dots'}>
                    <span className={`pagination__dot ${isWhiteDots ? 'pagination__dot--white' : ''}`}/>
                    <span className={`pagination__dot ${isWhiteDots ? 'pagination__dot--white' : ''}`}/>
                    <span className={`pagination__dot ${isWhiteDots ? 'pagination__dot--white' : ''}`}/>
                </div>}
                {current !== last && current + 1 !== last && current + 2 !== last &&
                <div className={"pagination-button"} onClick={setLast}>
                    <span>{last}</span>
                </div>}
                {hasNext && !isNoArrows && <button onClick={setLast} className={'pagination-button__icon--last'}>
                    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.4577 5.29175L19.166 12.0001L12.4577 18.7084" stroke="#535353"
                              strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.66667 5.29175L14.375 12.0001L7.66667 18.7084" stroke="#535353"
                              strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>}
            </div>
        </div>
    );
};
