import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setPage, setCount, filterSelector} from "../../../../../../../store/slices/project-filter-slice";

interface props {

}

const ItemsAtPageProject: React.FC<props> = () => {
    const pages = [10, 25, 50, 100]
    const {projectOptions} = useSelector(filterSelector)
    const [visible, setVisible] = useState<boolean>(false)
    const {t} = useTranslation()
    const visibleHandler = (e: any) => {
        setVisible(!visible)
    }
    const dispatch = useDispatch()
    const hideHandler = () => {
        setVisible(false)
    }
    const wrapperRef = useRef(null);
    const hideOptions = (e: any) => {
        if (wrapperRef.current) {
            // @ts-ignore
            if (!wrapperRef.current.contains(e.target)) {
                hideHandler()
            }
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', hideOptions)
        return () => document.removeEventListener('mouseup', hideOptions)
    }, [])
    const onSetCount = (page: number) => {
        dispatch(setCount(page))
        dispatch(setPage(1))
        hideHandler()
    }

    return (
        <div ref={wrapperRef} className={'change-page project_page'}>
            <span>{t('onPage')}</span>
            <div onClick={visibleHandler} className={'change-page__btn'}>
                {projectOptions.count} <span className={'change-page__arrow'}/>
            </div>
            {visible && <div className={'change-page__dropdown project__dropdown'}>
                {pages.map((page) => (
                    <div onClick={() => onSetCount(page)} className={'change-page__dropdown--item'} key={page}>
                        {page}
                    </div>
                ))}
            </div>}
        </div>
    )
}

export default ItemsAtPageProject
