import React from 'react'
import './oauth.css'
import Google from "./components/google";
import Github from "./components/github";
import {useTranslation} from "react-i18next";

interface props {
create?: boolean
}

const Ouath: React.FC<props> = ({create}) => {
    const {t} = useTranslation()
    return (
        <>
            <p className={`oath__text ${create ? 'register-oauth' : ''}`} style={{color: create ? '#000000': '#808080'}}>
                {create ? t('createWith'): t('signInWith')}
            </p>
            {/*<Google/>*/}
            <Github create={create}/>
        </>
    )
}

export default Ouath
