import React, {useEffect} from 'react'
import './homepage.css'
import Mods from "./components/mods/mods";
import Analysis from "./components/analysis/analysis";
import Header from "../../components/header/header";
import {useNavigate, useParams} from "react-router-dom";
import {clearProject, getProjectThunk, projectsSelector, setAllProjects} from "../../store/slices/projects-slice";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../shared/loader/loader";
import {setDocumentTitle} from "../../utils/setDocumentTitle/setDocumentTitle";
import {getTeamThunk} from "../../store/slices/team-slice";
import {getProjectsApi} from "../../api/project/project";

interface props {

}

const Homepage: React.FC<props> = () => {
    const {id, teamId} = useParams()
    const {project} = useSelector(projectsSelector)
    const navigate = useNavigate()


    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setAllProjects([]))
    }, [teamId])
    useEffect(() => {
        if (id) {
            dispatch(getProjectThunk(id, navigate))
            getProjectsApi('?page=1', '', '', '', '', teamId ? `&teamID=${teamId}` : '', '&count=500')
                .then((response) => {
                    dispatch(setAllProjects(response.items))
                }).catch(e => {

            })
            if (teamId && project.entity.team) {
                dispatch(getTeamThunk(teamId, navigate))
            }
        }
        return () => {
            dispatch(clearProject())
            dispatch(setAllProjects([]))
        }
    }, [dispatch, id, teamId, navigate])

    if (!project.loaded) {
        return (
            <Loader/>
        )
    }
    setDocumentTitle(project.entity.name)
    return (
        <>
            <Mods/>
            <Analysis/>
        </>
    )
}

export default Homepage
