export function findColor(width: number | null, middle = 10, high = 30) {
    if (width) {
        if (width <= middle) {
            return '#23D86B'
        }
        if (width > middle && width < high) {
            return '#F6E441'
        }
        if (width >= high) {
            return '#F64149'
        }
    }
}

export function findStatusColor(status: string) {
    if (status === 'failed') {
        return '#F64149'
    }
    if (status === 'completed') {
        return '#5ABE60'
    } else {
        return '#55AAF9'
    }
}