import React, {useEffect} from 'react'
import './header.css'
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {sidebarSelector, visibleToggle} from "../../store/slices/sidebar-slice";
import {useDispatch, useSelector} from "react-redux";
import {useWindowSize} from "../../hooks/useWindowParams";
import {auth, logout, userSelector} from "../../store/slices/user-slice";
import {getUserInfo} from "../../api/user/user";
import Logout from "./components/logout";
import Notifications from "./components/notifications/notifications";
import TeamsCreateSteps from "../aside/components/teams/teams-create-steps";
import {setVisible, teamSelector} from "../../store/slices/team-slice";
import UserDropdown from "./components/user-dropdown/user-dropdown";
import Languages from "./components/languages/languages";
import {useTranslation} from "react-i18next";
import {projectsSelector} from "../../store/slices/projects-slice";


interface props {

}

const Header: React.FC<props> = ({ }) => {
    const dispatch = useDispatch()
    const {id, teamId} = useParams()
    const navigate = useNavigate()
    const {width} = useWindowSize()
    const {user} = useSelector(userSelector)
    const {sidebar} = useSelector(sidebarSelector)
    const {t} = useTranslation()
    const {project} = useSelector(projectsSelector)
    const {pathname} = useLocation()
    const {teams, team} = useSelector(teamSelector)
    const hideHandler = () => {
        dispatch(setVisible(false))
        document.body.style.overflow = 'visible'
    }

    const setHeaderTitle = () => {
        const data = {
            title: '',
            teamName: ''
        }
        if (pathname === '/projects') {
            data.title = t('myProjects')
            data.teamName = ''
        }
        if (pathname === `/project/${id}`) {
            data.title = project.entity?.name
            data.teamName = ''
        }
        if (pathname === `/teams/${teamId}/members`) {
            data.title = t('members')
            data.teamName = team.entity?.name
        }
        if (pathname === `/teams/${teamId}/projects`) {
            data.title = t('projects')
            data.teamName = team.entity?.name
        }
        if (pathname === `/teams/${teamId}/settings`) {
            data.title = t('settings')
            data.teamName = team.entity?.name
        }
        if (pathname === `/teams/${teamId}/projects/${id}`) {
            data.title = project.entity?.name
            data.teamName = team.entity?.name
        }
        if (pathname === '/settings') {
            data.title = t('settings')
            data.teamName = ''
        }
        if (pathname === '/support') {
            data.title = t('support')
            data.teamName = ''
        }
        if (pathname === `/support/${id}`) {
            data.title = t('ticket')
            data.teamName = ''
        }
        if (pathname === '/help') {
            data.title = t('help')
            data.teamName = ''
        }
        return data
    }

    useEffect(() => {
        getUserInfo()
            .then((response) => {
                if (response && response.data) {
                    dispatch(auth(response.data.user))
                }
            }).catch((e) => {
            if (e.code === 'ERR_NETWORK') {
                navigate('/maintenance')
            }
            if (e.response.status === 403) {
                logout()
                navigate('/403')
            }
            if (e.response.status === 404) {
                logout()
                navigate('/404')
            }
            if (e.response.status === 500) {
                navigate('/500')
            }
        })
    }, [pathname, id, teamId])

    return (
        <header className={'header'}>
            {width && width <= 575 && !sidebar.isVisible ?
                <div onClick={() => {
                    dispatch(visibleToggle())
                    document.body.style.overflow = 'hidden'
                }} className={'aside__toggler header__toggler'}>
                    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 0.995C0 0.445 0.446 0 0.995 0H9.005C9.26889 7.86455e-09 9.52197 0.10483 9.70857 0.291429C9.89517 0.478027 10 0.731109 10 0.995C10 1.25889 9.89517 1.51197 9.70857 1.69857C9.52197 1.88517 9.26889 1.99 9.005 1.99H0.995C0.731109 1.99 0.478028 1.88517 0.291429 1.69857C0.10483 1.51197 0 1.25889 0 0.995ZM0 7C0 6.45 0.446 6.005 0.995 6.005H19.005C19.2689 6.005 19.522 6.10983 19.7086 6.29643C19.8952 6.48303 20 6.73611 20 7C20 7.26389 19.8952 7.51697 19.7086 7.70357C19.522 7.89017 19.2689 7.995 19.005 7.995H0.995C0.731109 7.995 0.478028 7.89017 0.291429 7.70357C0.10483 7.51697 0 7.26389 0 7ZM0.995 12.01C0.731109 12.01 0.478028 12.1148 0.291429 12.3014C0.10483 12.488 0 12.7411 0 13.005C0 13.2689 0.10483 13.522 0.291429 13.7086C0.478028 13.8952 0.731109 14 0.995 14H13.005C13.2689 14 13.522 13.8952 13.7086 13.7086C13.8952 13.522 14 13.2689 14 13.005C14 12.7411 13.8952 12.488 13.7086 12.3014C13.522 12.1148 13.2689 12.01 13.005 12.01H0.995Z"
                            fill="#5ABE60"/>
                    </svg>
                </div> : null}
            <div className={'header__wrapper'}>
                <div className={'header__logo'}>
                    <Link to={'/projects'}>
                        <svg width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.588867 9.66309C0.588867 9.79199 0.613932 9.91732 0.664062 10.0391C0.721354 10.1608 0.803711 10.2646 0.911133 10.3506L7.76465 16.5381C7.95801 16.71 8.16927 16.7959 8.39844 16.7959C8.6276 16.7959 8.82096 16.7064 8.97852 16.5273C9.14323 16.3483 9.21484 16.1442 9.19336 15.915C9.17188 15.6859 9.06803 15.4889 8.88184 15.3242L2.64062 9.66309L8.88184 4.0127C9.06803 3.84798 9.17188 3.65104 9.19336 3.42188C9.21484 3.18555 9.14323 2.98145 8.97852 2.80957C8.82096 2.63053 8.6276 2.54102 8.39844 2.54102C8.16927 2.53385 7.95801 2.61621 7.76465 2.78809L0.911133 8.97559C0.803711 9.06868 0.721354 9.17611 0.664062 9.29785C0.613932 9.41243 0.588867 9.53418 0.588867 9.66309ZM11.8789 19.2344C12.1296 19.2988 12.3551 19.2702 12.5557 19.1484C12.7562 19.0339 12.8887 18.8548 12.9531 18.6113L18.0449 1.15527C18.1094 0.933268 18.0915 0.725586 17.9912 0.532227C17.891 0.338867 17.7191 0.209961 17.4756 0.145508C17.2393 0.0810547 17.0244 0.0989583 16.8311 0.199219C16.6449 0.292318 16.5088 0.478516 16.4229 0.757812L11.3633 18.1494C11.2917 18.3929 11.2952 18.6185 11.374 18.8262C11.46 19.0339 11.6283 19.1699 11.8789 19.2344ZM28.7549 9.66309C28.7549 9.53418 28.7262 9.41243 28.6689 9.29785C28.6188 9.17611 28.54 9.06868 28.4326 8.97559L21.5791 2.78809C21.3857 2.61621 21.1745 2.53385 20.9453 2.54102C20.7161 2.54102 20.5228 2.63053 20.3652 2.80957C20.2077 2.98145 20.1361 3.18555 20.1504 3.42188C20.1719 3.65104 20.2757 3.84798 20.4619 4.0127L26.7031 9.66309L20.4619 15.3242C20.2757 15.4889 20.1719 15.6859 20.1504 15.915C20.1361 16.1442 20.2077 16.3483 20.3652 16.5273C20.5228 16.7064 20.7161 16.7959 20.9453 16.7959C21.1745 16.7959 21.3857 16.71 21.5791 16.5381L28.4326 10.3506C28.54 10.2646 28.6188 10.1608 28.6689 10.0391C28.7262 9.91732 28.7549 9.79199 28.7549 9.66309Z"
                                fill="#5ABE60"/>
                        </svg>
                    </Link>
                </div>
                <h2 className={'header__title'}>
                    App Refactoring
                </h2>
                {width && width > 575 && setHeaderTitle().teamName ? <h4 className={'header__subTitle'}>
                    {setHeaderTitle().teamName.length > 15 ? `${setHeaderTitle().teamName.slice(0, 15)}...` : setHeaderTitle()?.teamName}
                </h4> : null}
                {width && width > 575 ? <h4 className={'header__subTitle'}>
                    {setHeaderTitle()?.title?.length > 15 ? `${setHeaderTitle()?.title?.slice(0, 15)}...` : setHeaderTitle()?.title}
                </h4> : null}
            </div>
            <div className={'header__profile'}>
                {teamId && teamId.length > 0 && <div className={'header__profile--teamId'}>
                    {t('teamId')}: {teamId}
                </div>}
                <Notifications/>
                {width && width <= 575 ?
                    <UserDropdown/>
                    : <>
                        <div className={'header__profile--info'}>
                            <p className={'header__profile--email'}>
                                {user.entity.username}
                            </p>
                        </div>
                        <Logout/>
                    </>}
                {teams.isVisible && <TeamsCreateSteps hideHandler={hideHandler}/>}
                <Languages/>
            </div>
        </header>
    )
}

export default Header
