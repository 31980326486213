import makeRequest from "../makeRequest";
import {IUploadData, IViaLinkUpload} from "../../interfaces/upload-project/upload-project";
import {IProgress} from "../../pages/upload/components/upload-form/upload-form";


export function uploadProject(datas: IUploadData, setProgress: IProgress, refreshProgress: any, setConfirmLoading: any) {
    let controller = new AbortController();
    const reset = () => {
        controller.abort()
        const newData: IProgress = {
            ...setProgress,
            projectName: datas.fileName.name,
            isCanceled: true
        }
        refreshProgress(newData)
    }
    const data = new FormData()
    let type = datas.type
    if (datas.type.includes('#')) {
        type = 'csharp'
    }
    if (datas.type === 'javascript') {
        type = 'js'
    }
    if (datas.type === 'typescript') {
        type = 'ts'
    }
    data.append('type', type)
    data.append('fileName', datas.fileName)
    if (datas.compare_to_id && datas.compare_to_id.length) {
        data.append('compare_to_id', datas.compare_to_id)
    }
    if (datas.teamID) {
        data.append('teamID', datas.teamID.toString())
    }
    let startTime: any, endTime;
    startTime = (new Date()).getTime();
    return makeRequest.post('api/projects/', data, {
        signal: controller.signal,
        method: 'POST',
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: data,
        onUploadProgress: data => {
            endTime = (new Date()).getTime();
            let duration = (endTime - startTime) / 1000
            let speedBps = Math.round(data.loaded / duration);
            let speedKbps = (speedBps / 1024).toFixed(2);
            let speedMbps = (Number(speedKbps) / 1024).toFixed(2);
            let total = Math.round(data.total / 1024 / 1024)
            let loaded = Math.round(data.loaded / 1024 / 1024)
            let leftSize = total - loaded
            const leftTime = leftSize / Number(speedMbps)
            const seconds = Number((leftTime % 60).toFixed(0)) < 10 ? `0${(leftTime % 60).toFixed(0)}` : (leftTime % 60).toFixed(0)
            const newData: IProgress = {
                ...setProgress,
                id: setProgress.id,
                isCanceled: false,
                speed: Number(speedMbps),
                total: total,
                loaded: Math.round(data.loaded / 1024 / 1024),
                percentage: Math.round((100 * data.loaded) / data.total),
                projectType: datas.type,
                projectName: datas.fileName.name,
                leftTime: Math.floor(leftTime / 60) < 1 ? `${seconds} sec` : `${Math.floor(leftTime / 60)} min`,
                projectId: null,
                abort: reset
            }
            refreshProgress(newData)
            setConfirmLoading(true)
        }
    },)
}

export function uploadViaLinkProjects(data: IViaLinkUpload) {
    const newData = new FormData()
    newData.append('type', data.type)
    newData.append('archiveUrl', data.archiveUrl)
    if (data.teamID) {
        newData.append('teamID', data.teamID.toString())
    }
    return makeRequest({
        method: 'POST',
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: newData,
        url: `api/projects/direct_link`
    })
}

export function getProjectsApi(page: string, sortBy?: string, sortValue?: string, filterBy?: string, filterValue?: string, teamID?: string, count?: string) {
    return makeRequest.get(`api/projects${page ? page : ''}${sortBy ? sortBy : ``}${sortValue ? sortValue : ``}${filterBy ? filterBy : ''}${filterValue ? filterValue : ''}${teamID ? teamID : ''}${count ? count : ''}`).then((response) => {
        if (response && response.data) {
            return response.data
        }
    })
}

export function getProjectApi(id: string) {
    return makeRequest.get(`api/project/${id}`).then((response) => {
        if (response && response.data) {
            return response.data.project
        }
    })
}

export function deleteProjectApi(id: number) {
    return makeRequest({
        method: 'DELETE',
        url: `api/projects/${id}/`
    })
}

export function renameProjectApi(id: number, data: { name: string }) {
    return makeRequest({
        method: "PUT",
        url: `api/projects/${id}/rename`,
        data: data
    })
}

export function reindexProjectApi(id: string) {
    return makeRequest.get(`api/project/${id}/reindex`)
}