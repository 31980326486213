import React, {useEffect} from 'react'
import Header from "../../components/header/header";
import Account from "./components/account/account";
import Notifications from "./components/notifications/notifications";
import {getUserInfo} from "../../api/user/user";
import {auth, setLoaded} from "../../store/slices/user-slice";
import {useDispatch} from "react-redux";
import {setDocumentTitle} from "../../utils/setDocumentTitle/setDocumentTitle";
import {useTranslation} from "react-i18next";

interface props {

}

const Settings: React.FC<props> = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        return () => {
            dispatch(setLoaded(false))
            getUserInfo()
                .then((response) => {
                    if (response && response.data) {
                        dispatch(auth(response.data.user))
                    }
                }).catch(() => {
            }).finally(() => dispatch(setLoaded(true)))
        }
    }, [])
    const {t} = useTranslation()
    setDocumentTitle(t('settings'))
    return (
        <>
            <Account/>
            <Notifications/>
        </>
    )
}

export default Settings
