import {Action, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {IUploadProjectState} from "../../interfaces/upload-project/upload-project";

const initialState: IUploadProjectState = {
    upload: {
        compare_to_id: null,
        archiveUrl: '',
        fileName: null,
        type: ''
    }
}


export const uploadSelector = (state: { upload: IUploadProjectState }) => state.upload

const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        setType: (state, action: PayloadAction<string>) => {
            state.upload.type = action.payload
        },
        setFileName: (state, action: PayloadAction<any>) => {
            state.upload.fileName = action.payload
            state.upload.archiveUrl = ''
        },
        setCompareToId: (state, action: PayloadAction<number | null>) => {
            state.upload.compare_to_id = action.payload
        },
        setArchiveUrl: (state, action: PayloadAction<string>) => {
            state.upload.archiveUrl = action.payload
            state.upload.fileName = null
        }
    }
})


export const {setCompareToId, setFileName, setType, setArchiveUrl} = uploadSlice.actions


export default uploadSlice.reducer