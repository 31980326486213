import React, {useEffect, useState} from 'react'
import './change-password-modal.css'
import {useForm} from "react-hook-form";
import Loader from "../../../../../../shared/loader/loader";
import {changePassword, getUserInfo} from "../../../../../../api/user/user";
import {auth} from "../../../../../../store/slices/user-slice";
import {useDispatch} from "react-redux";
import {setToken} from "../../../../../../utils/authLocalStorage";
import {oneLetterPattern, oneNumberPattern, passwordPattern} from "../../../../../../constants/patterns";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface props {
    id: number
}

const ChangePasswordModal: React.FC<props> = ({id}) => {
    const [visible, setVisible] = useState<boolean>(false)
    const [password, setPassword] = useState<string | null>(null)
    const [minLength, setMinLength] = useState<string>('')
    const [oneLetter, setOneLetter] = useState<string>('')
    const {t} = useTranslation()
    const [oneNumber, setOneNumber] = useState<string>('')
    const [isShow, setIsShow] = useState({
        old: false,
        new: false,
        repeat: false
    })
    const navigate = useNavigate()
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm();
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const dispatch = useDispatch()
    const handleChange = (name: string, value: boolean) => {
        setIsShow({
            ...isShow, [name]: value
        })
    }
    useEffect(() => {
        if (password) {
            if (password.trim().length < 8) {
                setMinLength('error')
            } else if (password.trim().length >= 8) {
                setMinLength(
                    'success'
                )
            }
        }
    }, [password])
    useEffect(() => {
        if (password) {
            if (oneNumberPattern.test(password)) {
                setOneNumber('success')
            } else if (!oneNumberPattern.test(password)) {
                setOneNumber('error')
            }
        }
    }, [password])
    useEffect(() => {
        if (password) {
            if (oneLetterPattern.test(password)) {
                setOneLetter('success')
            } else if (!oneLetterPattern.test(password)) {
                setOneLetter('error')
            }
        }
    }, [password])
    const resetUser = () => {
        getUserInfo()
            .then((response) => {
                if (response && response.data) {
                    dispatch(auth(response.data.user))
                }
            }).catch((e) => {
            if (e.response.status === 403) {
                navigate('/403')
            }
            if (e.response.status === 500) {
                navigate('/500')
            }
        })
    }
    const showHandler = () => {
        document.body.style.overflow = 'hidden'
        setVisible(true)
    }
    const hideHandler = () => {
        setVisible(false)
        document.body.style.overflow = 'visible'
    }
    const onChange = (e: any) => setPassword(e.target.value)
    const onChangePassword = (e: any) => {
        if (password) {
            if (minLength === 'success' && oneLetter === 'success' && oneNumber === 'success') {
                const data = {
                    ...e,
                    newPassword: password
                }
                setConfirmLoading(true)
                changePassword(id, data)
                    .then((response) => {
                        if (response && response.data) {
                            setToken(response.data.token)
                            resetUser()
                            reset()
                            setVisible(false)
                            hideHandler()
                            setError('')
                        }
                    }).catch((e) => {
                    setError(e.response.data.message)
                }).finally(() => setConfirmLoading(false))
            }
        }
    }
    return (
        <>
            <div onClick={showHandler} className={'account__subtitle'} style={{cursor: "pointer"}}>
                <p className={'account__text account__text--blue'}>
                    {t('changePass')}
                </p>
            </div>
            {visible && <div className={'logout-modal-wrapper'}>
                <div onClick={hideHandler} className={'create-team__backdrop'}>

                </div>
                <form onSubmit={handleSubmit(onChangePassword)} className={'logout-modal change-password'}>
                    {confirmLoading && <Loader/>}
                    <svg onClick={hideHandler} className={'create-team__icon'} width="11" height="11"
                         viewBox="0 0 11 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.804688 10.2803C0.86849 10.3486 0.945964 10.3919 1.03711 10.4102C1.12826 10.4329 1.21712 10.4329 1.30371 10.4102C1.3903 10.3919 1.47005 10.3486 1.54297 10.2803L5.7334 6.08301L9.93066 10.2803C10.0309 10.3805 10.154 10.4307 10.2998 10.4307C10.4456 10.4352 10.5687 10.3851 10.6689 10.2803C10.7692 10.1755 10.8193 10.0501 10.8193 9.9043C10.8193 9.75846 10.7692 9.6377 10.6689 9.54199L6.47168 5.34473L10.6689 1.14746C10.7692 1.05176 10.8193 0.93099 10.8193 0.785156C10.8239 0.634766 10.7738 0.50944 10.6689 0.40918C10.5687 0.308919 10.4456 0.258789 10.2998 0.258789C10.154 0.258789 10.0309 0.308919 9.93066 0.40918L5.7334 4.60645L1.54297 0.40918C1.47005 0.345378 1.3903 0.302083 1.30371 0.279297C1.21712 0.25651 1.12826 0.25651 1.03711 0.279297C0.945964 0.297526 0.86849 0.34082 0.804688 0.40918C0.736328 0.477539 0.690755 0.557292 0.667969 0.648438C0.645182 0.735026 0.645182 0.823893 0.667969 0.915039C0.690755 1.00618 0.736328 1.08366 0.804688 1.14746L4.99512 5.34473L0.804688 9.54199C0.736328 9.60579 0.688477 9.68327 0.661133 9.77441C0.638346 9.86556 0.638346 9.95671 0.661133 10.0479C0.683919 10.139 0.731771 10.2165 0.804688 10.2803Z"
                            fill="#C6C7C7"/>
                    </svg>
                    <div className={'create-team__icon-wrapper'}>
                        <h2 className={'create-team__title'}>
                            {t('changePass')}
                        </h2>

                    </div>
                    <label className={'password__label'}>
                        {t('oldPass')}
                    </label>
                    <div className={'password__input'}>
                        <input {...register('oldPassword', {required: true, pattern: passwordPattern, onBlur: (e) => setValue('oldPassword', e.target.value.trim())})}
                               type={isShow.old ? 'text' : 'password'}/>
                        <svg onClick={() => handleChange('old', !isShow.old)} className={'password__show'} width="16"
                             height="19" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.5 8C10.5 8.66304 10.2366 9.29893 9.76777 9.76777C9.29893 10.2366 8.66304 10.5 8 10.5C7.33696 10.5 6.70107 10.2366 6.23223 9.76777C5.76339 9.29893 5.5 8.66304 5.5 8C5.5 7.33696 5.76339 6.70107 6.23223 6.23223C6.70107 5.76339 7.33696 5.5 8 5.5C8.66304 5.5 9.29893 5.76339 9.76777 6.23223C10.2366 6.70107 10.5 7.33696 10.5 8Z"
                                fill="#DDDDDD"/>
                            <path
                                d="M0 8C0 8 3 2.5 8 2.5C13 2.5 16 8 16 8C16 8 13 13.5 8 13.5C3 13.5 0 8 0 8ZM8 11.5C8.92826 11.5 9.8185 11.1313 10.4749 10.4749C11.1313 9.8185 11.5 8.92826 11.5 8C11.5 7.07174 11.1313 6.1815 10.4749 5.52513C9.8185 4.86875 8.92826 4.5 8 4.5C7.07174 4.5 6.1815 4.86875 5.52513 5.52513C4.86875 6.1815 4.5 7.07174 4.5 8C4.5 8.92826 4.86875 9.8185 5.52513 10.4749C6.1815 11.1313 7.07174 11.5 8 11.5V11.5Z"
                                fill="#DDDDDD"/>
                        </svg>
                    </div>
                    {errors.oldPassword && errors.oldPassword.type === 'pattern' &&
                    <p className={'password__error'}>
                        Password must have 8 symbols and should include at least one letter and one number
                    </p>
                    }
                    <label className={'password__label'}>
                        {t('newPass')}
                    </label>
                    <div className={'password__input'}>
                        <input onChange={onChange} value={password ? password : ""}
                               type={isShow.new ? 'text' : 'password'}/>
                        <svg onClick={() => handleChange('new', !isShow.new)} className={'password__show'} width="16"
                             height="19" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.5 8C10.5 8.66304 10.2366 9.29893 9.76777 9.76777C9.29893 10.2366 8.66304 10.5 8 10.5C7.33696 10.5 6.70107 10.2366 6.23223 9.76777C5.76339 9.29893 5.5 8.66304 5.5 8C5.5 7.33696 5.76339 6.70107 6.23223 6.23223C6.70107 5.76339 7.33696 5.5 8 5.5C8.66304 5.5 9.29893 5.76339 9.76777 6.23223C10.2366 6.70107 10.5 7.33696 10.5 8Z"
                                fill="#DDDDDD"/>
                            <path
                                d="M0 8C0 8 3 2.5 8 2.5C13 2.5 16 8 16 8C16 8 13 13.5 8 13.5C3 13.5 0 8 0 8ZM8 11.5C8.92826 11.5 9.8185 11.1313 10.4749 10.4749C11.1313 9.8185 11.5 8.92826 11.5 8C11.5 7.07174 11.1313 6.1815 10.4749 5.52513C9.8185 4.86875 8.92826 4.5 8 4.5C7.07174 4.5 6.1815 4.86875 5.52513 5.52513C4.86875 6.1815 4.5 7.07174 4.5 8C4.5 8.92826 4.86875 9.8185 5.52513 10.4749C6.1815 11.1313 7.07174 11.5 8 11.5V11.5Z"
                                fill="#DDDDDD"/>
                        </svg>
                    </div>
                    <div className={'password__rules modal__rules'}>
                        <p className={'password__rule'}>
                            {t('passRules.title')}
                        </p>
                        <p className={`password__rule ${minLength === 'error' ? 'password__rules--wrong' : ''} ${minLength === 'success' ? 'password__rules--pass' : ''}`}>
                            {t('passRules.minLength')}
                            {minLength === 'success' && <span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.0542 9.97754C4.4445 9.97754 4.73991 9.82894 4.94043 9.53174L9.65088 2.38281C9.72607 2.27181 9.77979 2.16439 9.81201 2.06055C9.84424 1.95312 9.86035 1.85107 9.86035 1.75439C9.86035 1.48942 9.76904 1.26921 9.58643 1.09375C9.40739 0.914714 9.18001 0.825195 8.9043 0.825195C8.7181 0.825195 8.56055 0.862793 8.43164 0.937988C8.30273 1.01318 8.1792 1.1403 8.06104 1.31934L4.03271 7.6626L2.00244 5.19189C1.80192 4.95557 1.55127 4.8374 1.25049 4.8374C0.974772 4.8374 0.745605 4.92692 0.562988 5.10596C0.383952 5.28141 0.294434 5.50342 0.294434 5.77197C0.294434 5.89372 0.314128 6.0083 0.353516 6.11572C0.396484 6.22314 0.469889 6.33594 0.57373 6.4541L3.18945 9.58008C3.41862 9.84505 3.70687 9.97754 4.0542 9.97754Z"
                                fill="#5ABE60"/>
                        </svg>
                        </span>
                            }
                        </p>

                        <p className={`password__rule ${oneLetter === 'error' ? 'password__rules--wrong' : ''} ${oneLetter === 'success' ? 'password__rules--pass' : ''}`}>
                            {t('passRules.oneLetter')}
                            {oneLetter === 'success' && <span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.0542 9.97754C4.4445 9.97754 4.73991 9.82894 4.94043 9.53174L9.65088 2.38281C9.72607 2.27181 9.77979 2.16439 9.81201 2.06055C9.84424 1.95312 9.86035 1.85107 9.86035 1.75439C9.86035 1.48942 9.76904 1.26921 9.58643 1.09375C9.40739 0.914714 9.18001 0.825195 8.9043 0.825195C8.7181 0.825195 8.56055 0.862793 8.43164 0.937988C8.30273 1.01318 8.1792 1.1403 8.06104 1.31934L4.03271 7.6626L2.00244 5.19189C1.80192 4.95557 1.55127 4.8374 1.25049 4.8374C0.974772 4.8374 0.745605 4.92692 0.562988 5.10596C0.383952 5.28141 0.294434 5.50342 0.294434 5.77197C0.294434 5.89372 0.314128 6.0083 0.353516 6.11572C0.396484 6.22314 0.469889 6.33594 0.57373 6.4541L3.18945 9.58008C3.41862 9.84505 3.70687 9.97754 4.0542 9.97754Z"
                                fill="#5ABE60"/>
                        </svg>
                        </span>
                            }
                        </p>
                        <p className={`password__rule ${oneNumber === 'error' ? 'password__rules--wrong' : ''} ${oneNumber === 'success' ? 'password__rules--pass' : ''}`}>
                            {t('passRules.oneNumber')}
                            {oneNumber === 'success' && <span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.0542 9.97754C4.4445 9.97754 4.73991 9.82894 4.94043 9.53174L9.65088 2.38281C9.72607 2.27181 9.77979 2.16439 9.81201 2.06055C9.84424 1.95312 9.86035 1.85107 9.86035 1.75439C9.86035 1.48942 9.76904 1.26921 9.58643 1.09375C9.40739 0.914714 9.18001 0.825195 8.9043 0.825195C8.7181 0.825195 8.56055 0.862793 8.43164 0.937988C8.30273 1.01318 8.1792 1.1403 8.06104 1.31934L4.03271 7.6626L2.00244 5.19189C1.80192 4.95557 1.55127 4.8374 1.25049 4.8374C0.974772 4.8374 0.745605 4.92692 0.562988 5.10596C0.383952 5.28141 0.294434 5.50342 0.294434 5.77197C0.294434 5.89372 0.314128 6.0083 0.353516 6.11572C0.396484 6.22314 0.469889 6.33594 0.57373 6.4541L3.18945 9.58008C3.41862 9.84505 3.70687 9.97754 4.0542 9.97754Z"
                                fill="#5ABE60"/>
                        </svg>
                        </span>
                            }
                        </p>
                    </div>
                    {errors.newPassword && errors.newPassword.type === 'pattern' &&
                    <p className={'password__error'}>
                        Password must be have 8 symbols and should include at least one letter and one number
                    </p>
                    }
                    {error.length > 0 && <p className={'password__error'}>
                        {error}
                    </p>}
                    <div className={'logout-modal__btn-wrapper'}>
                        <button className={'logout-modal__btn'}>
                            {t('change')}
                        </button>
                    </div>
                </form>
            </div>}
        </>
    )
}

export default ChangePasswordModal
