import React from 'react'

interface props {

}

const Cookies: React.FC<props> = () => {
    return (
        <section className={'account privacy'}>
            <h2 className={'account__title'}>
                Cookies
            </h2>
            <div className={'account__info'}>
                <p className={'privacy__text'}>
                    Cookies are files with a small amount of data that are commonly used as anonymous unique
                    identifiers. These are sent to your browser from the websites that you visit and are stored on your
                    device's internal memory.
                </p>
                <p className={'privacy__text'}>
                    This Service does not use these “cookies” explicitly. However, the app may use third-party code and
                    libraries that use “cookies” to collect information and improve their services. You have the option
                    to either accept or refuse these cookies and know when a cookie is being sent to your device. If you
                    choose to refuse our cookies, you may not be able to use some portions of this Service.
                </p>
            </div>
        </section>
    )
}

export default Cookies
